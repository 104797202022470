/* eslint-disable no-console */
import {AppDto} from "@/api/dto/app-dto";
import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import globalStore from "@/globalstore";
import {DtoConverter} from "@/api/dto-converter";
import {ConfigDto} from "@/api/dto/config-dto";
import isElectron from "@/utils/ElectronCheck";

const axios = require('axios').default;

export default class AppApi {
    static get API_ROOT(): string {
        return isElectron() ? (process.env.VUE_APP_API_ROOT!!) : (process.env.VUE_APP_API_ROOT_DEV ?? "/")
    }

    static get API_PATH(): string {
        return process.env.VUE_APP_API_PATH!!
    }

    static get API_BASE(): string {
        return AppApi.API_ROOT + AppApi.API_PATH
    }

    // Cached calls
    private static cached = new Set<string>();

    private static get live(): boolean {
        return !isElectron()
    }

    private static apiClient: AxiosInstance = axios.create({
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    // Setup axios instance
    private static init = function () {
        AppApi.apiClient.interceptors.response.use(
            (res: AxiosResponse) => {
                console.log(`%c Request Success: ${res.config.url}`, 'color: #4CAF50; font-weight: bold');//, res);
                return res;
            },
            (err: any) => {
                console.error('%c Request Error:', 'color: #EC6060; font-weight: bold', err);
                return Promise.reject(err);
            }
        );
    }();

    private static get defaultConfig(): AxiosRequestConfig {
        return {
            baseURL: AppApi.API_BASE,
            headers: {
                // 'X-App-Platform': globalStore.electronApi.isElectron() ? 'electron' : window.matchMedia('(display-mode: standalone)').matches ? 'pwa' : 'web',
                // 'X-App-Native-Platform': globalStore.electronApi.platform
            }
        }
    }

    private static getUserLocale() {
        return globalStore.localeManager.locale;
    }

    static resetCaches() {
        // console.debug("Resetting caches");
        this.cached.clear();
    }

    private static get liveSuffix(): string {
        return this.live ? "/live" : "";
    }

    static async getAllApps(): Promise<boolean> {
        if (globalStore.downloadManager.enabled) return false;
        // console.log("Loading all apps");
        const currentLocale = this.getUserLocale();
        const url = `${currentLocale}/apps/config${this.liveSuffix}`;
        const response = await this.apiClient.get<ConfigDto>(url, this.defaultConfig);
        // console.log(response);
        const apps = response.data.apps.map(item => DtoConverter.toApp(item)!!);
        const categories = response.data.categories.map(item => DtoConverter.toAppCategory(item)!!)
        await globalStore.categoryRepository.putAllCategories(categories, true);
        await globalStore.appRepository.putAllApps(apps, true);
        this.cached.clear();
        return true;
    }

    static async getApp(name: string, forceCacheOverride: boolean = false, force: boolean = false, locale: string = this.getUserLocale()): Promise<boolean> {
        // console.log("loading app", name)
        // console.trace();
        if (!force && globalStore.downloadManager.enabled) return false;
        // console.log("Getting app");
        const url = `${locale}/apps/${name}${this.liveSuffix}`;

        if (!forceCacheOverride && this.cached.has(url)) {
            return true;
        }

        this.cached.add(url);

        const response = await this.apiClient.get<AppDto>(url, this.defaultConfig);
        // console.log(response);
        const app = DtoConverter.toApp(response.data)!!;

        // console.log(`Current locale ${currentLocale} supported by app: ${app.supportsLanguage(currentLocale)} with default language ${app.defaultLanguage?.identifier}`);
        if (!app.supportsLanguage(locale)) {
            console.log(`Current locale ${locale} not supported by app, switching to ${app.defaultLanguage?.identifier}`);
            globalStore.localeManager.locale = app.defaultLanguage?.identifier ?? locale;
        }

        // this.cached.add(url);
        await globalStore.appRepository.putAllApps([app]);
        return true;
    }

    // static delay(ms: number) {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }
}