import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Module} from "@/data/model/module";
import {ModuleGroup} from "@/data/model/modulegroup";
import {Option} from "@/data/model/option";
import {ContentPageElement} from "@/data/model/contentpageelement";
import {Configuration} from "@/data/model/configuration";

export class Configurator extends DatabaseModel {
    id: string;
    title: string;
    buttonTitle: string;
    productDepth?: number;
    productHeight?: number;
    productFloorOffset?: number;
    productWallOffset?: number;
    position?: number;
    @Collectable()
    modules: Module[];
    @Collectable()
    moduleGroups: ModuleGroup[];
    @Collectable()
    options: Option[];
    @Collectable()
    configurations: Configuration[];

    constructor(data: {
        id: string,
        title: string,
        buttonTitle: string,
        productDepth?: number,
        productHeight?: number,
        productFloorOffset?: number,
        productWallOffset?: number,
        position?: number,
        modules: Module[],
        moduleGroups: ModuleGroup[],
        options: Option[],
        configurations: Configuration[],
    }) {
        super();
        this.id = data.id;
        this.title = data.title;
        this.buttonTitle = data.buttonTitle;
        this.productDepth = data.productDepth;
        this.productHeight = data.productHeight;
        this.productFloorOffset = data.productFloorOffset;
        this.productWallOffset = data.productWallOffset;
        this.position = data.position;
        this.modules = data.modules;
        this.moduleGroups = data.moduleGroups;
        this.options = data.options;
        this.configurations = data.configurations;
    }

    get buttonTitleFallback(): string | null {
        return this.buttonTitle ?? this.title;
    }

    get sortedModules(): Module[] {
        return this.modules?.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        }) ?? [];
    }
    get sortedOptions(): Option[] {
        return this.options?.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        }) ?? [];
    }
    get defaultConfiguration(): Configuration | null {
        let result: Configuration | null = null;
        this.configurations?.forEach((item: Configuration) => {
            if (item.isDefault && result == null) {
                result = item;
            }
        });
        return result;
    }
}