















/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Machine} from "@/data/model/machine";
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import Button from "@/components/Button.vue";
import LinkButton from "@/components/LinkButton.vue";
@Component({
    components: {LinkButton, Button, ImageResource}
})
export default class MachineItem extends Vue {
    @Prop() private machine!: Machine;
    @Prop() private clickHandler!: (machine: Machine) => void;

    get introductionImage(): Resource | null {
        let machine = this.machine;
        return machine?.introductionImage?.imageExists(768, 768) ? machine?.introductionImage : null;
    }
}
