import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ContentPage} from "@/data/model/contentpage";
import {Resource} from "@/data/model/resource";

export class Option extends DatabaseModel {
    id: string;
    position?: number;
    title?: string;
    @Collectable()
    contentPage?: ContentPage;
    @Collectable()
    icon?: Resource;

    constructor(data: {
        id: string,
        position?: number,
        title?: string,
        contentPage?: ContentPage,
        icon?: Resource,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.title = data.title;
        this.contentPage = data.contentPage;
        this.icon = data.icon;
    }
}