
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {CaseStudy} from "@/data/model/casestudy";
import {Application} from "@/data/model/application";

@Component({})
export default class CaseStudyVue extends MachineVue {
    get caseStudyInstance(): CaseStudy | null {
        return this.appCaseStudies.find((item: CaseStudy) => item.id == this.routerParams.casestudyId) ?? null
    }

    get caseStudyApplications(): Application[] {
        return this.caseStudyInstance?.applications ?? []
    }
}
