import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";
import {Page} from "@/data/model/page";

export class OptionalVisualVideo extends DatabaseModel {
    id: string;
    x?: number;
    y?: number;
    @Collectable()
    page?: Page;

    constructor(data: {
        id: string,
        x?: number,
        y?: number,
        page?: Page,
    }) {
        super();
        this.id = data.id;
        this.x = data.x;
        this.y = data.y;
        this.page = data.page;
    }
}