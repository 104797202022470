





import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ContentPageBase from "@/components/ContentPageBase.vue";
import {ContentPage} from "@/data/model/contentpage";
import ImageResource from "@/components/ImageResource.vue";
import OptionVue from "@/views/base/OptionVue.vue";

@Component({
    components: {
        ImageResource,
        ContentPageBase,
        LoadingIndicator
    }
})
export default class OptionDetail extends OptionVue {

    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title
    }

    get subtitle() {
        return this.optionInstance?.title
    }

    get contentPageId(): string | null {
        return this.contentPage?.id ?? null;
    }

    get contentPage(): ContentPage | null {
        let option = this.optionInstance;
        return option?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
