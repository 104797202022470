













import {Component, Vue} from 'vue-property-decorator';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCloudDownloadAlt} from '@fortawesome/pro-solid-svg-icons'
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import globalStore from "@/globalstore";

@Component({
    name: "download-mode",
    components: {
        FontAwesomeIcon
    }
})
export default class DownloadMode extends Vue {
    get state(): string | null {

        if (this.downloadsEnabled && this.downloading) {
            return "Downloading " + this.totalDownloaded + "/" + this.totalDownloads + " resources";
        } else if (this.downloadsEnabled && this.totalDownloaded < this.totalDownloads) {
            return "Partially available offline";
        } else if (this.downloadsEnabled) {
            return "Offline available";
        } else {
            return "An internet connection is required in online mode";
        }
    }

    click() {
        globalStore.downloadManager.toggle();
    }

    get icon(): IconDefinition {
        return faCloudDownloadAlt;
    }

    get totalDownloaded(): number {
        return globalStore.downloadManager.totalDownloaded
    }

    get totalDownloads(): number {
        return globalStore.downloadManager.totalDownloads
    }

    get downloading(): boolean {
        return globalStore.downloadManager.downloading
    }

    get currentlyDownloading(): number [] {
        return globalStore.downloadManager.currentlyDownloading
    }

    get totalProgress(): string {
        return globalStore.downloadManager.totalProgress.toFixed(2)
    }

    get totalProgressDisplay(): string | number {
        return globalStore.downloadManager.totalDownloads > 0 ? (globalStore.downloadManager.totalProgress * 100 / globalStore.downloadManager.totalDownloads).toFixed(2) : 0.0;
    }

    get toggleDownloadingText(): string {
        if (globalStore.downloadManager.enabled)
            return "Disable";
        else
            return "Enable";
    }

    get downloadsEnabled(): boolean {
        return globalStore.downloadManager.enabled
    }

    set downloadsEnabled(value: boolean) {
        globalStore.downloadManager.enable(value);
        if (!value) {
            if (confirm("You just disabled offline downloading. Do you also want to remove the downloaded offline content?")) {
                globalStore.downloadManager.cleanup()
            }
        }
    }

    get downloadsSupported(): boolean {
        return globalStore.downloadManager.downloadsSupported
    }
}
