








import {Component, Prop, Vue} from 'vue-property-decorator';
import {isConsideredRoot} from "@/router";

@Component
export default class BackButton extends Vue {
    @Prop() public title!: String;
    @Prop() public subtitle!: String;
    private hasHistory: boolean = false;
    useBackButton: boolean = true;
    target: (() => void) | null = null;

    beforeMount() {
        // console.log(window.history.length);
        if (this.target != null)
            this.target();
        else
            this.hasHistory = window.history.length > 1 && this.$router.currentRoute.path != '/';
        this.useBackButton = !isConsideredRoot(this.$router.currentRoute);
    }

    // beforeDestroy() {
    // }

    clickHandler() {
        if (this.hasHistory && this.useBackButton)
            this.$router.go(-1);
    }
}
