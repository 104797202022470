/* eslint-disable no-console */
import {OfflineStorageListener} from "@/manager/OfflineStorageListener";

/**
 * A class that manages downloads using a 'TranslationTable' where you can queue URL's which wil be registered to the TranslationTable.
 * The TranslationTable assigns a unique filename (this implementation uses uuid's) after which the download is started.
 *
 * Listeners can be applied to register changes in progress
 */
export interface OfflineStorageInterface {
    readonly isDownloading: boolean
    readonly totalDownloadCount: number
    readonly totalDownloadedCount: number

    getCurrentRequestProgressions(): number[]

    addListener(listener: OfflineStorageListener): void

    removeListener(listener: OfflineStorageListener): void

    cancelAndCleanup(): void

    cancel(): void

    /**
     * @return the filename of under what name the file should be saved if registered, for example '1341341'
     */
    // @ts-ignore
    filenameForUrl(url: string, register: boolean = false): string | null

    /**
     * @return the full path of where the url should be saved if registered, for example '/user/data/offlinedata/1341341'
     */
    // @ts-ignore
    localPathForUrl(url: string, register: boolean = false): string | null

    /**
     * @param url
     * @return true if the file has been downloaded already
     */
    has(url: string): boolean

    /**
     * Retains only the URL's given as parameter for this offline storage interface. All files that do not match these urls will be deleted
     */
    // @ts-ignore
    retainOnly(urls: string[], cleanupOldFiles: boolean = true): void

    delete(url: string | string[]): void

    download(url: string | string[]): void

    prioritize(url: string | string[]): void
}

export enum ProgressUpdateType {
    // eslint-disable-next-line no-unused-vars
    MAJOR, // File finished/started
    // eslint-disable-next-line no-unused-vars
    MINOR // Individual file progress changed
}

export class RunningRequest {
    private readonly _url: string;
    private readonly _cancel: () => void;
    private readonly _tempFileName: string;
    private _progress: number = 0.0;

    constructor(url: string, cancel: () => void, tempFileName: string) {
        this._url = url;
        this._cancel = cancel;
        this._tempFileName = tempFileName;
    }

    cancel() {
        console.debug(`Cancelling running request ${this._url}`);
        this._cancel();
    }

    get progress(): number {
        return this._progress;
    }

    set progress(value: number) {
        if (value >= 0 && value <= 1)
            this._progress = value;
    }

    get tempFileName(): string {
        return this._tempFileName;
    }

    get url(): string {
        return this._url;
    }
}