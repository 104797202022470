import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";
import {MachineImageBenefit} from "@/data/model/machineimagebenefit";

export class MachineImage extends DatabaseModel {

    id: string;
    position?: number;
    @Collectable()
    benefits: MachineImageBenefit[];
    @Collectable()
    image?: Resource;
    @Collectable()
    buttonsImage?: Resource;


    constructor(data: {
        id: string,
        position?: number,
        benefits: MachineImageBenefit[],
        image?: Resource,
        buttonsImage?: Resource,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.benefits = data.benefits;
        this.image = data.image;
        this.buttonsImage = data.buttonsImage;
    }
}