










import Button from "@/components/Button.vue";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
    Button,
  }
})
export default class QuestionnaireContentBoolean extends Vue {
  @Prop() public question!: string;
  @Prop() public clickHandler!: Function;
}
