




































import {Component} from 'vue-property-decorator';
import CanonLogo from "@/components/CanonLogo.vue";
import BackButton from "@/components/BackButton.vue";
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MachineVue from "@/views/base/MachineVue.vue";
import {Optional} from "@/data/model/optional";
import PageBase from "@/components/PageBase.vue";
import Columns from "@/components/Columns.vue";
import Column from "@/components/Column.vue";
import {OptionalVisual} from "@/data/model/optionalvisual";
import {OptionalVisualVideo} from "@/data/model/optionalvisualvideo";

@Component({
    components: {
        Button,
        CanonLogo,
        BackButton,
        LoadingIndicator,
        Columns,
        Column,
        PageBase
    }
})
export default class OptionalsOverview extends MachineVue {
    private selectedOptionals: Optional[] = [];
    private showFootprint: boolean = false;

    get subtitle() {
        let machine = this.machineInstance;
        return machine?.optionalsButtonTitle ?? null;
    }

    get footprintTitle() {
        let machine = this.machineInstance;
        return machine?.footprintTitle ?? null;
    }

    videoStyle(video: OptionalVisualVideo) {
        let x = video.x;
        let y = video.y;

        if (x && y) {
            return "left: " + (x) + "%; top: " + (y) + "%";
        }

        return "";
    }

    toggleFootprint() {
        this.showFootprint = !this.showFootprint
    }

    toggleOptional(optional: Optional) {
        if (this.optionalDisabled(optional)) {
            let machine = this.machineInstance;
            let optionalVisuals: OptionalVisual[] = machine?.optionalVisuals ?? []

            let possibleOptionalVisual = optionalVisuals.find(optionalVisual => {
                return optionalVisual.optionals.every(optionalId => optionalId == optional.id)
            })

            if (possibleOptionalVisual) {
                this.selectedOptionals = [optional];
                this.trackOptional(optional);
            }
        } else {
            let index = this.selectedOptionals.indexOf(optional);
            if (index >= 0) {
                this.selectedOptionals.splice(index);
            } else {
                this.selectedOptionals.push(optional);
                this.trackOptional(optional);
            }
        }
    }

    trackOptional(optional: Optional) {
        this.$tracker.selectContent(optional.id, this.machineOptionals.map(item => {
            return {
                item_id: item.id,
                item_name: item.title
            }
        }), "optional");
        this.$tracker.viewItems(this.selectedOptionals.map(item => {
            return {
                item_id: item.id,
                item_name: item.title
            }
        }));
    }

    optionalDisabled(optional: Optional): boolean {
        if (this.optionalActive(optional)) {
            return false;
        }

        let optionalVisual = this.currentOptionalVisual;

        if (optionalVisual == null || optionalVisual.optionals.length == 0) {
            return !optional.isDefault
        }
        if (optionalVisual != null) {
            if (!optionalVisual.optionals.every(optionalId => {
                return (optional.compatibleWith.indexOf(optionalId) >= 0)
            })) {
                return true;
            }

            if (!optional.requires.every(optionalId => {
                return optionalVisual &&
                    optionalVisual?.optionals.indexOf(optionalId) >= 0;
            })) {
                return true;
            }
        }

        return false;
    }

    optionalActive(optional: Optional): boolean {
        return this.currentOptionalVisual?.optionals.some(optionalId => optionalId == optional.id) ?? false;
    }

    get currentOptionalVisual(): OptionalVisual | null {
        let machine = this.machineInstance;
        let optionalVisuals: OptionalVisual[] = machine?.optionalVisuals ?? [];

        let current = optionalVisuals.find((optionalVisual) => {
            return this.selectedOptionals.length == optionalVisual.optionals.length && optionalVisual.optionals.every((optionalId) => {
                return this.selectedOptionals.some((optional) => optional.id == optionalId)
            })
        }) ?? null;

        if (current == null) {
            current = optionalVisuals.find((optionalVisual) => optionalVisual.isDefault) ?? null;

            if (current !== null) {
                let optionals: Optional[] = this.machineOptionals ?? [];

              let selectedOptionalsList = optionals.filter((optional) => current?.optionals.includes(optional.id));
              setTimeout(() => (this.selectedOptionals = selectedOptionalsList), 1);
            }
        }

        return current;
    }

    get hasFootprint(): boolean {
        let machine = this.machineInstance;
        let optionalVisuals: OptionalVisual[] = machine?.optionalVisuals ?? []
        return this.footprintTitle != null && optionalVisuals.some((optionalVisual) => optionalVisual.footprintImage && optionalVisual.footprintImage.imageExists(2048, 2048))
    }

    beforeMount() {
        this.refreshApp();
    }

    visualSize() {
        let img = document.getElementById("optional-visual-image");
        let videosContainer = document.getElementById("optional-visual-videos");

        if (img && videosContainer) {
            let style = getComputedStyle(img, null);
            videosContainer.style.width = style.width;
            videosContainer.style.height = style.height;
        }
    }

    mounted() {
        let that = this;
        that.visualSize();

        window.addEventListener('resize', function () {
            that?.visualSize();
        });
    }
}
