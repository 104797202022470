/* eslint-disable no-console */
import {DatabaseObserver} from "@/data/database-observer";
import Dexie from "dexie";

// Kind of a hack, but provides very basic observability on a specific table (triggers on all changes though)
export function hookTable(table: Dexie.Table<any, any>, call: () => void): DatabaseObserver {
    let updateListener = function (modifications: any, primKey: any, obj: any, transaction: any) {
        // console.log("updating", modifications, primKey, obj, transaction);
        // Prevent calling callback when there are no real updates
        if (Object.keys(modifications).length > 0)
            setTimeout(call, 1);
    };
    let creatingListener = function (primKey: any, obj: any, transaction: any) {
        // console.log("creating", primKey, obj, transaction);
        transaction.on('complete', () => {
            setTimeout(call, 1);
        })
    };
    let deletingListener = function (primKey: any, obj: any, transaction: any) {
        // console.log("deleting", primKey, obj, transaction);
        setTimeout(call, 1);
    };
    table.hook('updating', updateListener);
    table.hook('creating', creatingListener);
    table.hook('deleting', deletingListener);

    return new class implements DatabaseObserver {
        release(): void {
            table.hook('updating').unsubscribe(updateListener);
            table.hook('creating').unsubscribe(creatingListener);
            table.hook('deleting').unsubscribe(deletingListener);
        }
    }
}

// protected wrapLiveCall<T, Key>(table: Dexie.Table<T, Key>, call: () => Promise<any>, throttle: number = 200): DatabaseObserver {
//     let lastCall = 0;
//     let timeout: any | null = null;
//     let throttledCall = function () {
//         // console.log("Updating call");
//         const diff = new Date().getTime() - lastCall;
//         // console.log(diff);
//         if (diff < throttle) {
//             // console.log("Scheduling");
//             if (timeout == null) {
//                 timeout = setTimeout(function () {
//                     // console.log("Timeout executed");
//                     call();
//                     timeout = null;
//                 }, throttle - diff)
//             }
//         } else {
//             // console.log("Run instantly");
//             call();
//             lastCall = new Date().getTime();
//         }
//     };
//     return this.hookTable(table, async () => {
//         throttledCall()
//     });
// }

export function wrapLiveCall<T, Key>(table: Dexie.Table<T, Key>, call: () => Promise<any>): DatabaseObserver {
    call();
    return hookTable(table, async () => {
        await call()
    });
}