





import {Component} from 'vue-property-decorator';
import CanonLogo from "@/components/CanonLogo.vue";
import BackButton from "@/components/BackButton.vue";
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageVue from "@/views/base/PageVue.vue";
import {Page} from "@/data/model/page";
import PageBase from "@/components/PageBase.vue";
import {Resource} from "@/data/model/resource";
import {ContentPage} from "@/data/model/contentpage";
import ContentPageBase from "@/components/ContentPageBase.vue";

@Component({
    components: {
        ContentPageBase,
        PageBase,
        Button,
        CanonLogo,
        BackButton,
        LoadingIndicator
    }
})
export default class PageDetail extends PageVue {

    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title ?? this.appInstance?.title;
    }

    get subtitle() {
        return this.pageInstance?.title
    }

    get contentPageId(): string|null {
        return this.contentPage?.id ?? null;
    }

    get contentPage(): ContentPage | null {
        let page = this.pageInstance;
        return page?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
