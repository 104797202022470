






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Button extends Vue {
    @Prop({default: false}) protected inactive!: boolean;

    handleClick() {
        this.$emit("click");
        // console.log(this.clickHandler);
        // if (this.clickHandler != null) {
        //     this.clickHandler();
        // }
    }
}
