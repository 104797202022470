

















/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {isIOS, isAndroid, osName} from "mobile-device-detect";
import {App} from "@/data/model/app";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRocket } from '@fortawesome/pro-duotone-svg-icons'
import {Resource} from "@/data/model/resource";
import ImageResource from "@/components/ImageResource.vue";
import {isMobile} from "mobile-device-detect";

@Component({
    data () {
        return {
            icon: faRocket
        }
    },

    components: {
        ImageResource,
        FontAwesomeIcon
    }
})
export default class AppItem extends Vue {
    @Prop() private app!: App;
    @Prop() private clickHandler!: (app: App) => void;
    private selected: boolean = false;

    isInstaller(app: App){
        if(isMobile){
            if(app.type == "installer"){ 
                return true; 
            }else{ 
                return false; 
            }
        }else{
            return false;
        }  
    }

    focusApp(app: App) {
        //console.log(osName);
        if (app.type == "installer" || (osName == "Mac OS" && app.windowsInstaller)) {
            var count = 0;
            if (app.windowsInstaller){ count++; }
            if (isIOS && app.iosUrl){ count++; }
            if (isAndroid && app.androidUrl){ count++; }

            if(count > 1){ this.selected = true; }
            else{ this.selected = false; }
            //console.log(count);
        }
    }

    selectApp(app: App) {
        if (app.type != "installer" && !(osName == "Mac OS" && app.windowsInstaller)) {
            this.launch(app);
        } else if (isIOS && app.iosUrl) {
            this.openIos();
        } else if (isAndroid && app.androidUrl) {
            this.openAndroid();
        }else{
            this.openWindows();
        }
    }

    canLaunch(app: App): boolean {
        return app.type != "installer";
    }

    get appTitle(): string | null {
        return this.app?.title ?? null;
    }

    get image(): Resource | null {
        let icon = this.app?.icon;

        if (icon instanceof Resource) {
          return icon?.imageExists(100, 100) ? icon : null;
        } else {
          return null;
        }
    }

    blurApp() {
        this.selected = false;
    }

    openIos() {
        this.$tracker.trackDownload(this.app.id, this.app.title, "ios");
        window.open(this.app.iosUrl);
    }

    openAndroid() {
        this.$tracker.trackDownload(this.app.id, this.app.title, "android");
        window.open(this.app.androidUrl);
    }

    openWindows() {
        this.$tracker.trackDownload(this.app.id, this.app.title, "windows");
        window.open(this.app.windowsInstaller);
    }

    launch(app: App) {
        this.clickHandler(app);
    }
}
