































import {Component, Watch} from 'vue-property-decorator';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faLanguage} from '@fortawesome/pro-regular-svg-icons'
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import AppVue from "@/views/base/AppVue.vue";
import globalStore from "@/globalstore";
import router from "@/router";
import {isAndroid, isIOS, osName} from "mobile-device-detect";
import {App} from "@/data/model/app";
import Vue from 'vue';

@Component({
    components: {
        FontAwesomeIcon
    }
})
export default class LanguageSelect extends AppVue {
    protected selectedLanguage: string | null = null;

    @Watch("selectedLanguage")
    onSelectedLanguageChanged() {
        let language = this.selectedLanguage;

        if (language != null) {
          for (let appLanguage of this.appInstance?.languages ?? []) {
            if (appLanguage.id == language) {
              if (globalStore.localeManager.locale != appLanguage.identifier && appLanguage.identifier != undefined) {
                globalStore.localeManager.locale = appLanguage.identifier;
                this.$router.replace({
                  params: {
                    locale: globalStore.localeManager.locale
                  }
                });
                this.$i18n.locale = globalStore.localeManager.locale;
              }
              return;
            }
          }
          for (let sibling of this.appInstance?.siblings ?? []) {
            for (let siblingLanguage of sibling.languages) {
              if (siblingLanguage.id == language && sibling.urlPath) {
                if (sibling.type != "installer" && !(osName == "Mac OS" && sibling.windowsInstaller)) {
                  this.launch(sibling, siblingLanguage.identifier);
                } else if (isIOS && sibling.iosUrl) {
                  this.openIos(sibling);
                } else if (isAndroid && sibling.androidUrl) {
                  this.openAndroid(sibling);
                } else if (sibling.windowsInstaller) {
                  this.openWindows(sibling);
                }

                return;
              }
            }
          }
        }
    }

    get hasSiblings(): boolean {
      return (this.appInstance?.siblings?.length ?? 0) > 0;
    }

    get icon(): IconDefinition {
        return faLanguage;
    }

    openIos(app: App) {
      window.open(app.iosUrl);
    }

    openAndroid(app: App) {
      window.open(app.androidUrl);
    }

    openWindows(app: App) {
      window.open(app.windowsInstaller);
    }

    launch(app: App, locale: string | undefined) {
      let urlPath = app.urlPath
      if (urlPath != undefined && locale != undefined) {
        globalStore.localeManager.locale = locale;
        router.push({
          name: 'app-intro',
          params: {appSlug: urlPath, locale: locale}
        });
      }
    }
}
