
import {Component} from "vue-property-decorator";
import {Option} from "@/data/model/option";
import ConfiguratorVue from "./ConfiguratorVue.vue";

@Component({})
export default class OptionVue extends ConfiguratorVue {
    get optionInstance(): Option | null {
        return this.configuratorOptions.find((item: Option) => item.id == this.routerParams.optionId) ?? null
    }
}
