





import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ContentPageBase from "@/components/ContentPageBase.vue";
import {ContentPage} from "@/data/model/contentpage";
import ImageResource from "@/components/ImageResource.vue";
import ModuleVue from "@/views/base/ModuleVue.vue";

@Component({
    components: {
        ImageResource,
        ContentPageBase,
        LoadingIndicator
    }
})
export default class ModuleDetail extends ModuleVue {

    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title
    }

    get subtitle() {
        return this.moduleInstance?.title
    }

    get contentPageId(): string | null {
        return this.contentPage?.id ?? null;
    }

    get contentPage(): ContentPage | null {
        let module = this.moduleInstance;
        return module?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
