import {AppDto} from "@/api/dto/app-dto";
import {App} from "@/data/model/app";
import {ApplicationDto} from "@/api/dto/application-dto";
import {Application} from "@/data/model/application";
import {BenefitDto} from "@/api/dto/benefit-dto";
import {Benefit} from "@/data/model/benefit";
import {BenefitElementDto} from "@/api/dto/benefitelement-dto";
import {BenefitElement} from "@/data/model/benefitelement";
import {CaseStudyDto} from "@/api/dto/casestudy-dto";
import {CaseStudy} from "@/data/model/casestudy";
import {ConfiguratorDto} from "@/api/dto/configurator-dto";
import {Configurator} from "@/data/model/configurator";
import {ContactDto} from "@/api/dto/contact-dto";
import {Contact} from "@/data/model/contact";
import {ContentPageDto} from "@/api/dto/contentpage-dto";
import {ContentPage} from "@/data/model/contentpage";
import {ContentPageElementDto} from "@/api/dto/contentpageelement-dto";
import {ContentPageElement} from "@/data/model/contentpageelement";
import {LanguageDto} from "@/api/dto/language-dto";
import {Language} from "@/data/model/language";
import {MachineDto} from "@/api/dto/machine-dto";
import {Machine} from "@/data/model/machine";
import {ModuleDto} from "@/api/dto/module-dto";
import {Module} from "@/data/model/module";
import {ModuleGroupDto} from "@/api/dto/modulegroup-dto";
import {ModuleGroup} from "@/data/model/modulegroup";
import {OptionDto} from "@/api/dto/option-dto";
import {Option} from "@/data/model/option";
import {OptionalDto} from "@/api/dto/optional-dto";
import {Optional} from "@/data/model/optional";
import {OptionalVisualDto} from "@/api/dto/optionalvisual-dto";
import {OptionalVisual} from "@/data/model/optionalvisual";
import {OverviewDto} from "@/api/dto/overview-dto";
import {Overview} from "@/data/model/overview";
import {PageDto} from "@/api/dto/page-dto";
import {Page} from "@/data/model/page";
import {ResourceDto} from "@/api/dto/resource-dto";
import {Resource} from "@/data/model/resource";
import {TechnologyDto} from "@/api/dto/technology-dto";
import {Technology} from "@/data/model/technology";
import {TooltipDto} from "@/api/dto/tooltip-dto";
import {Tooltip} from "@/data/model/tooltip";
import {UserDto} from "@/api/dto/user-dto";
import {User} from "@/data/model/user";
import {BenefitCategoryDto} from "@/api/dto/benefitcategory-dto";
import {BenefitCategory} from "@/data/model/benefitcategory";
import {AppCategory} from "@/data/model/appcategory";
import {AppCategoryDto} from "@/api/dto/appcategory-dto";
import {OptionalVisualVideoDto} from "@/api/dto/optionalvisualvideo-dto";
import {OptionalVisualVideo} from "@/data/model/optionalvisualvideo";
import {MachineImageDto} from "@/api/dto/machine-image-dto";
import {MachineImage} from "@/data/model/machineimage";
import {MachineImageBenefit} from "@/data/model/machineimagebenefit";
import {MachineImageBenefitDto} from "@/api/dto/machine-image-benefit-dto";
import {ConfigurationDto} from "@/api/dto/configuration-dto";
import {Configuration} from "@/data/model/configuration";
import {ConfigurationModuleDto} from "@/api/dto/configuration-module-dto";
import {ConfigurationModule} from "@/data/model/configurationmodule";

/**
 * To convert API JSON objects to the right database models
 */
export class DtoConverter {

    static toApp(dto?: AppDto): App | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new App({
            id: dto.id,
            published: dto.published,
            callToActionTitle: dto.call_to_action_title,
            callToActionButtonTitle: dto.call_to_action_button_title,
            skipIntroductionButtonTitle: dto.skip_introduction_button_title,
            introductionButtonTitle: dto.introduction_button_title,
            introductionBody: dto.introduction_body,
            subtitle: dto.subtitle,
            title: dto.title,
            callToActionBody: dto.call_to_action_body,
            urlPath: dto.url_path,
            skipMachineOverview: dto.skip_machine_overview,
            preview: dto.preview,
            type: dto.type,
            region: dto.region,
            windowsInstaller: dto.windows_installer,
            iosUrl: dto.ios_url,
            androidUrl: dto.android_url,
            pages: dto.pages?.map(item => this.toPage(item)!!),
            icon: this.toResource(dto.icon),
            introductionImage: this.toResource(dto.introduction_image),
            introductionVideo: this.toResource(dto.introduction_video),
            mainImage: this.toResource(dto.main_image),
            languages: dto.languages?.map(item => this.toLanguage(item)!!),
            contacts: dto.contacts?.map(item => this.toContact(item)!!),
            overviews: dto.overviews?.map(item => this.toOverview(item)!!),
            caseStudies: dto.case_studies?.map(item => this.toCaseStudy(item)!!),
            machines: dto.machines?.map(item => this.toMachine(item)!!),
            callToActionImage: this.toResource(dto.call_to_action_image),
            benefitCategories: dto.benefit_categories?.map(item => this.toBenefitCategory(item)!!),
            categories: dto.categories?.map(item => this.toAppCategory(item)!!),
            landing: dto.landing?.map(item => this.toAppCategory(item)!!),
            category: this.toAppCategory(dto.category),
            currentCountry: dto.current_country,
            countries: dto.countries,
            defaultForCountry: dto.default_for_country,
            siblings: dto.siblings?.map(item => this.toApp(item)!!),
            children: dto.children?.map(item => this.toApp(item)!!),
        });
    }

    static toAppCategory(dto?: AppCategoryDto): AppCategory | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);

        let apps: App[];
        if (Array.isArray(dto.apps)) {
            apps = (dto.apps)?.map(item => this.toApp(item)!!);
        } else {
            apps = [];
        }
        return new AppCategory({
            id: dto.id,
            title: dto.title,
            position: dto.position,
            apps: apps,
        });
    }

    static toApplication(dto?: ApplicationDto): Application | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Application({
            id: dto.id,
            position: dto.position,
            title: dto.title,
            thumbnail: this.toResource(dto.thumbnail),
            contentPage: this.toContentPage(dto.content_page),
        });
    }

    static toBenefit(dto?: BenefitDto): Benefit | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Benefit({
            id: dto.id,
            position: dto.position,
            title: dto.title,
            technologies: dto.technologies?.map(item => this.toTechnology(item)!!),
            benefitElements: dto.benefit_elements?.map(item => this.toBenefitElement(item)!!),
            mainVideo: this.toResource(dto.main_video),
            mainImage: this.toResource(dto.main_image),
            category: this.toBenefitCategory(dto.category),
        });
    }

    static toBenefitElement(dto?: BenefitElementDto): BenefitElement | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new BenefitElement({
            id: dto.id,
            position: dto.position,
            title: dto.title,
            body: dto.body,
            moreButtonTitle: dto.more_button_title,
            technologies: dto.technologies?.map(item => this.toTechnology(item)!!),
            image: this.toResource(dto.image),
        });
    }

    static toBenefitCategory(dto?: BenefitCategoryDto): BenefitCategory | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new BenefitCategory({
            id: dto.id,
            title: dto.title,
            position: dto.position,
        });
    }

    static toCaseStudy(dto?: CaseStudyDto): CaseStudy | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new CaseStudy({
            id: dto.id,
            position: dto.position,
            metadata: dto.metadata,
            title: dto.title,
            applications: dto.applications?.map(item => this.toApplication(item)!!),
            thumbnail: this.toResource(dto.thumbnail),
            contentPage: this.toContentPage(dto.content_page),
            machines: dto.machines?.map(item => this.toMachine(item)!!),
            filter_machines: dto.filter_machines,
        });
    }

    static toConfigurator(dto?: ConfiguratorDto): Configurator | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Configurator({
            id: dto.id,
            title: dto.title,
            buttonTitle: dto.button_title,
            productDepth: dto.product_depth,
            productHeight: dto.product_height,
            productFloorOffset: dto.product_floor_offset,
            productWallOffset: dto.product_wall_offset,
            position: dto.position,
            modules: dto.modules?.map(item => this.toModule(item)!!),
            moduleGroups: dto.module_groups?.map(item => this.toModuleGroup(item)!!),
            options: dto.options?.map(item => this.toOption(item)!!),
            configurations: dto.configurations?.map(item => this.toConfiguration(item)!!),
        });
    }

    static toConfiguration(dto?: ConfigurationDto): Configuration | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Configuration({
            id: dto.id,
            title: dto.title,
            isDefault: dto.is_default,
            modules: dto.modules?.map(item => this.toConfigurationModule(item)!!),
        });
    }

    static toConfigurationModule(dto?: ConfigurationModuleDto): ConfigurationModule | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new ConfigurationModule({
            id: dto.id,
            moduleId: dto.module_id,
            position: dto.position,
        });
    }

    static toContact(dto?: ContactDto): Contact | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Contact({
            id: dto.id,
            position: dto.position,
            title: dto.title,
            url: dto.url,
            enabled: dto.enabled,
        });
    }

    static toContentPage(dto?: ContentPageDto): ContentPage | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new ContentPage({
            id: dto.id,
            type: dto.type,
            title: dto.title,
            referenceTitle: dto.reference_title,
            subpages: dto.subpages?.map(item => this.toPage(item)!!),
            technologies: dto.technologies?.map(item => this.toTechnology(item)!!),
            overviews: dto.overviews?.map(item => this.toOverview(item)!!),
            contentPageElements: dto.content_page_elements?.map(item => this.toContentPageElement(item)!!),
            mainVideo: this.toResource(dto.main_video),
            images: dto.images?.map(item => this.toResource(item)!!),
            mainImage: this.toResource(dto.main_image),
        });
    }

    static toContentPageElement(dto?: ContentPageElementDto): ContentPageElement | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new ContentPageElement({
            id: dto.id,
            position: dto.position,
            type: dto.type,
            title: dto.title,
            body: dto.body,
            image: this.toResource(dto.image),
            align: dto.align,
        });
    }

    static toLanguage(dto?: LanguageDto): Language | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Language({
            id: dto.id,
            identifier: dto.identifier,
            title: dto.title,
            position: dto.position,
        });
    }

    static toMachine(dto?: MachineDto): Machine | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Machine({
            id: dto.id,
            subtitle: dto.subtitle,
            optionalsButtonTitle: dto.optionals_button_title,
            caseStudiesButtonTitle: dto.case_studies_button_title,
            applicationsButtonTitle: dto.applications_button_title,
            benefitsButtonTitle: dto.benefits_button_title,
            skipIntroductionButtonTitle: dto.skip_introduction_button_title,
            introductionButtonTitle: dto.introduction_button_title,
            introductionBody: dto.introduction_body,
            body: dto.body,
            title: dto.title,
            footprintTitle: dto.footprint_title,
            position: dto.position,
            benefits: dto.benefits?.map(item => this.toBenefit(item)!!),
            applications: dto.applications?.map(item => this.toApplication(item)!!),
            introductionImage: this.toResource(dto.introduction_image),
            introductionVideo: this.toResource(dto.introduction_video),
            mainImage: this.toResource(dto.main_image),
            benefitsImage: this.toResource(dto.benefits_image),
            optionals: dto.optionals?.map(item => this.toOptional(item)!!),
            optionalVisuals: dto.optional_visuals?.map(item => this.toOptionalVisual(item)!!),
            technologies: dto.technologies?.map(item => this.toTechnology(item)!!),
            machineImages: dto.machine_images?.map(item => this.toMachineImage(item)!!),
            configurators: dto.configurators?.map(item => this.toConfigurator(item)!!),
        });
    }

    static toMachineImage(dto?: MachineImageDto): MachineImage | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new MachineImage({
            id: dto.id,
            position: dto.position,
            benefits: dto.benefits?.map(item => this.toMachineImageBenefit(item)!!),
            image: this.toResource(dto.image),
            buttonsImage: this.toResource(dto.buttons_image),
        });
    }

    static toMachineImageBenefit(dto?: MachineImageBenefitDto): MachineImageBenefit | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new MachineImageBenefit({
            id: dto.id,
            x: dto.x,
            y: dto.y,
            benefitId: dto.benefit_id,
            pageId: dto.page_id,
        });
    }

    static toModule(dto?: ModuleDto): Module | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Module({
            id: dto.id,
            position: dto.position,
            arrangingDepth: dto.arranging_depth,
            insetRight: dto.inset_right,
            width: dto.width,
            insetLeft: dto.inset_left,
            groupMaximum: dto.group_maximum,
            maximum: dto.maximum,
            placement: dto.placement,
            height: dto.height,
            depth: dto.depth,
            title: dto.title,
            contentPage: this.toContentPage(dto.content_page),
            moduleGroup: this.toModuleGroup(dto.module_group),
            topOpenedView: this.toResource(dto.top_opened_view),
            topView: this.toResource(dto.top_view),
            frontView: this.toResource(dto.front_view),
        });
    }

    static toModuleGroup(dto?: ModuleGroupDto): ModuleGroup | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new ModuleGroup({
            id: dto.id,
            name: dto.name,
        });
    }

    static toOption(dto?: OptionDto): Option | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Option({
            id: dto.id,
            position: dto.position,
            title: dto.title,
            contentPage: this.toContentPage(dto.content_page),
            icon: this.toResource(dto.icon),
        });
    }

    static toOptional(dto?: OptionalDto): Optional | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Optional({
            id: dto.id,
            position: dto.position,
            isDefault: dto.is_default,
            title: dto.title,
            body: dto.body,
            requires: dto.requires ?? [],
            compatibleWith: dto.compatible_with ?? [],
        });
    }

    static toOptionalVisual(dto?: OptionalVisualDto): OptionalVisual | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new OptionalVisual({
            id: dto.id,
            isDefault: dto.is_default,
            optionals: dto.optionals ?? [],
            footprintImage: this.toResource(dto.footprint_image),
            image: this.toResource(dto.image),
            videos: dto.videos?.map(item => this.toOptionalVisualVideo(item)!!),
        });
    }

    static toOptionalVisualVideo(dto?: OptionalVisualVideoDto): OptionalVisualVideo | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new OptionalVisualVideo({
            id: dto.id,
            x: dto.x,
            y: dto.y,
            page: this.toPage(dto.page),
        });
    }

    static toOverview(dto?: OverviewDto): Overview | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Overview({
            id: dto.id,
            position: dto.position,
            prominent: dto.prominent,
            home: dto.home,
            title: dto.title,
            body: dto.body,
            tooltips: dto.tooltips?.map(item => this.toTooltip(item)!!),
            image: this.toResource(dto.image),
        });
    }

    static toPage(dto?: PageDto): Page | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Page({
            id: dto.id,
            position: dto.position,
            prominent: dto.prominent,
            title: dto.title,
            thumbnail: this.toResource(dto.thumbnail),
            contentPage: this.toContentPage(dto.content_page),
        });
    }

    static toResource(dto?: ResourceDto): Resource | undefined {
        if (dto == null) return undefined;
        const images: {[string: string]: string} = {};
        Object.entries(dto).forEach(([key, value]) => {
            if (key.startsWith("image_")) {
                let index: string = key.replace("image_", "");
                images[index] = value;
            }
        });
        const thumbnails: {[string: string]: string} = {};
        Object.entries(dto).forEach(([key, value]) => {
            if (key.startsWith("thumbnail_")) {
                let index: string = key.replace("thumbnail_", "");
                thumbnails[index] = value;
            }
        });

        return new Resource({
            id: dto.id,
            style: dto.style,
            body: dto.body,
            images: images,
            thumbnails: thumbnails,
            thumbnail: dto.thumbnail,
            languages: dto.languages,
            video: dto.video,
            subtitle: dto.subtitle,
            autoplay: dto.autoplay,
            backgroundColor: dto.background_color,
            subpages: dto.subpages?.map(item => this.toPage(item)!!),
        });
    }

    static toTechnology(dto?: TechnologyDto): Technology | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Technology({
            id: dto.id,
            position: dto.position,
            prominent: dto.prominent,
            title: dto.title,
            logo: this.toResource(dto.logo),
            contentPage: this.toContentPage(dto.content_page),
        });
    }

    static toTooltip(dto?: TooltipDto): Tooltip | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new Tooltip({
            id: dto.id,
            type: dto.type,
            showAsVideo: dto.show_as_video,
            x: dto.x,
            y: dto.y,
            title: dto.title,
            body: dto.body,
            page: this.toPage(dto.page),
            video: this.toResource(dto.video),
        });
    }

    static toUser(dto?: UserDto): User | undefined {
        if (dto == null) return undefined;
        // console.log("dto", dto);
        return new User({
            id: dto.id,
            email: dto.email,
            firstName: dto.first_name,
            lastName: dto.last_name,
        });
    }
}