import "reflect-metadata";
import {isDefined} from "@/utils/CheckerUtils";

const metadataKey = Symbol('Collectable');

export function Collectable() {
    return function (target: DatabaseModel, propertyKey: PropertyKey): void {
        let properties: PropertyKey[] = Reflect.getMetadata(metadataKey, target);

        if (properties) {
            properties.push(propertyKey);
        } else {
            properties = [propertyKey];
            Reflect.defineMetadata(metadataKey, properties, target);
        }
    };
}

export abstract class DatabaseModel {
    /**
     * Returns all URLs that the given value provides, as long as the value is a supported type
     */
    private getUrlsFromObject(value: any): string[] {
        // console.log(value, typeof value, value instanceof Array, value instanceof Object, value instanceof Map, value instanceof DatabaseModel);
        if (value instanceof DatabaseModel) {
            return value.collectUrls();
        } else if (value instanceof Array) {
            const urls: string[] = [];
            value.forEach((item: any) => {
                urls.push(...this.getUrlsFromObject(item))
            });
            return urls;
        }
        return [];
    }

    /**
     * Loops over all properties that have the {@linkcode Collectable} decorator and returns the URLs they provide, if any.
     * Will only work with properties supported by {@linkcode getUrlsFromObject}
     */
    private collectTaggedPropertiesUrls(): string[] {
        const properties: string[] | undefined = Reflect.getMetadata(metadataKey, this);
        if (properties == null) return [];
        const urls: string[] = [];

        properties.forEach((item: string) => {
            // @ts-ignore
            const value: any = this[item];
            if (value == null) return null;

            urls.push(...this.getUrlsFromObject(value));
        });
        return urls.filter(isDefined);
    }

    /**
     * Before overriding this method, you probably want to check if you shouldn't override {@linkcode collectSelfUrls}
     */
    collectUrls(): string[] {
        const selfUrls = this.collectSelfUrls();
        const taggedCollectedUrls = this.collectTaggedPropertiesUrls();
        const urls: string[] = [];
        urls.push(...selfUrls);
        urls.push(...taggedCollectedUrls);
        return urls;
    }

    /**
     * @return List of all URLs this object provides to be downloaded
     */
    collectSelfUrls(): string[] {
        return []
    }

    cleanUndefineds(): this {
        const obj = this as object;
        Object.keys(obj).forEach(key => {
            // @ts-ignore
            const value = obj[key];
            if (typeof value == "undefined") {
                // @ts-ignore
                delete obj[key];
            }
            if (value instanceof DatabaseModel) {
                value.cleanUndefineds();
            }
        });
        return this;
    }
}