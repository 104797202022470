
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";
import {Application} from "@/data/model/application";

@Component({
    components: {
        ImageResource
    }
})
export default class Gallery extends Vue {
    @Prop() public applications!: Application[];

    protected selectedIndex: number = 0;

    @Watch('selectedIndex')
    onSelectedIndexChanged(newSelectedIndex: number) {
        this.$tracker.selectContent(this.applications[newSelectedIndex].id, this.applications.map(item => {
            return {
                item_id: item.id,
                item_name: item.title
            }
        }), "application")
    }

    applicationZIndex(applicationIndex: number) {
        let total = this.theApplications.length;

        if (applicationIndex == this.selectedIndex) {
            return total + 1;
        }

        return (total-applicationIndex);
    }

    applicationClass(applicationIndex: number) {
        if (this.selectedIndex == applicationIndex) {
            return 'active';
        } else if (this.selectedIndex > applicationIndex) {
            return 'left';
        } else {
            return 'right';
        }
    }

    get theApplications(): Application[] {
        return this.applications ?? []
    }

    scrolledApplications(event: Event) {
        let applicationsGallery = event.target as HTMLElement;
        let applicationsGalleryContent = applicationsGallery.getElementsByClassName("content").item(0);

        let applicationsGalleryWidth: number = applicationsGallery.getBoundingClientRect().width;

        if (applicationsGalleryWidth != 0) {
            let relativeScroll = applicationsGallery.scrollLeft / applicationsGalleryWidth;
            let d = new Date();
            d.setTime(d.getTime() + (5 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = "GalleryScroll=" + relativeScroll + ";" + expires;
        }

        let closest: HTMLElement | null = null;
        let closestIndex = 0;
        let index = 0;

        const children: HTMLElement[] = applicationsGalleryContent?.childNodes ? Array.from(applicationsGalleryContent?.childNodes) as HTMLElement[] : [];

        for (let child of children) {
            if (closest) {
                let closestDistance = Math.abs(closest.getBoundingClientRect().left - (applicationsGalleryWidth - closest.getBoundingClientRect().width) / 2);
                let childDistance = Math.abs(child.getBoundingClientRect().left - (applicationsGalleryWidth - child.getBoundingClientRect().width) / 2);

                if (closestDistance > childDistance) {
                    closest = child;
                    closestIndex = index;
                }
            } else {
                closest = child;
                closestIndex = index;
            }

            index++;
        }

        this.selectedIndex = closestIndex;

        const images = applicationsGalleryContent?.getElementsByTagName("img");

        if (images) {
            for (let image of Array.from(images)) {
                image.style.transform = "";
            }
        }

        let bodyWidth = document.body.getBoundingClientRect().width;

        for (let child of children) {
            let distance = child.getBoundingClientRect().left - (applicationsGalleryWidth - child.getBoundingClientRect().width) / 2;
            let relativeDistance = distance / bodyWidth;

            if (relativeDistance > 0) {
                relativeDistance = Math.max(0, relativeDistance - 0.07);
            } else {
                relativeDistance = Math.min(0, relativeDistance + 0.07);
            }

            let angle = Math.min(45, Math.max(-45, relativeDistance * -360));

            for (let image of Array.from(child.getElementsByTagName("img"))) {
                image.style.transform = "translateX(-50%) translateY(-50%) rotateY(" + angle + "deg)";
            }
            for (let span of Array.from(child.getElementsByTagName("span"))) {
                span.style.transform = "rotateY(" + angle + "deg)";
            }
        }
    }

    touchedApplication(event: Event, applicationId: string, applicationIndex: number) {
        if (this.selectedIndex == applicationIndex) {

            this.$router.push({
                name: 'machine-application-item', params: {
                    applicationId: applicationId
                }
            })
        } else {
            this.selectedIndex = applicationIndex;

            let applicationsGallery = document.getElementById("applications-gallery");
            let applicationDom = event.target as HTMLElement;

            let bodyWidth: number = document.body.getBoundingClientRect().width;

            let centerX = (applicationsGallery?.scrollLeft ?? 0) + applicationDom.getBoundingClientRect().left + applicationDom.getBoundingClientRect().width / 2;
            let scrollLeft = centerX - bodyWidth / 2;

            applicationsGallery?.scroll({
                top: 0,
                left: scrollLeft,
                behavior: "smooth"
            });
        }
    }

    private scrolled: boolean = false;

    restoreScroll() {
        let cname = "GalleryScroll"
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        let scrollPosition: number | null = null;
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                scrollPosition = Number(c.substring(name.length, c.length));
                break;
            }
        }

        if (scrollPosition) {
            this.forceScrollLeft(scrollPosition);
        }
    }

    @Watch("applications", {immediate: true, deep: true})
    onApplications(newValue: Application[] | null, oldValue: Application[] | null) {
        this.restoreScroll();
    }

    forceScrollLeft(scrollPosition: number) {
        if (this.scrolled) {
            return;
        }

        let applicationsGallery = document.getElementById("applications-gallery");

        if (applicationsGallery) {
            let applicationsGalleryWidth: number = applicationsGallery.getBoundingClientRect().width;
            let scrollLeft = scrollPosition * applicationsGalleryWidth;
            applicationsGallery.scrollLeft = scrollLeft;

            let that = this;
            if (applicationsGallery.scrollLeft != scrollLeft) {
                //console.log("try again");

                setTimeout(function () {
                    that?.forceScrollLeft(scrollPosition);
                }, 150);
            } else {
                this.scrolled = true;
            }
        }
    }

    mounted() {
        this.restoreScroll();

        document.addEventListener('wheel', function (evt) {
            let applicationsGallery = document.getElementById("applications-gallery");

            if (applicationsGallery) {
                let scrollLeft = applicationsGallery.scrollLeft;

                applicationsGallery.scrollLeft = Math.max(0, scrollLeft + evt.deltaY);
            }
        });
    }
}
