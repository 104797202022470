import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {isDefined} from "@/utils/CheckerUtils";
import {Page} from "@/data/model/page";

export class Resource extends DatabaseModel {
    id!: string;
    style?: string | undefined;
    body?: string | undefined;
    images: { [string:string]: string };
    thumbnails: { [string:string]: string };
    thumbnail?: string | undefined;
    languages: string[];
    video?: string | undefined;
    subtitle?: string | undefined;
    autoplay?: boolean;
    backgroundColor?: string;
    @Collectable()
    subpages: Page[];

    constructor(data: {
        id: string,
        style?: string,
        body?: string,
        images: { [string:string]: string },
        thumbnails: { [string:string]: string },
        thumbnail?: string,
        languages: string[],
        video?: string,
        subtitle?: string,
        autoplay?: boolean,
        backgroundColor?: string,
        subpages: Page[],
    }) {
        super();
        this.id = data.id;
        this.style = data.style;
        this.body = data.body;
        this.images = data.images;
        this.thumbnails = data.thumbnails;
        this.thumbnail = data.thumbnail;
        this.languages = data.languages;
        this.video = data.video;
        this.subtitle = data.subtitle;
        this.autoplay = data.autoplay;
        this.backgroundColor = data.backgroundColor;
        this.subpages = data.subpages;
    }

    videoExists(): boolean {
        return this.video != null;
    }

    imageExists(x: number, y: number): boolean {
        return this.bestImageUrlForSize(x, y) != null;
    }

    thumbnailExists(x: number, y: number): boolean {
        return this.bestThumbnailUrlForSize(x, y) != null;
    }

    bestImageUrlForSize(x: number, y: number): string | null {
        return this.bestUrlForSize(x, y, this.images);
    }

    bestThumbnailUrlForSize(x: number, y: number): string | null {
        return this.bestUrlForSize(x, y, this.thumbnails);
    }

    private bestUrlForSize(x: number, y: number, urls: {[string:string]: string}): string | null {
        // console.log(`Matching for ${x}x${y}`);
        let key = "${x}x${y}";
        if (key in urls) {
            // console.log("Exact image match");
            return urls[key];
        }

        let match: string | null = null;
        let matchX: number | null = null;
        let matchY: number | null = null;

        for (let key in urls) {
            let value = urls[key];
            const parts = key.split("x");
            // console.log("   ", parts);
            if (parts.length == 2) {
                const valueX: number = parseInt(parts[0]);
                const valueY: number = parseInt(parts[1]);

                // console.log(`   Checking match ${valueX}x${valueY}`);

                if (valueX > x && valueY > y) {
                    if ((matchX == null || valueX < matchX || matchX < x) || (matchY == null || valueY < matchY || matchY < y)) {
                        // console.log(`   New/improved Match ${key}`);
                        match = value;
                        matchX = valueX;
                        matchY = valueY;
                    }
                } else if ((matchX == null || valueX > matchX) || (matchY == null || valueY > matchY)) {
                    // console.log(`   Backup match ${key} with ${valueX}x${valueY}`);
                    match = value;
                    matchX = valueX;
                    matchY = valueY;
                } else if (match == null) {
                    // console.log("Last resort: Returning first image");

                    match = value;
                }

            }
        }

        return match;
    }

    collectSelfUrls(): string[] {
        let urls: string[] = [];
        urls.push(...[this.video, this.subtitle, this.thumbnail].filter<string>(isDefined));
        urls.push(...Object.values(this.images).filter(isDefined))
        urls.push(...Object.values(this.thumbnails).filter(isDefined))
        return urls;
    }
}