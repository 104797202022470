import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ContentPageElement} from "@/data/model/contentpageelement";
import {Page} from "@/data/model/page";
import {Resource} from "@/data/model/resource";
import {Technology} from "@/data/model/technology";
import {Overview} from "@/data/model/overview";

export class ContentPage extends DatabaseModel {
    id: string;
    type?: string;
    title?: string;
    subtitle?: string;
    referenceTitle?: string;
    @Collectable()
    subpages: Page[];
    @Collectable()
    technologies: Technology[];
    @Collectable()
    overviews: Overview[];
    @Collectable()
    contentPageElements: ContentPageElement[];
    @Collectable()
    mainVideo?: Resource;
    @Collectable()
    images: Resource[];
    @Collectable()
    mainImage?: Resource;

    constructor(data: {
        id: string,
        type?: string,
        title?: string,
        subtitle?: string,
        referenceTitle?: string,
        subpages: Page[],
        technologies: Technology[],
        overviews: Overview[],
        contentPageElements: ContentPageElement[],
        mainVideo?: Resource,
        images: Resource[],
        mainImage?: Resource,
    }) {
        super();
        this.id = data.id;
        this.type = data.type;
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.referenceTitle = data.referenceTitle;
        this.subpages = data.subpages;
        this.technologies = data.technologies;
        this.overviews = data.overviews;
        this.contentPageElements = data.contentPageElements;
        this.mainVideo = data.mainVideo;
        this.images = data.images;
        this.mainImage = data.mainImage;
    }

    get sortedContentPageElements(): ContentPageElement[] {
        return this.contentPageElements.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }
}