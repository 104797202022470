import ElectronApi from "@/electron/ElectronApi";
import {AppRepository} from "@/data/app-repository";
import {DownloadManager} from "@/manager/DownloadManager";
import ElectronApiPlaceholder from "@/electron/ElectronApiPlaceholder";
import {LocaleManager} from "@/manager/LocaleManager";
import Tracker from "@/utils/Tracker";
import {CategoryRepository} from "@/data/category-repository";

class GlobalStore {
    electronApi!: ElectronApi;
    appRepository!: AppRepository;
    categoryRepository!: CategoryRepository
    downloadManager!: DownloadManager;
    localeManager!: LocaleManager;
    tracker!: Tracker;
}

const globalStore = new GlobalStore();
export default globalStore;

globalStore.localeManager = new LocaleManager();

// @ts-ignore
globalStore.electronApi = window.electronApi || new ElectronApiPlaceholder();

const appRepository = new AppRepository();
globalStore.appRepository = appRepository;
const categoryRepository = new CategoryRepository();
globalStore.categoryRepository = categoryRepository;
const downloadManager = new DownloadManager(appRepository);
globalStore.downloadManager = downloadManager;
const tracker = new Tracker();
globalStore.tracker = tracker;

globalStore.tracker.userLanguage(globalStore.localeManager.locale)
