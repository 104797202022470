











import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import router from "@/router";
import AppVue from "@/views/base/AppVue.vue";
import Interstitial from "@/components/Interstitial.vue";
import VideoResource from "@/components/VideoResource.vue";
import {Resource} from "@/data/model/resource";

@Component({
    components: {
        VideoResource,
        Interstitial,
        LoadingIndicator
    }
})
export default class AppIntroVideo extends AppVue {

    get introductionVideo(): Resource | null {
        let app = this.appInstance;
        return app?.introductionVideo?.videoExists() ? app?.introductionVideo : null;
    }

    skipIntro() {
        this.$tracker.trackEvent("app_intro_skipped")
        this.selectMachine();
    }

    onVideoEnded() {
        this.$tracker.trackEvent("app_intro_ended")
        this.selectMachine();
    }

    selectMachine() {
        let app = this.appInstance;

        if (app?.machines?.length == 1 && (app?.skipMachineOverview ?? true)) {
            let machine = app?.machines[0];

            router.push({
                name: 'machine-home',
                params: {appSlug: this.$route.params.appSlug, machineId: machine.id}
            })
        } else {
            router.push({
                name: 'app-machine-selection',
                params: {appSlug: this.$route.params.appSlug, machineId: this.$route.params.machineId}
            })
        }
    }

    beforeMount() {
        this.refreshApp();
    }

    created() {
        document.addEventListener('keyup', (evt) => {
            if (evt.key === "Escape") {
                this.skipIntro();
            }
        });
    }
}
