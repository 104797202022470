




import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ContentPageBase from "@/components/ContentPageBase.vue";
import {ContentPage} from "@/data/model/contentpage";
import ApplicationVue from "@/views/base/ApplicationVue.vue";

@Component({
    components: {
        ContentPageBase,
        LoadingIndicator
    }
})
export default class CaseStudyDetail extends ApplicationVue {
    get title() {
        let machine = this.machineInstance;
        return machine?.applicationsButtonTitle ?? null;
    }

    get subtitle() {
        return this.applicationInstance?.title
    }

    get contentPageId() {
        return this.contentPage?.id;
    }

    get contentPage(): ContentPage | null {
        let application = this.applicationInstance;
        return application?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
