import {DatabaseModel} from "@/data/model/database-model";

export class ConfigurationModule extends DatabaseModel {
    id: string;
    moduleId: string;
    position: number;

    constructor(data: {
        id: string,
        moduleId: string,
        position: number,
    }) {
        super();
        this.id = data.id;
        this.moduleId = data.moduleId;
        this.position = data.position;
    }

}