/* eslint-disable no-console */
import Vue from "vue";
import {Language} from "@/data/model/language";
import {Route} from "vue-router";
import globalStore from "@/globalstore";
import AppApi from "@/api/app-api";
import router from "@/router";

export class LocaleManager {
    private _locale!: string;
    private _state = new Vue({
        data: {
            locale: "",
            changingLocale: false
        }
    });
    private listeners = new Set<LocalChangedListener>();

    constructor() {
        const localLocale = localStorage.getItem("appLocale");
        this._locale = localLocale ?? this.defaultLocale;
        this._state.locale = this._locale;

        this._state.$watch('locale', (newLocale: string, oldLocale: string) => {
            globalStore.tracker.userLanguage(newLocale)
        })
    }

    addListener(listener: LocalChangedListener) {
        this.listeners.add(listener);
    }

    removeListener(listener: LocalChangedListener) {
        this.listeners.delete(listener);
    }

    get defaultLocale(): string {
        if (typeof navigator.languages !== 'undefined')
            return navigator.languages[0].split("-")[0];
        else
            return navigator.language[0].split("-")[0];
    }

    get locale(): string {
        // console.log("Getting locale");
        return this._state.locale;
    }

    get isChangingLocale(): boolean {
        return this._state.changingLocale;
    }

    switchLocale(value: string, suppressListeners: boolean = false) {
        // Should never occur, but you never know
        if (value == null) return;
        value = value.split("-")[0];
        if (this._locale != value) {
            console.debug(`Locale changed to ${value} (was ${this._locale}) suppressListeners=${suppressListeners}`);
            localStorage.setItem("appLocale", value);
            this._locale = value;
            this._state.locale = value;

            if (!suppressListeners)
                this.listeners.forEach(listener => listener.onLocaleChanged(value))

            const app = router.currentRoute.params.appSlug ?? process.env.VUE_APP_FORCED_APP;
            // console.log(`App ${app}`)
            // console.debug("changingLocale=true", app)
            if (app != null) {
                this._state.changingLocale = true;
                AppApi.getApp(app, true, true, value)
                    .then(result => {
                        // console.debug("changingLocale=false")
                        this._state.changingLocale = false;
                        // console.log(result);
                    })
            }
        }
    }

    set locale(value: string) {
        this.switchLocale(value);
    }

    limitTo(languages: Language[]): boolean {
        // console.log("Languages", languages);
        const currentLanguage = this.locale;
        for (let language of languages) if (currentLanguage == language.identifier) return false;

        const bestLanguage = languages.find(language => language.default && language.identifier != null) ??
            languages.find(language => language.identifier != null);
        if (bestLanguage?.identifier != null) {
            // console.log(`Limiting locale to language ${bestLanguage.identifier}`);
            this.locale = bestLanguage.identifier;
            return true
        }
        return false
    }

    /**
     * Takes the given route
     * @return The edited route if the locale was changed or undefined if the route hasn't been edited
     * @param route
     */
    applyTo(route: Route): Route | undefined {
        const toRouteHasLocale = "locale" in route.params;
        const isLocaleSet = route.params.locale != null;

        if (!(toRouteHasLocale && isLocaleSet)) {
            return undefined;
        }

        const locale = this.locale;
        if (route.params.locale != locale) {
            route.params.locale = locale;
            return route
        }
        return undefined
    }
}

export interface LocalChangedListener {
    onLocaleChanged(newLocale: string): void
}