
















/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import Button from "@/components/Button.vue";
import LinkButton from "@/components/LinkButton.vue";
import {Module} from "@/data/model/module";
@Component({
    components: {LinkButton, Button, ImageResource}
})
export default class ModuleItem extends Vue {
    @Prop() private module!: Module;
    @Prop() private enabled!: Boolean;

    get image(): Resource | null {
        let module = this.module;
        return module?.frontView?.imageExists(300, 300) ? module?.frontView : null;
    }
    get imageOpened(): Resource | null {
        let module = this.module;
        return module?.topOpenedView?.imageExists(300, 300) ? module?.topOpenedView : null;
    }
    get imageTop(): Resource | null {
        let module = this.module;
        return module?.topView?.imageExists(300, 300) ? module?.topView : null;
    }

    get moduleTitle() {
        return this.module?.title;
    }

    get modulePlacement() {
        return this.module?.placement;
    }

    click() {
        this.$emit("click", this.module);
    }

    mouseDownHandler(e: any) {
        if (!this.enabled) {
            return;
        }

        e.preventDefault();

        let dragX: null|number = null;
        let dragY: null|number = null;

        if (e.type === "touchstart") {
            dragX = e.touches[0].clientX;
            dragY = e.touches[0].clientY;
        } else {
            dragX = e.clientX;
            dragY = e.clientY;
        }
        if (dragX !== null && dragY !== null) {
            let offsetX = dragX - e.target.offsetLeft;
            let offsetY = dragY - e.target.offsetTop;

            if (e.target.offsetWidth != 0) {
                offsetX = offsetX / e.target.offsetWidth * 100;
            }
            if (e.target.offsetHeight != 0) {
                offsetY = offsetY / e.target.offsetHeight * 100;
            }

            this.$emit("startdragging", this.module, dragX, dragY, offsetX + "%", offsetY + "%");
        }
    }
}
