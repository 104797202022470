import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ConfigurationModule} from "@/data/model/configurationmodule";

export class Configuration extends DatabaseModel {
    id: string;
    title: string;
    isDefault: boolean;
    @Collectable()
    modules: ConfigurationModule[];

    constructor(data: {
        id: string,
        title: string,
        isDefault: boolean,
        modules: ConfigurationModule[],
    }) {
        super();
        this.id = data.id;
        this.title = data.title;
        this.isDefault = data.isDefault;
        this.modules = data.modules;
    }

    get sortedModules(): ConfigurationModule[] {
        return this.modules?.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        }) ?? [];
    }
}