













import {Component, Vue} from "vue-property-decorator";
import Button from "@/components/Button.vue";
import Row from "@/components/Row.vue";
import Rows from "@/components/Rows.vue";

@Component({
    components: {Button, 
        Rows,
        Row}
})
export default class NotFound extends Vue {

}
