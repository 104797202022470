



























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Resource} from "@/data/model/resource";
import globalStore from "@/globalstore";
import {App} from "@/data/model/app";
import AppVue from "@/views/base/AppVue.vue";

@Component
export default class VideoResource extends AppVue {
    @Prop() protected video!: Resource;
    @Prop({default: false}) protected fullscreen!: Boolean;
    @Prop({default: false}) protected overrideAutoplay!: Boolean;
    @Prop({default: false}) protected play!: Boolean;
    @Prop({default: 400}) protected thumbnailSize!: number;

    @Watch("play", {immediate: true, deep: true})
    onPlay(newValue: boolean, oldValue: boolean) {
        if (newValue && oldValue == false) {
            let videoElement = document.getElementById(this.video.id) as HTMLVideoElement
            videoElement.play();
        }
    }

    onPlaying() {
        this.$tracker.trackVideo(this.video.id, this.video.body, true);
        this.$emit("playing");
    }

    onPause() {
        let videoElement = document.getElementById(this.video.id) as HTMLVideoElement;

        if (videoElement.readyState == 4) {
            this.$tracker.trackVideo(this.video.id, this.video.body, true);
            this.$emit("pause");
        }
    }

    onScreenChange() {
        let videoElement = document.getElementById(this.video.id) as HTMLVideoElement;
        let is_fullscreen = false;

        if( window.innerHeight == screen.height) {
          is_fullscreen = true;
        }

        if (is_fullscreen) {
          videoElement.play()
        } else {
          videoElement.pause();
        }
    }

    get autoplay(): boolean {
        if (this.overrideAutoplay) {
            return true;
        }
        if (this.video.autoplay) {
            return true;
        }
        return false;
    }

    get locale(): string | null {
        return globalStore.localeManager.locale;
    }

    get url(): string | null {
        let video = this.video?.video;
        if (video != null) {
            return globalStore.downloadManager.handleUrl(video);
        }
        return video ?? null;
    }

    get subtitle(): string | null {
        let subtitle = this.video?.subtitle;
        if (subtitle != null) {
            return globalStore.downloadManager.handleUrl(subtitle);
        }
        return subtitle ?? null;
    }

    get thumbnail(): string | null {
        const thumbnail = this.video?.bestThumbnailUrlForSize(this.thumbnailSize, this.thumbnailSize);
        if (thumbnail != null) {
            return globalStore.downloadManager.handleUrl(thumbnail);
        }
        return thumbnail;
    }

    handleEnded() {
        this.$emit("ended");
    }

    requestFullscreenFunc(){
        const video = document.getElementById(this.video.id) as HTMLVideoElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };

        // start the video before the fullscreen enter
        video.play()

        if(typeof video.msRequestFullscreen == "function"){ video?.msRequestFullscreen(); }
        else if(typeof video.mozRequestFullScreen == "function"){ video?.mozRequestFullScreen(); }
        else if(typeof video.webkitRequestFullscreen == "function"){ video?.webkitRequestFullscreen(); }
        else{ video?.requestFullscreen(); }
    }
}
