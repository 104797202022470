









import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class QuestionnaireContentTextArea extends Vue {
  @Prop() public question!: string;
  @Prop() public clickHandler!: Function;
  @Prop() public value!: string;
  private inputValue: string|null = this.value ?? null;
}
