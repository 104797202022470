

























import {Component, Prop, Vue} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import {Tooltip} from "@/data/model/tooltip";
import {Overview} from "@/data/model/overview";
import Columns from "@/components/Columns.vue";
import Column from "@/components/Column.vue";

@Component({
    components: {
        Column,
        Columns,
        ImageResource,
    }
})
export default class OverviewBase extends Vue {
    @Prop() public overviewInstance!: Overview;

    get overviewBody(): String|null {
        let overview = this.overviewInstance;
        return overview?.body ?? null;
    }

    get overviewImage(): Resource | null {
        let overview = this.overviewInstance;
        return overview?.image ?? null;
    }

    get overviewTooltips(): Tooltip[] {
        let overview = this.overviewInstance;
        return overview?.tooltips ?? [];
    }

    openTooltip(tooltip: Tooltip) {
        this.$emit("tooltip", tooltip);
    }

    isBalloon(tooltip: Tooltip) {
        return !tooltip.showAsVideo && tooltip.type == "tool";
    }

    tooltipStyle(tooltip: Tooltip) {
        let x = tooltip.x;
        let y = tooltip.y;

        if (x && y) {
            return "left: " + (x * 100) + "%; top: " + (y * 100) + "%";
        }

        return "";
    }

    tooltipClass(tooltip: Tooltip) {
        let prefix = "";

        if ((tooltip.title?.length ?? 0) > 0) {
            prefix = "text ";
        }

        if (tooltip.showAsVideo) {
            return prefix + "tooltip_video";
        }

        let type = tooltip.type;

        if (type) {
            return prefix + "tooltip_" + type;
        }

        return prefix;
    }

    overviewSize() {
        let img = document.getElementById("overview-image");
        let tooltipsContainer = document.getElementById("overview-tooltips");

        if (img && tooltipsContainer) {
            let style = getComputedStyle(img, null);
            tooltipsContainer.style.width = style.width;
            tooltipsContainer.style.height = style.height;
        }
    }

    mounted() {
        let that = this;
        that.overviewSize();

        window.addEventListener('resize', function () {
            that?.overviewSize();
        });
    }
}
