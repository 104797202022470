
import {Component} from "vue-property-decorator";
import {Module} from "@/data/model/module";
import ConfiguratorVue from "./ConfiguratorVue.vue";

@Component({})
export default class ModuleVue extends ConfiguratorVue {
    get moduleInstance(): Module | null {
        return this.configuratorModules.find((item: Module) => item.id == this.routerParams.moduleId) ?? null
    }
}
