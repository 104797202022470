import {DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";

export class ContentPageElement extends DatabaseModel {
    id: string;
    position?: number;
    type?: string;
    title?: string;
    body?: string;
    image?: Resource;
    align?: string;

    constructor(data: {
        id: string,
        position?: number,
        type?: string,
        title?: string,
        body?: string,
        image?: Resource,
        align?: string
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.type = data.type;
        this.title = data.title;
        this.body = data.body;
        this.image = data.image;
        this.align = data.align;
    }
}