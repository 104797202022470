






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class Row extends Vue {
    @Prop({default: true}) protected centerVertical!: boolean;
    @Prop({default: 1}) protected rows!: number;
}
