/* eslint-disable no-console */
import isElectron from "@/utils/ElectronCheck";
import {DatabaseObserver} from "@/data/database-observer";
import {AppRepository} from "@/data/app-repository";
import {OfflineStorageListener} from "@/manager/OfflineStorageListener";
import Vue from 'vue';
import globalStore from "@/globalstore";
import {OfflineStorageInterface, ProgressUpdateType} from "@/manager/OfflineStorageInterface";
import {App} from "@/data/model/app";

const ENABLED_KEY = "offlineStorageEnabled";

export class DownloadManager implements OfflineStorageListener {
    private appRepository: AppRepository;

    private liveDatas: Array<DatabaseObserver> = [];

    private apps: App[] = [];
    private downloadMode: DownloadMode;
    private offlineStorage: OfflineStorageInterface | null = null;

    private offlineDownloadEnabled = false;

    private _state: {
        totalDownloads: number,
        totalDownloaded: number,
        currentlyDownloading: number[],
        totalProgress: number,
        downloading: boolean,
        enabled: boolean
    } = Vue.observable({
        totalDownloads: 0,
        totalDownloaded: 0,
        currentlyDownloading: [],
        totalProgress: 0.0,
        downloading: false,
        enabled: false
    });

    constructor(appRepository: AppRepository) {
        this.appRepository = appRepository;
        this.offlineDownloadEnabled = JSON.parse(localStorage.getItem(ENABLED_KEY) ?? "false") == true;
        this._state.enabled = this.offlineDownloadEnabled;
        this.downloadMode = DownloadMode.Unsupported;
    }

    prepare() {
        if (isElectron()) {
            this.downloadMode = DownloadMode.Electron;
            const type = require('./OfflineStorageInterfaceImplementation').OfflineStorageInterfaceImplementation;
            this.offlineStorage = new type();
            this.offlineStorage!!.addListener(this);
        } else if ('serviceWorker' in navigator) {
            // this.downloadMode = DownloadMode.ServiceWorker;
        }

        // console.log('Download mode set to ', this.downloadMode);

        if (this.offlineStorage != null) {
            this.startListening();
        }

        // if (!this.offlineDownloadEnabled) {
        //     this.offlineStorage?.cancelAndCleanup();
        // }
    }

    get downloadsSupported(): boolean {
        return this.downloadMode != DownloadMode.Unsupported
    }

    toggle() {
        this.enable(!this.offlineDownloadEnabled);
    }

    get enabled(): boolean {
        return this._state.enabled;
    }

    get totalDownloads(): number {
        return this._state.totalDownloads
    }

    get totalDownloaded(): number {
        return this._state.totalDownloaded
    }

    get currentlyDownloading(): number[] {
        return this._state.currentlyDownloading
    }

    get totalProgress(): number {
        return this._state.totalProgress
    }

    get downloading(): boolean {
        return this._state.downloading
    }

    public enable(enabled: boolean) {
        this.offlineDownloadEnabled = enabled;
        this._state.enabled = this.offlineDownloadEnabled;
        localStorage.setItem(ENABLED_KEY, enabled + "");
        if (!enabled) {
            // this.offlineStorage?.cancelAndCleanup();
        } else {
            this.triggerUpdate();
        }
        this.onProgressUpdate(ProgressUpdateType.MAJOR);
    }

    onProgressUpdate(type: ProgressUpdateType): void {
        if (this.offlineStorage == null) return;
        // console.log(`Progress update: ${type}`)
        if (type == ProgressUpdateType.MAJOR) {
            this._state.totalDownloaded = this.offlineStorage!!.totalDownloadedCount;
            this._state.totalDownloads = this.offlineStorage!!.totalDownloadCount;
            this._state.currentlyDownloading = this.offlineStorage!!.getCurrentRequestProgressions();
        } else {
            this._state.currentlyDownloading = this.offlineStorage!!.getCurrentRequestProgressions();
        }
        this._state.downloading = this.offlineStorage!!.isDownloading;

        let totalProgress = this._state.totalDownloaded;
        this._state.currentlyDownloading.forEach(progress => totalProgress += progress);
        this._state.totalProgress = totalProgress;

        if (this.offlineStorage!!.isDownloading) {
            // globalStore.electronApi.setBadge(`${this._state.totalDownloaded}/${this._state.totalDownloads}`);
            if (this._state.totalDownloads > 0) {
                globalStore.electronApi.setProgress(totalProgress / this._state.totalDownloads, "normal")
            } else {
                globalStore.electronApi.setProgress(-1, "none")
            }
        } else {
            // globalStore.electronApi.setBadge(`${this._state.totalDownloaded}/${this._state.totalDownloads}`);
            globalStore.electronApi.setProgress(-1, "none")
        }
        if (!this.offlineDownloadEnabled) {
            globalStore.electronApi.setProgress(-1, "none");
            return
        }
        // console.log(`Total progress `)
    }

    cleanup() {
        this.offlineStorage?.cancelAndCleanup()
    }

    private startListening() {
        console.log("Started listening to the database for download changes");
        this.liveDatas.push(this.appRepository.getAllAppsLive((data) => {
            this.apps = data;
            this.updateDownloadList();
        }));
    }

    private updateDownloadList() {
        if (!this.offlineDownloadEnabled) return;
        if (this.downloadMode == DownloadMode.Unsupported) return;
        let toDownload: string[] = [];

        if (process.env.VUE_APP_FORCED_APP != null) {
            let app = this.apps.filter(app => app.urlPath == process.env.VUE_APP_FORCED_APP);
            if (app.length > 0) {
                toDownload = toDownload.concat(app[0].collectUrls())
            }
        } else {
            // TODO: Limit to currently selected app?
            for (let app of this.apps) {
                toDownload = toDownload.concat(app.collectUrls())
            }
        }

        // Some nonsense URL to test failure
        // toDownload = ["400x200"]
        // toDownload = ["https://www.dakda0iofajfi09uqjiufhjnujfhasufhasfasfasfasfasf.com"]

        // console.log("Updated download list", toDownload);
        // this.toDownload = toDownload;

        if (this.downloadMode == DownloadMode.Electron) {
            this.offlineStorage!!.retainOnly(toDownload);

            for (let download of toDownload) {
                this.offlineStorage!!.download(download)
            }
        }
    }

    prioritize(url: string | string[]) {
        if (this.offlineStorage != null) {
            this.offlineStorage.prioritize(url)
        }
    }

    triggerUpdate() {
        // console.log("Triggering update");
        this.updateDownloadList();
    }

    getFilePathForUrl(url: string): string | null {
        console.log(`DownloadManager checking ${url}`);
        if (this.offlineStorage != null) {
            return this.offlineStorage.localPathForUrl(url)
        }
        return null
    }

    /**
     * @deprecated TODO: I'm not sure if we should make this async in the future, considering it has to do disk lookups (currently). Maybe we should just cache the disk status for URL's?
     *              Maybe we should also (optionally) return an observable here, that will update content once it has been downloaded/removed automatically
     */
    handleUrl(url: string) {
        if (this.offlineStorage != null && this.enabled) {
            if (this.offlineStorage.has(url)) {
                const path = this.offlineStorage.localPathForUrl(url);
                if (path != null) {
                    return "file://" + path;
                }
            }
            console.log(`Failed to resolve ${url} in offline storage`)
        }
        return url
    }
}

export enum DownloadMode {
    // eslint-disable-next-line no-unused-vars
    Unsupported,
    // eslint-disable-next-line no-unused-vars
    Electron
}