
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Configurator} from "@/data/model/configurator";
import {Module} from "@/data/model/module";
import {Option} from "@/data/model/option";

@Component({})
export default class ConfiguratorVue extends MachineVue {
    get configuratorInstance(): Configurator | null {
        return this.machineConfigurators.find((item: Configurator) => item.id == this.routerParams.configuratorId) ?? null
    }

    get configuratorModules(): Module[] {
        return this.configuratorInstance?.modules ?? [];
    }

    get configuratorModuleGroups(): Module[] {
        return this.configuratorInstance?.moduleGroups ?? [];
    }

    get configuratorOptions(): Option[] {
        return this.configuratorInstance?.options ?? [];
    }
}
