import {App} from "@/data/model/app";
import {Application} from "@/data/model/application";
import {Benefit} from "@/data/model/benefit";
import {BenefitElement} from "@/data/model/benefitelement";
import {CaseStudy} from "@/data/model/casestudy";
import {Configurator} from "@/data/model/configurator";
import {Contact} from "@/data/model/contact";
import {ContentPage} from "@/data/model/contentpage";
import {ContentPageElement} from "@/data/model/contentpageelement";
import {Language} from "@/data/model/language";
import {Machine} from "@/data/model/machine";
import {Module} from "@/data/model/module";
import {ModuleGroup} from "@/data/model/modulegroup";
import {Option} from "@/data/model/option";
import {Optional} from "@/data/model/optional";
import {OptionalVisual} from "@/data/model/optionalvisual";
import {Overview} from "@/data/model/overview";
import {Page} from "@/data/model/page";
import {Resource} from "@/data/model/resource";
import {Technology} from "@/data/model/technology";
import {Tooltip} from "@/data/model/tooltip";
import {User} from "@/data/model/user";
import {BenefitCategory} from "@/data/model/benefitcategory";
import {AppCategory} from "@/data/model/appcategory";
import {OptionalVisualVideo} from "@/data/model/optionalvisualvideo";
import {MachineImage} from "@/data/model/machineimage";
import {MachineImageBenefit} from "@/data/model/machineimagebenefit";
import {Configuration} from "@/data/model/configuration";
import {ConfigurationModule} from "@/data/model/configurationmodule";
import {AppCategoryDto} from "@/api/dto/appcategory-dto";

/**
 * Serializes from and to database models
 */
export class DbConverter {

    static toApp(data?: any): App | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new App({
            id: data.id,
            published: data.published,
            callToActionTitle: data.callToActionTitle,
            callToActionButtonTitle: data.callToActionButtonTitle,
            skipIntroductionButtonTitle: data.skipIntroductionButtonTitle,
            introductionButtonTitle: data.introductionButtonTitle,
            introductionBody: data.introductionBody,
            subtitle: data.subtitle,
            title: data.title,
            callToActionBody: data.callToActionBody,
            urlPath: data.urlPath,
            skipMachineOverview: data.skipMachineOverview,
            preview: data.preview,
            type: data.type,
            region: data.region,
            windowsInstaller: data.windowsInstaller,
            iosUrl: data.iosUrl,
            androidUrl: data.androidUrl,
            pages: data.pages?.map((item: any) => this.toPage(item)!!),
            icon: this.toResource(data.icon),
            introductionImage: this.toResource(data.introductionImage),
            introductionVideo: this.toResource(data.introductionVideo),
            mainImage: this.toResource(data.mainImage),
            languages: data.languages?.map((item: any) => this.toLanguage(item)!!),
            contacts: data.contacts?.map((item: any) => this.toContact(item)!!),
            overviews: data.overviews?.map((item: any) => this.toOverview(item)!!),
            caseStudies: data.caseStudies?.map((item: any) => this.toCaseStudy(item)!!),
            machines: data.machines?.map((item: any) => this.toMachine(item)!!),
            callToActionImage: this.toResource(data.callToActionImage),
            benefitCategories: data.benefitCategories?.map((item: any) => this.toBenefitCategory(item)!!),
            categories: data.categories?.map((item: any) => this.toAppCategory(item)!!),
            landing: data.landing?.map((item: any) => this.toAppCategory(item)!!),
            category: this.toAppCategory(data.category),
            currentCountry: data.currentCountry,
            countries: data.countries,
            defaultForCountry: data.defaultForCountry,
            siblings: data.siblings?.map((item: any) => this.toApp(item)!!),
            children: data.children?.map((item: any) => this.toApp(item)!!),
        });
    }

    static toAppCategory(data?: any): AppCategory | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new AppCategory({
            id: data.id,
            title: data.title,
            position: data.position,
            apps: data.apps?.map((item: any) => this.toApp(item)!!),
        });
    }

    static toApplication(data?: any): Application | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Application({
            id: data.id,
            position: data.position,
            title: data.title,
            thumbnail: this.toResource(data.thumbnail),
            contentPage: this.toContentPage(data.contentPage),
        });
    }

    static toBenefit(data?: any): Benefit | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Benefit({
            id: data.id,
            position: data.position,
            title: data.title,
            technologies: data.technologies?.map((item: any) => this.toTechnology(item)!!),
            benefitElements: data.benefitElements?.map((item: any) => this.toBenefitElement(item)!!),
            mainVideo: this.toResource(data.mainVideo),
            mainImage: this.toResource(data.mainImage),
            category: this.toBenefitCategory(data.category)
        });
    }

    static toBenefitElement(data?: any): BenefitElement | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new BenefitElement({
            id: data.id,
            position: data.position,
            title: data.title,
            body: data.body,
            moreButtonTitle: data.moreButtonTitle,
            technologies: data.technologies?.map((item: any) => this.toTechnology(item)!!),
            image: this.toResource(data.image),
        });
    }

    static toBenefitCategory(data?: any): BenefitCategory | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new BenefitCategory({
            id: data.id,
            title: data.title,
            position: data.position,
        });
    }

    static toCaseStudy(data?: any): CaseStudy | undefined {
        if (data == null) return undefined;
        // console.log("data", data);

        return new CaseStudy({
            id: data.id,
            position: data.position,
            metadata: data.metadata,
            title: data.title,
            applications: data.applications?.map((item: any) => this.toApplication(item)!!),
            thumbnail: this.toResource(data.thumbnail),
            contentPage: this.toContentPage(data.contentPage),
            machines: data.machines?.map((item: any) => this.toMachine(item)!!),
            filter_machines: data.filter_machines,
        });
    }

    static toConfigurator(data?: any): Configurator | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Configurator({
            id: data.id,
            title: data.title,
            buttonTitle: data.buttonTitle,
            productDepth: data.productDepth,
            productHeight: data.productHeight,
            productFloorOffset: data.productFloorOffset,
            productWallOffset: data.productWallOffset,
            position: data.position,
            modules: data.modules?.map((item: any) => this.toModule(item)!!),
            moduleGroups: data.moduleGroups?.map((item: any) => this.toModuleGroup(item)!!),
            options: data.options?.map((item: any) => this.toOption(item)!!),
            configurations: data.configurations?.map((item: any) => this.toConfiguration(item)!!),
        });
    }

    static toConfiguration(data?: any): Configuration | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Configuration({
            id: data.id,
            title: data.title,
            isDefault: data.isDefault,
            modules: data.modules?.map((item: any) => this.toConfigurationModule(item)!!),
        });
    }

    static toConfigurationModule(data?: any): ConfigurationModule | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new ConfigurationModule({
            id: data.id,
            moduleId: data.moduleId,
            position: data.position,
        });
    }

    static toContact(data?: any): Contact | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Contact({
            id: data.id,
            position: data.position,
            title: data.title,
            url: data.url,
            enabled: data.enabled,
        });
    }

    static toContentPage(data?: any): ContentPage | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new ContentPage({
            id: data.id,
            type: data.type,
            title: data.title,
            referenceTitle: data.referenceTitle,
            subpages: data.subpages?.map((item: any) => this.toPage(item)!!),
            technologies: data.technologies?.map((item: any) => this.toTechnology(item)!!),
            overviews: data.overviews?.map((item: any) => this.toOverview(item)!!),
            contentPageElements: data.contentPageElements?.map((item: any) => this.toContentPageElement(item)!!),
            mainVideo: this.toResource(data.mainVideo),
            images: data.images?.map((item: any) => this.toResource(item)!!),
            mainImage: this.toResource(data.mainImage),
        });
    }

    static toContentPageElement(data?: any): ContentPageElement | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new ContentPageElement({
            id: data.id,
            position: data.position,
            type: data.type,
            title: data.title,
            body: data.body,
            image: this.toResource(data.image),
            align: data.align,
        });
    }

    static toLanguage(data?: any): Language | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Language({
            id: data.id,
            identifier: data.identifier,
            title: data.title,
            position: data.position,
            default: data.default
        });
    }

    static toMachine(data?: any): Machine | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Machine({
            id: data.id,
            subtitle: data.subtitle,
            optionalsButtonTitle: data.optionalsButtonTitle,
            caseStudiesButtonTitle: data.caseStudiesButtonTitle,
            applicationsButtonTitle: data.applicationsButtonTitle,
            benefitsButtonTitle: data.benefitsButtonTitle,
            skipIntroductionButtonTitle: data.skipIntroductionButtonTitle,
            introductionButtonTitle: data.introductionButtonTitle,
            introductionBody: data.introductionBody,
            body: data.body,
            title: data.title,
            footprintTitle: data.footprintTitle,
            position: data.position,
            benefits: data.benefits?.map((item: any) => this.toBenefit(item)!!),
            applications: data.applications?.map((item: any) => this.toApplication(item)!!),
            introductionImage: this.toResource(data.introductionImage),
            introductionVideo: this.toResource(data.introductionVideo),
            mainImage: this.toResource(data.mainImage),
            benefitsImage: this.toResource(data.benefitsImage),
            optionals: data.optionals?.map((item: any) => this.toOptional(item)!!),
            optionalVisuals: data.optionalVisuals?.map((item: any) => this.toOptionalVisual(item)!!),
            technologies: data.technologies?.map((item: any) => this.toTechnology(item)!!),
            machineImages: data.machineImages?.map((item: any) => this.toMachineImage(item)!!),
            configurators: data.configurators?.map((item: any) => this.toConfigurator(item)!!),
        });
    }

    static toMachineImage(data?: any): MachineImage | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new MachineImage({
            id: data.id,
            position: data.position,
            benefits: data.benefits?.map((item: any) => this.toMachineImageBenefit(item)!!),
            image: this.toResource(data.image),
            buttonsImage: this.toResource(data.buttonsImage),
        });
    }

    static toMachineImageBenefit(data?: any): MachineImageBenefit | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new MachineImageBenefit({
            id: data.id,
            x: data.x,
            y: data.y,
            benefitId: data.benefitId,
            pageId: data.pageId,
        });
    }

    static toModule(data?: any): Module | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Module({
            id: data.id,
            position: data.position,
            arrangingDepth: data.arrangingDepth,
            insetRight: data.insetRight,
            width: data.width,
            insetLeft: data.insetLeft,
            groupMaximum: data.groupMaximum,
            maximum: data.maximum,
            placement: data.placement,
            height: data.height,
            depth: data.depth,
            title: data.title,
            contentPage: this.toContentPage(data.contentPage),
            moduleGroup: this.toModuleGroup(data.moduleGroup),
            topOpenedView: this.toResource(data.topOpenedView),
            topView: this.toResource(data.topView),
            frontView: this.toResource(data.frontView),
        });
    }

    static toModuleGroup(data?: any): ModuleGroup | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new ModuleGroup({
            id: data.id,
            name: data.name,
        });
    }

    static toOption(data?: any): Option | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Option({
            id: data.id,
            position: data.position,
            title: data.title,
            contentPage: this.toContentPage(data.contentPage),
            icon: this.toResource(data.icon),
        });
    }

    static toOptional(data?: any): Optional | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Optional({
            id: data.id,
            position: data.position,
            isDefault: data.isDefault,
            title: data.title,
            body: data.body,
            requires: data.requires ?? [],
            compatibleWith: data.compatibleWith ?? [],
        });
    }

    static toOptionalVisual(data?: any): OptionalVisual | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new OptionalVisual({
            id: data.id,
            isDefault: data.isDefault,
            optionals: data.optionals ?? [],
            footprintImage: this.toResource(data.footprintImage),
            image: this.toResource(data.image),
            videos: data.videos?.map((item: any) => this.toOptionalVisualVideo(item)!!),
        });
    }

    static toOptionalVisualVideo(data?: any): OptionalVisualVideo | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new OptionalVisualVideo({
            id: data.id,
            x: data.x,
            y: data.y,
            page: this.toPage(data.page),
        });
    }

    static toOverview(data?: any): Overview | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Overview({
            id: data.id,
            position: data.position,
            prominent: data.prominent,
            home: data.home,
            title: data.title,
            body: data.body,
            tooltips: data.tooltips?.map((item: any) => this.toTooltip(item)!!),
            image: this.toResource(data.image),
        });
    }

    static toPage(data?: any): Page | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Page({
            id: data.id,
            position: data.position,
            prominent: data.prominent,
            title: data.title,
            thumbnail: this.toResource(data.thumbnail),
            contentPage: this.toContentPage(data.contentPage),
        });
    }

    static toResource(data?: any): Resource | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Resource({
            id: data.id,
            style: data.style,
            body: data.body,
            images: data.images,
            thumbnails: data.thumbnails,
            thumbnail: data.thumbnail,
            languages: data.languages,
            video: data.video,
            subtitle: data.subtitle,
            autoplay: data.autoplay,
            backgroundColor: data.backgroundColor,
            subpages: data.subpages?.map((item: any) => this.toPage(item)!!),
        });
    }

    static toTechnology(data?: any): Technology | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Technology({
            id: data.id,
            position: data.position,
            prominent: data.prominent,
            title: data.title,
            logo: this.toResource(data.logo),
            contentPage: this.toContentPage(data.contentPage),
        });
    }

    static toTooltip(data?: any): Tooltip | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new Tooltip({
            id: data.id,
            type: data.type,
            showAsVideo: data.showAsVideo,
            x: data.x,
            y: data.y,
            title: data.title,
            body: data.body,
            page: this.toPage(data.page),
            video: this.toResource(data.video),
        });
    }

    static toUser(data?: any): User | undefined {
        if (data == null) return undefined;
        // console.log("data", data);
        return new User({
            id: data.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
        });
    }
}