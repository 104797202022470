

















/* eslint-disable no-console */
import {Component} from 'vue-property-decorator';
import router from "@/router";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MachineItem from "@/components/MachineItem.vue";
import {Machine} from "@/data/model/machine";
import AppVue from "@/views/base/AppVue.vue";
import Button from "@/components/Button.vue";
import PageBase from "@/components/PageBase.vue";
import Row from "@/components/Row.vue";
import Rows from "@/components/Rows.vue";

@Component({
    components: {
        Rows,
        Row,
        PageBase,
        Button,
        LoadingIndicator,
        MachineItem
    }
})
export default class AppMachineSelection extends AppVue {
    get machines(): Machine[] {
        if(this.appInstance?.sortedMachines.length == 1){
            this.skip();
        }
        return this.appInstance?.sortedMachines ?? [];
    }

    skip(){
        if(this.appInstance?.sortedMachines.length == 1){
            let machine = this.appInstance?.machines[0];

            router.replace({
                name: 'machine-home',
                params: {appSlug: this.$route.params.appSlug, machineId: machine.id}
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')){
                    console.log(err);
                }
            });
        }
    }

    selectMachine(machine: Machine) {
        this.$tracker.selectContent(machine.id, this.machines.map(item => {
            return {
                item_id: item.id,
                item_name: item.title
            }
        }), "machine")
        router.push({
            name: 'machine-home',
            params: {appSlug: this.$route.params.appSlug, machineId: machine.id}
        })
        // this.$route.params.appSlug
    }

    get callToActionButtonTitle(): String | null {
        let app = this.appInstance
        return app?.callToActionButtonTitle && app?.contacts?.length > 0 ? app?.callToActionButtonTitle : null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
