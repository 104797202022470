
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Application} from "@/data/model/application";

@Component({})
export default class ApplicationVue extends MachineVue {
    get applicationInstance(): Application | null {
        return this.machineApplications.find((item: Application) => item.id == this.routerParams.applicationId) ?? null
    }
}
