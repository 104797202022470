


















import {Component, Prop, Vue} from 'vue-property-decorator';
import Navigation, {NavigationStyle} from "@/components/Navigation.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";
import DownloadMode from "@/components/DownloadMode.vue";

@Component({
    components: {
        DownloadMode,
        LanguageSelect,
        Navigation
    }
})
export default class PageBase extends Vue {
    @Prop() public title!: String;
    @Prop() public subtitle!: String;
    @Prop() public preview!: boolean;
    @Prop({default: NavigationStyle.Default}) public navigationStyle!: NavigationStyle;
    @Prop({default: false}) public fill!: Boolean;
    @Prop({default: false}) private csaLogo!: boolean;
    public isIe: Boolean = false;

    mounted() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        this.isIe = msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+'px');

        window.addEventListener('resize', function () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', vh+'px');
        });
    }
}
