












































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PageBase from "@/components/PageBase.vue";
import {Resource} from "@/data/model/resource";
import ImageResource from "@/components/ImageResource.vue";
import Navigation, {NavigationStyle} from "@/components/Navigation.vue";
import Columns from "@/components/Columns.vue";
import Column from "@/components/Column.vue";
import ContentPageResource from "@/components/ContentPageResource.vue";
import ContentPageGrid from "@/components/ContentPageGrid.vue";
import ContentPageElements from "@/components/ContentPageElements.vue";
import VideoResource from "@/components/VideoResource.vue";
import {ContentPageElement} from "@/data/model/contentpageelement";
import {ContentPage} from "@/data/model/contentpage";
import {Page} from "@/data/model/page";
import {Technology} from "@/data/model/technology";
import Button from "@/components/Button.vue";
import {Overview} from "@/data/model/overview";

@Component({
    components: {
        Button,
        VideoResource,
        ContentPageElements,
        ContentPageGrid,
        ContentPageResource,
        Column,
        Columns,
        Navigation,
        ImageResource,
        PageBase
    }
})
export default class ContentPageBase extends Vue {
    @Prop() public title!: string;
    @Prop() public subtitle!: string;
    @Prop() public contentPage!: ContentPage;
    @Prop() public preview!: boolean;
    @Prop({default: false}) private csaLogo!: boolean;

    get navigationStyle(): NavigationStyle {
        return this.contentPageMainImage?.backgroundColor ? NavigationStyle.Transparent : NavigationStyle.Default;
    }

    get contentPageStyle(): string {
        let backgroundColor = this.contentPageMainImage?.backgroundColor;
        if (backgroundColor) {
            return "background-color: " + backgroundColor;
        }

        return "";
    }

    get fullscreen(): boolean {
        return this.contentPage?.type == 'fullscreen';
    }

    get products(): boolean {
        return this.contentPage?.type == 'products';
    }

    get contentPageType(): string {
        return this.contentPage?.type ?? "default";
    }

    get contentPageTypeIsVideoOrImage(): boolean {
        let type = this.contentPageType;
        return type == 'image' || type == 'video';
    }

    get contentPageMainImage(): Resource | null {
        return this.contentPage?.mainImage?.imageExists(1024, 1024) ? this.contentPage?.mainImage : null;
    }

    get contentPageMainVideo(): Resource | null {
        return this.contentPage?.mainVideo?.videoExists() ? this.contentPage?.mainVideo : null;
    }

    get contentPageElements(): ContentPageElement[] {
        return this.contentPage?.sortedContentPageElements ?? [];
    }

    filterContentPageElements(elements: ContentPageElement[], location: string): ContentPageElement[] {
        return elements.filter(element => element.align == location || (location == "left" && element.align == null));
    }

    get contentPageSubpages(): Page[] {
        return this.contentPage?.subpages ?? [];
    }

    get contentPageTechnologies(): Technology[] {
        return this.contentPage?.technologies ?? [];
    }

    get contentPageOverviews(): Overview[] {
        return this.contentPage?.overviews ?? [];
    }

    openSubpage(page: Page) {
        let subpages: Page[] = page.contentPage?.subpages ?? []
        if (subpages.length > 0) {
            let subpage = subpages[0];
            this.openPage(subpage);
        }
    }

    openTechnology(technology: Technology) {
        this.$router.push({
            name: 'machine-technology-item', params: {
                technologyId: technology.id,
                overrideTitle: this.subtitle ?? ""
            }
        })
    }

    openPage(page: Page) {
        this.$router.push({
            name: 'machine-page-item', params: {
                pageId: page.id,
                overrideTitle: this.subtitle ?? ""
            }
        })
    }

    openOverview(overview: Overview) {
        this.$router.push({
            name: 'machine-overview-item', params: {
                overviewId: overview.id,
                overrideTitle: this.subtitle ?? ""
            }
        })
    }

    footerSize() {
        let footer = document.getElementsByClassName("footer")[0] as HTMLElement;
        let infographic = document.getElementsByClassName("infographic")[0] as HTMLElement;
        let content = document.getElementById("content");

        if (footer && infographic && content) {
            let infographicStyle = window.getComputedStyle(infographic);

            let marginTopString = infographicStyle.marginTop;
            let footerOuterHeight = footer.offsetHeight;

            if (marginTopString) {
                let marginTop = parseFloat(marginTopString.replace("px", ""));

                let contentStyle = window.getComputedStyle(content);
                let flexFlow = contentStyle.flexFlow;

                let singleColumn = document.getElementById("content-page-column-single") as HTMLElement;
                let firstColumn = document.getElementById("content-page-column-first") as HTMLElement;
                let secondColumn = document.getElementById("content-page-column-second") as HTMLElement;

                singleColumn.style.paddingBottom = footerOuterHeight + "px";

                if (!flexFlow.includes("column")) {
                    firstColumn.style.paddingBottom = "";
                    secondColumn.style.paddingBottom = footerOuterHeight + "px";

                    infographic.style.maxHeight = (window.innerHeight - (marginTop + 73)) + "px";
                } else {
                    firstColumn.style.paddingBottom = footerOuterHeight + "px";
                    secondColumn.style.paddingBottom = footerOuterHeight + "px";

                    infographic.style.maxHeight = (window.innerHeight - (marginTop + 73 + footerOuterHeight)) + "px";
                }
            }
        }
    }

    mounted() {
        let that = this;
        that.footerSize();

        window.addEventListener('resize', function () {
            that?.footerSize();
        });
    }
}
