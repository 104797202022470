// Require this file when working with Firebase somewhere
import * as firebase from "firebase/app";
import 'firebase/analytics';
import 'firebase/performance';

import {Vue} from 'vue-property-decorator';
import Analytics = firebase.analytics.Analytics;

const firebaseConfig = {
    apiKey: "AIzaSyC8P9-B47hVTk_-lb2kDjWmFkfUrI3goOs",
    authDomain: "canon-production-printing-idt.firebaseapp.com",
    databaseURL: "https://canon-production-printing-idt.firebaseio.com",
    projectId: "canon-production-printing-idt",
    storageBucket: "canon-production-printing-idt.appspot.com",
    messagingSenderId: "458471430182",
    appId: "1:458471430182:web:3827408df7e0a03404eee7",
    measurementId: "G-TVDNFQYZEL"
};
firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const perf = firebase.performance();

Vue.prototype.$analytics = analytics;
Vue.prototype.$perf = perf;

declare module 'vue/types/vue' {

    interface Vue {
        $analytics: Analytics
        $perf: Performance
    }
}