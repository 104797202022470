import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Page} from "@/data/model/page";
import {Resource} from "@/data/model/resource";

export class Tooltip extends DatabaseModel {
    id: string;
    type?: string;
    showAsVideo?: boolean;
    x?: number;
    y?: number;
    title?: string;
    body?: string;
    @Collectable()
    page?: Page;
    @Collectable()
    video?: Resource;

    constructor(data: {
        id: string,
        type?: string,
        showAsVideo?: boolean,
        x?: number,
        y?: number,
        title?: string,
        body?: string,
        page?: Page,
        video?: Resource,
    }) {
        super();
        this.id = data.id;
        this.type = data.type;
        this.showAsVideo = data.showAsVideo;
        this.x = data.x;
        this.y = data.y;
        this.title = data.title;
        this.body = data.body;
        this.page = data.page;
        this.video = data.video;
    }
}