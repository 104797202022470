import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {App} from "@/data/model/app";

export class AppCategory extends DatabaseModel {
    id: string;
    title?: string;
    position?: number;
    @Collectable()
    apps: App[];

    constructor(data: {
        id: string,
        title?: string,
        position?: number,
        apps: App[],
    }) {
        super();
        this.id = data.id;
        this.title = data.title;
        this.position = data.position;
        this.apps = data.apps;
    }
}