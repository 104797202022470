/* eslint-disable no-console */
import {remote} from 'electron'
import * as fs from "fs";
import {OfflineStorageListener} from "@/manager/OfflineStorageListener";
import {OfflineStorageInterface, ProgressUpdateType, RunningRequest} from "@/manager/OfflineStorageInterface";
import * as _ from "lodash";
import globalStore from "@/globalstore";

const request = require('request');
const TRANSLATION_TABLE_NAME = "offlineStorageTranslationTable";
const uuidv4 = require('uuid/v4');
const TEMP_SUFFIX = "_temp";

/**
 * A class that manages downloads using a 'TranslationTable' where you can queue URL's which wil be registered to the TranslationTable.
 * The TranslationTable assigns a unique filename (this implementation uses uuid's) after which the download is started.
 *
 * Listeners can be applied to register changes in progress
 */
export class OfflineStorageInterfaceImplementation implements OfflineStorageInterface {
    private runningRequests: RunningRequest[] = [];
    private failedRequests = new Map<string, number>();
    private queuedRequests: string[] = []; // Should stay a string so we can eventually use priority when we want to
    private finishedRequests = new Set<string>();

    // key: url, value: fileName
    private urlTable: Map<string, string> = new Map();

    private downloadFolder: string;
    private _isDownloading: boolean = false;
    private _failed: boolean = false;

    private listeners = new Set<OfflineStorageListener>();

    constructor(folder: string = "offlinedata", doInitialCleanup: boolean = true) {
        const app = remote.app;
        this.downloadFolder = app.getPath('userData') + "/" + folder;
        fs.mkdirSync(this.downloadFolder, {recursive: true});
        console.debug(`Initialising OfflineStorageInterface at ${this.downloadFolder}`);

        this.doStorageUpgrades();
        this.loadUrlTable();
        if (doInitialCleanup)
            this.cleanupNonTranslatedFiles();
        // Some URL's in the table might not have downloaded yet, thats why we load them into the queue at start
        this.setupInitialDownloadQueue();
        this.triggerNextRequest();
        // console.log("urlTable", this.urlTable, JSON.stringify(Object.fromEntries(this.urlTable)));
    }

    private doStorageUpgrades() {
        // Not needed yet
    }

    get hasFailed(): boolean {
        return this._failed;
    }

    get isDownloading(): boolean {
        return this._isDownloading;
    }

    get totalDownloadCount(): number {
        return this.urlTable.size;
    }

    get totalDownloadedCount(): number {
        let count = 0;
        for (let url of Array.from(this.urlTable.keys())) {
            if (this.has(url)) {
                count++;
                this.download(url);
            }
        }
        return count;
    }

    getCurrentRequestProgressions(): number[] {
        return this.runningRequests.map(request => request.progress)
    }

    addListener(listener: OfflineStorageListener) {
        this.listeners.add(listener);
        listener.onProgressUpdate(ProgressUpdateType.MAJOR);
    }

    removeListener(listener: OfflineStorageListener) {
        this.listeners.delete(listener);
    }

    private setupInitialDownloadQueue() {
        for (let url of Array.from(this.urlTable.keys())) {
            if (!this.has(url)) {
                this.download(url);
            } else {
                this.finishedRequests.add(url);
            }
        }
    }

    private removeTranslationTableEntry(url: string | null, file: string | null) {
        if (url == null && file == null) return;
        else if (url != null) {
            if (this.urlTable.delete(url))
                this.saveUrlTable();
        } else {
            const originalSize = this.urlTable.size;
            this.urlTable.forEach((value: string, key: string) => {
                if ((file == null || value == file) && (key == null || key == url)) {
                    this.urlTable.delete(key);
                }
            });
            if (this.urlTable.size != originalSize)
                this.saveUrlTable();
        }
    }

    private clearTranslationTable() {
        this.urlTable.clear();
        this.saveUrlTable();
    }

    private addUrlTranslationTableEntry(url: string, file: string) {
        this.urlTable.set(url, file);
        // console.debug(`Added translation entry ${url} > ${file}`);
        this.saveUrlTable();
    }

    private saveUrlTable() {
        window.localStorage.setItem(TRANSLATION_TABLE_NAME, JSON.stringify(Object.fromEntries(this.urlTable)));
        // console.log(`Saved url table with ${this.urlTable.size} entries`);
    }

    private loadUrlTable() {
        const raw = window.localStorage.getItem(TRANSLATION_TABLE_NAME);
        if (raw == null) return;
        const json = JSON.parse(raw);
        if (json == null) return;
        this.urlTable = new Map(Object.entries(json));
        //console.debug(`Loaded url table with ${this.urlTable.size} entries: ${Array.from(this.urlTable.values())}`);
    }

    private isQueued(url: string): boolean {
        return this.queuedRequests.includes(url);
    }

    private setDownloading() {
        if (this._isDownloading) return;
        this._isDownloading = true;
        console.log("Start sync");
        this.triggerUpdate(ProgressUpdateType.MAJOR);
    }

    private setDoneDownloading() {
        if (!this._isDownloading) return;
        this._isDownloading = false;
        console.log("Sync completed");
        this.triggerUpdate(ProgressUpdateType.MAJOR);
        // TODO: Consider if we should cleanup non-translated files, aka run
        // this.cleanupNonTranslatedFiles()
    }

    private setFailed() {
        if (!this._isDownloading) return;
        // this._isDownloading = false;
        this._failed = true;
        console.log("Sync failed");
        this.triggerUpdate(ProgressUpdateType.MAJOR);
        // TODO: Consider if we should cleanup non-translated files, aka run
        // this.cleanupNonTranslatedFiles()
    }

    private triggerNextRequest(): boolean {
        // console.log("triggerNextRequest", this.runningRequests)
        if (this.runningRequests.length != 0) {
            // console.log(`Cancelling next request trigger, already doing download work (${this.runningRequests.length})`, this.runningRequests);
            // this.setDoneDownloading();
            return false
        }

        const url = this.queuedRequests.pop();
        if (url == null) {
            if (this.failedRequests.size != 0) {
                this.failedRequests.forEach((value: number, key: string) => {
                    if (value < 3) {
                        // console.log(`Repushing to queue ${key} for retries ${value}`)
                        this.queuedRequests.push(key);
                    }
                })
                // this.queuedRequests = Array.from(this.failedRequests.keys());
                // setTimeout(() => {
                if (this.queuedRequests.length > 0) {
                    // this.failedRequests.clear();
                    // console.log("Retrying failed requests", this.queuedRequests, this.runningRequests);
                    this.triggerNextRequest();
                    return true
                }
                this.setFailed();
                // }, 0)
                return false;
            } else {
                this.setDoneDownloading();
                return false
            }
        }

        // console.log("Continuing...");

        if (this.has(url)) {
            // console.log(`Already has ${url}, moving to next`)
            this.triggerNextRequest();
            return true;
        }

        let savePath = this.localPathForUrl(url, true)!!;
        let savePathDownload = savePath + TEMP_SUFFIX;
        if (fs.existsSync(savePath)) {
            this.triggerNextRequest();
            // console.debug("URL already saved locally");
            this.setDoneDownloading();
            return false;
        }

        this.setDownloading();
        // console.debug("Triggering next download request", url, savePath, savePathDownload);

        var receivedBytes = 0;
        var totalBytes = 0;

        // Test image: https://upload.wikimedia.org/wikipedia/commons/d/d9/Test.png
        let realRequest = request.get({
            url: url,
            followAllRedirects: true,
            strictSSL: false
        }, (error: any, response: any, body: any) => {
            // console.log(`Callback for url ${url}`)
            // console.debug(`Renaming ${savePathDownload} to ${savePath}`, error);
            try {
                if (!error) {
                    fs.renameSync(savePathDownload, savePath);
                }
                this.finishedRequest(url, error, response, body);
            } catch (e) {
                this.finishedRequest(url, e ?? error, response, body);
            }
        });

        let stream = fs.createWriteStream(savePathDownload);
        stream.on('error', function (error: any) {
            globalStore.tracker.exception(error)
            console.error(error);
            try {
                fs.unlinkSync(savePathDownload);
                console.log(`Deleting ${savePathDownload} because of an error`)
            } catch (e) {
                globalStore.tracker.exception(e)
                console.error(e)
            }
        });

        let runningRequest = new RunningRequest(url, () => {
            realRequest.abort();
            stream.close();
        }, this.filenameForUrl(url)!! + TEMP_SUFFIX);
        // console.log("Adding running request", runningRequest)
        this.runningRequests.push(runningRequest);
        realRequest
            .on('error', (error: any) => {
                realRequest.abort();

                try {
                    stream.end(function () {
                        fs.unlinkSync(savePathDownload);
                    });
                } catch (e) {
                    console.log(e.message)
                }
            })
            .on('response', (data: any) => {
                totalBytes = parseInt(data.headers['content-length']);
            })
            .on('data', (chunk: any) => {
                receivedBytes += chunk.length;
                // var percentage = ((receivedBytes * 100) / totalBytes).toFixed(2);
                runningRequest.progress = receivedBytes / totalBytes;
                this.triggerUpdate(ProgressUpdateType.MINOR);

            })
            .on('abort', () => {
                // console.log("Aborted")
                try {
                    fs.unlinkSync(savePathDownload);
                    console.debug(`Deleting ${savePathDownload} because of abortion`)
                } catch (e) {
                    globalStore.tracker.exception(e)
                    console.error(e)
                }
            })
            .pipe(stream);
        return true
    }

    private triggerCall: () => void = () => {
    };
    private triggerThrottle = _.throttle(() => {
            this.triggerCall();
        }, 200, {leading: true, trailing: true}
    );

    private triggerUpdate(type: ProgressUpdateType) {
        this.triggerCall = () => {
            this.listeners.forEach(listener => {
                listener.onProgressUpdate(type)
            })
        };
        this.triggerThrottle();
    }

    private addFailedRequest(url: string) {
        const retries = (this.failedRequests.get(url) ?? 0) + 1
        this.failedRequests.set(url, retries);
        // console.log("Failed requests", this.failedRequests)
    }

    // eslint-disable-next-line no-unused-vars
    private finishedRequest(url: string, error: any, response: Response, body: any) {
        // let savePath = this.localPathForUrl(url);
        let filename = this.filenameForUrl(url)!!;
        // console.debug(`Request finished, saved to ${filename}`, error, url, this.queuedRequests, this.failedRequests, this.runningRequests);
        if (error) {
            globalStore.tracker.exception(error)
            console.error(error, url);
            this.addFailedRequest(url);
        } else {
            this.finishedRequests.add(url);
            this.addUrlTranslationTableEntry(url, filename);
        }
        this.removeRunningRequestByUrl(url);
        this.triggerUpdate(ProgressUpdateType.MAJOR);
        this.triggerNextRequest();
    }

    private removeRunningRequestByUrl(url: string) {
        this.runningRequests = this.runningRequests.filter(request => request.url != url);
        // console.log(`Removed url ${url}, still running: `, this.runningRequests)
    }

    private removeRunningRequest(runningRequest: RunningRequest) {
        const itemIndex = this.runningRequests.indexOf(runningRequest);
        if (itemIndex >= 0)
            this.runningRequests = this.runningRequests.splice(itemIndex, 1);
    }

    cancel() {
        for (let runningRequest of this.runningRequests) {
            runningRequest.cancel()
        }
        this.runningRequests = [];
        this.queuedRequests = [];
        this.finishedRequests.clear();
        this.failedRequests.clear();
        this.clearTranslationTable();
    }

    cancelAndCleanup() {
       this.cancel();
        // TODO: Consider wether we should cleanup files when disabling downloading. Maybe ask the user if it wants to keep or remove the files?
        //  And maybe do this every time the app starts while downloading is disabled but offline content still exists
        this.cleanupNonTranslatedFiles();
    }

    /**
     * @return the filename of under what name the file should be saved if registered, for example '1341341'
     */
    filenameForUrl(url: string, register: boolean = false): string | null {
        const result = this.urlTable.get(url) as string | null;
        if (!result && register) {
            const fileName = uuidv4();
            this.addUrlTranslationTableEntry(url, fileName);
            return this.localPathForUrl(url);
        }
        return result;
    }

    /**
     * @return the full path of where the url should be saved if registered, for example '/user/data/offlinedata/1341341'
     */
    localPathForUrl(url: string, register: boolean = false): string | null {
        const result = this.filenameForUrl(url, register);
        if (result != null)
            return this.downloadFolder + "/" + result;
        return null;
    }

    /**
     * @param url
     * @return true if the file has been downloaded already
     */
    has(url: string): boolean {
        const fileName = this.localPathForUrl(url);
        return fileName != null && fs.existsSync(fileName);
    }

    private cleanupNonTranslatedFiles() {
        console.debug("Cleaning files");
        const files = fs.readdirSync(this.downloadFolder);
        for (let file of files) {
            let keep = Array.from(this.urlTable.values()).includes(file) || this.runningRequests.filter(item => item.tempFileName == file).length > 0;
            if (!keep) {
                console.log(`Deleting file ${file} (it is not in the translation table)`);
                this.removeTranslationTableEntry(null, file);
                try {
                    fs.unlinkSync(this.downloadFolder + "/" + file);
                    // this.removeUrlTranslationTableEntry(url, null);
                } catch (e) {
                    globalStore.tracker.exception(e)
                    console.error(e);
                    // ignore
                }
            }
        }
        console.debug("Done cleaning files")
    }

    /**
     * Retains only the URL's given as parameter for this offline storage interface. All files that do not match these urls will be deleted
     */
    retainOnly(urls: string[], cleanupOldFiles: boolean = true) {
        const urlsUnique = new Set<string>(urls);
        for (let url of Array.from(this.urlTable.keys())) {
            let keep = urlsUnique.has(url);
            if (!keep) {
                console.log(`Deleting url translation for ${url} (it has been request to not be retained)`);
                this.urlTable.delete(url);
            }
        }

        this.queuedRequests = this.queuedRequests.filter(url => urlsUnique.has(url));

        for (let runningRequest of this.runningRequests) {
            let keep = urlsUnique.has(runningRequest.url);
            if (!keep) {
                runningRequest.cancel();
            }
        }
        this.saveUrlTable();
        if (cleanupOldFiles)
            this.cleanupNonTranslatedFiles();
        this.triggerUpdate(ProgressUpdateType.MAJOR);
    }

    delete(url: string | string[]) {
        if (Array.isArray(url)) {
            url.forEach((url) => this.delete(url));
            return;
        }
        const file = this.localPathForUrl(url);
        if (file != null) {
            try {
                fs.unlinkSync(file);
                // this.removeUrlTranslationTableEntry(url, null);
            } catch (e) {
                globalStore.tracker.exception(e)
                console.error(e);
                // ignore
            }
        }
        this.removeTranslationTableEntry(url, null);
        this.triggerUpdate(ProgressUpdateType.MAJOR);
    }

    download(url: string | string[]) {
        if (Array.isArray(url)) {
            url.forEach((url) => this.download(url));
            return;
        }
        // console.log(`Queueing? ${url}`);
        if (!this.isQueued(url)) {
            if (this.has(url)) {
                this.finishedRequests.add(url);
                return;
            }
            this.localPathForUrl(url, true);
            this.queuedRequests.push(url);
            // console.log(`Queued ${url}`);
            this.triggerUpdate(ProgressUpdateType.MAJOR);
            this.triggerNextRequest();
        }
    }

    prioritize(url: string | string[]) {
        if (Array.isArray(url)) {
            url.forEach((url) => this.prioritize(url));
            return;
        }
        if (!this.queuedRequests.includes(url)) return;
        this.queuedRequests = this.queuedRequests.filter(queuedUrl => queuedUrl != url);
        this.queuedRequests.unshift(url);
        console.log(`Prioritized ${url}`)
    }
}