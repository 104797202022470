












import {Component, Prop, Vue} from 'vue-property-decorator';
import {Page} from "@/data/model/page";
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";

@Component({
    components: {
        ImageResource
    }
})
export default class ContentPageGrid extends Vue {
    @Prop() public pages!: Page[];

    openPage(page: Page) {
        this.$router.push({
            name: 'machine-page-item', params: {
                pageId: page.id
            }
        })
    }
}
