


























import {Component, Prop, Vue} from 'vue-property-decorator';
import PageBase from "@/components/PageBase.vue";
import {Machine} from "@/data/model/machine";
import {Benefit} from "@/data/model/benefit";
import {BenefitCategory} from "@/data/model/benefitCategory";
import {App} from "@/data/model/app";
import Button from "@/components/Button.vue";

@Component({
    components: {
        Button,
        PageBase
    }
})

export default class BenefitsList extends Vue {
    @Prop() public app!: App;
    @Prop() public machine!: Machine;
    @Prop() public benefit!: Benefit;
    isDropped = new Set();

    dropIt(category: BenefitCategory) {
        if(this.isDropped.has(category.id)){
            this.isDropped.delete(category.id);
        }else{
            this.isDropped.clear();
            this.isDropped.add(category.id);
        }
        this.$forceUpdate();
    }

    get categories(): BenefitCategory[] {
        let app = this.app;
        return app?.sortedBenefitCategories ?? []
    }

    getBenefitsForCategory(category: BenefitCategory | null): Benefit[] {
        let machine = this.machine;
        return machine?.getBenefitsForCategory(category) ?? [];
    }

    handleClick(benefit: Benefit) {
        if (this.benefit && benefit.id == this.benefit.id) {
            this.$emit("selectBenefit", null);
        } else {
            this.$emit("selectBenefit", benefit);
        }
    }
}
