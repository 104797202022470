

















import {Component, Prop, Vue} from 'vue-property-decorator';
import BackButton from "@/components/BackButton.vue";
import CanonLogo from "@/components/CanonLogo.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";
import DownloadMode from "@/components/DownloadMode.vue";

export enum NavigationStyle {
    Default,
    Dark,
    Transparent
}

@Component({
    components: {
        DownloadMode,
        LanguageSelect,
        BackButton,
        CanonLogo
    }
})
export default class Navigation extends Vue {
    @Prop() public title!: String;
    @Prop() public subtitle!: String;
    @Prop() public preview!: boolean;
    @Prop({default: NavigationStyle.Default}) public navigationStyle!: NavigationStyle;
    @Prop({default: false}) private csaLogo!: boolean;

    get dark(): boolean {
        return this.navigationStyle === NavigationStyle.Dark;
    }
    get transparent(): boolean {
        return this.navigationStyle === NavigationStyle.Transparent;
    }
}
