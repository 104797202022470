




import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ContentPageBase from "@/components/ContentPageBase.vue";
import {ContentPage} from "@/data/model/contentpage";
import CaseStudyVue from "@/views/base/CaseStudyVue.vue";

@Component({
    components: {
        ContentPageBase,
        LoadingIndicator
    }
})
export default class CaseStudyDetail extends CaseStudyVue {
    get title() {
        let machine = this.machineInstance;
        return machine?.caseStudiesButtonTitle ?? null;
    }

    get subtitle() {
        return this.caseStudyInstance?.title
    }

    get contentPageId() {
        return this.contentPage?.id;
    }

    get contentPage(): ContentPage | null {
        let caseStudy = this.caseStudyInstance;
        return caseStudy?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
