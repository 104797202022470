import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ContentPage} from "@/data/model/contentpage";
import {Resource} from "@/data/model/resource";

export class Application extends DatabaseModel {
    id: string;
    position?: number;
    title?: string;
    @Collectable()
    thumbnail?: Resource;
    @Collectable()
    contentPage?: ContentPage;

    constructor(data: {
        id: string,
        position?: number,
        title?: string,
        thumbnail?: Resource,
        contentPage?: ContentPage,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.title = data.title;
        this.thumbnail = data.thumbnail;
        this.contentPage = data.contentPage;
    }
}