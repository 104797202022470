/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ElectronApi from "@/electron/ElectronApi";

export default class ElectronApiPlaceholder implements ElectronApi {
    isElectron(): boolean {
        return false;
    }

    setProgress(progress: number, mode: string): boolean {
        return false;
    }

    setBadge(value: string): void {
    }

    get platform(): string {
        return 'web'
    }

    on(channel: string, listener: (event: any, ...args: any[]) => void): this {
        return this;
    }

    removeListener(channel: string, listener: (...args: any[]) => void): this {
        return this;
    }

    send(channel: string, ...args: any[]): void {
    }

    get version(): string | null {

        return null;
    }
}