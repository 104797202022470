import {DatabaseModel} from "@/data/model/database-model";

export class BenefitCategory extends DatabaseModel {
    id: string;
    title?: string;
    position?: number;

    constructor(data: {
        id: string,
        title?: string,
        position?: number
    }) {
        super();
        this.id = data.id;
        this.title = data.title;
        this.position = data.position;
    }
}