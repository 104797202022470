import en from './en.json';
import de from './de.json';
import nl from './nl.json';
import fr from './fr.json';
import es from './es.json';
import it from './it.json';
import pl from './pl.json';
import no from './no.json';

export const fallbackLocale = 'en';

export const translationlang = {
    en: en,
    de: de,
    nl: nl,
    fr: fr,
    es: es,
    it: it,
    pl: pl,
    no: no
};


