
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Overview} from "@/data/model/overview";
import {Tooltip} from "@/data/model/tooltip";

@Component({})
export default class OverviewVue extends MachineVue {
    get overviewInstance(): Overview | null {
        return this.appOverviews.find((item: Overview) => item.id == this.routerParams.overviewId) ?? null
    }

    get overviewTooltips(): Tooltip[] {
        return this.overviewInstance?.tooltips ?? [];
    }
}
