import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ContentPage} from "@/data/model/contentpage";
import {Resource} from "@/data/model/resource";

export class Technology extends DatabaseModel {
    id: string;
    position?: number;
    prominent?: boolean;
    title?: string;
    @Collectable()
    logo?: Resource;
    @Collectable()
    contentPage?: ContentPage;

    constructor(data: {
        id: string,
        position?: number,
        prominent?: boolean,
        title?: string,
        logo?: Resource,
        contentPage?: ContentPage,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.prominent = data.prominent;
        this.title = data.title;
        this.logo = data.logo;
        this.contentPage = data.contentPage;
    }

    get buttonTitle(): string | null {
        return this.contentPage?.referenceTitle ?? this.contentPage?.title ?? this.title ?? null;
    }
}