/* eslint-disable no-console */
import Dexie from 'dexie';
import {DatabaseObserver} from "@/data/database-observer";
import {AppCategory} from "@/data/model/appcategory";
import {DbConverter} from "@/data/db-converter";
import {wrapLiveCall} from "@/data/repository-utils";

export class CategoryRepository extends Dexie {
    private repoTable: Dexie.Table<Object, string>;

    constructor() {
        super("categories");
        this.version(1).stores({
            categories: "id"
        });
        this.repoTable = this.table("categories");
    }

    public async putAllCategories(categories: Array<AppCategory>, doCleanup: boolean = false) {
        let ids = categories.map(category => category.id);
        // console.log("Putting all categories", categories);
        await this.transaction('rw', this.repoTable, async () => {
            for (let category of categories) {
                category.cleanUndefineds();

                let exists = await this.repoTable
                    .where('id').equals(category.id)
                    .first() != null;

                // console.log("Current database value", await this.table.where('id').equals(category.id).first())
                if (exists) {
                    // console.log("Updating", category);
                    const updates = await this.repoTable.update(category.id, category);
                    // console.log("Update result", updates)
                } else {
                    // console.log("Putting", category);
                    const put = await this.repoTable.put(category);
                    // console.log("Put result", put)
                }
                // console.log("New database value", await this.table.where('id').equals(category.id).first())
            }
        });
        // console.log("Put all completed");
        if (doCleanup)
            await this.repoTable
                .where('id').noneOf(ids)
                .delete();
        // console.log("clean");
    }

    public listenForCategoryChanges(callback: () => void): DatabaseObserver {
        // console.log("listenForCategoryChanges");
        let queryCall = async () => {
            callback();
        };
        return wrapLiveCall(this.repoTable, queryCall);
    }

    public getAllCategoriesLive(callback: (data: Array<AppCategory>) => void, forceFetch: boolean = false): DatabaseObserver {
        // console.log("getAllCategoriesLive");
        let queryCall = async () => {
            const items = await this.repoTable.toArray();
            callback(items.map(item => DbConverter.toAppCategory(item)!!));
        };
        return wrapLiveCall(this.repoTable, queryCall);
    }

    public async getCategory(id: string): Promise<AppCategory | undefined> {
        // console.log("getCategory");
        return DbConverter.toAppCategory(await this.repoTable
            .where('id').equals(id)
            .first());
    }

    public getCategoryLive(id: string, callback: (data: AppCategory | undefined) => void, forceFetch: boolean = false): DatabaseObserver {
        //console.log("getCategoryLive");
        //console.log(`Finding ${slugOrId}`);
        let queryCall = async () => {
            //console.log("Reexecuting query", slugOrId, await this.repoTable.toArray());
            let data = await this.repoTable
                .where('id').equals(id)
                .first();

            if (data != null) {
                callback(DbConverter.toAppCategory(data)!!);
            } else {
                console.log("No category yet")
            }
        };

        return wrapLiveCall(this.repoTable, queryCall);
    }
}