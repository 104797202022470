import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {ContentPage} from "@/data/model/contentpage";
import {ModuleGroup} from "@/data/model/modulegroup";
import {Resource} from "@/data/model/resource";

export class Module extends DatabaseModel {
    id: string;
    position?: number;
    arrangingDepth?: number;
    insetRight?: number;
    width?: number;
    insetLeft?: number;
    groupMaximum?: number;
    maximum?: number;
    placement?: number;
    height?: number;
    depth?: number;
    title?: string;
    @Collectable()
    contentPage?: ContentPage;
    @Collectable()
    moduleGroup?: ModuleGroup;
    @Collectable()
    topOpenedView?: Resource;
    @Collectable()
    topView?: Resource;
    @Collectable()
    frontView?: Resource;

    constructor(data: {
        id: string,
        position?: number,
        arrangingDepth?: number,
        insetRight?: number,
        width?: number,
        insetLeft?: number,
        groupMaximum?: number,
        maximum?: number,
        placement?: number,
        height?: number,
        depth?: number,
        title?: string,
        contentPage?: ContentPage,
        moduleGroup?: ModuleGroup,
        topOpenedView?: Resource,
        topView?: Resource,
        frontView?: Resource,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.arrangingDepth = data.arrangingDepth;
        this.insetRight = data.insetRight;
        this.width = data.width;
        this.insetLeft = data.insetLeft;
        this.groupMaximum = data.groupMaximum;
        this.maximum = data.maximum;
        this.placement = data.placement;
        this.height = data.height;
        this.depth = data.depth;
        this.title = data.title;
        this.contentPage = data.contentPage;
        this.moduleGroup = data.moduleGroup;
        this.topOpenedView = data.topOpenedView;
        this.topView = data.topView;
        this.frontView = data.frontView;
    }
}