import {DatabaseModel} from "@/data/model/database-model";

export class Contact extends DatabaseModel {
    id: string;
    position?: number;
    title?: string;
    url?: string;
    enabled?: boolean;

    constructor(data: {
        id: string,
        position?: number,
        title?: string,
        url?: string,
        enabled?: boolean,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.title = data.title;
        this.url = data.url;
        this.enabled = data.enabled;
    }
}