































































import {Component, Watch} from 'vue-property-decorator';
import CanonLogo from "@/components/CanonLogo.vue";
import BackButton from "@/components/BackButton.vue";
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ConfiguratorVue from "@/views/base/ConfiguratorVue.vue";
import PageBase from "@/components/PageBase.vue";
import ImageResource from "@/components/ImageResource.vue";
import Row from "@/components/Row.vue";
import Rows from "@/components/Rows.vue";
import ModuleItem from "@/components/ModuleItem.vue";
import OptionItem from "@/components/OptionItem.vue";
import {Module} from "@/data/model/module";
import {ConfigurationModule} from "@/data/model/configurationmodule";
import ConfigurationModuleItem from "@/components/ConfigurationModuleItem.vue";
import { v4 as uuid } from 'uuid';
import ConfigurationOptionItem from "@/components/ConfigurationOptionItem.vue";
import {Option} from "@/data/model/option";
import {Configurator} from "@/data/model/configurator";

@Component({
    components: {
        ConfigurationOptionItem,
        ConfigurationModuleItem,
        OptionItem,
        ModuleItem,
        ImageResource,
        PageBase,
        Row,
        Rows,
        Button,
        CanonLogo,
        BackButton,
        LoadingIndicator
    }
})
export default class ConfiguratorDetail extends ConfiguratorVue {
    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title ?? this.appInstance?.title;
    }

    get subtitle() {
        return this.configuratorInstance?.title
    }

    currentModules: null | ConfigurationModule[] = null
    currentOptions: Option[] = []
    draggingModule: null | ConfigurationModule = null;
    draggingOption: null | Option = null;
    removeModule: boolean = true;
    removeOption: boolean = true;

    currentView: string = "front";

    scrollHandler: any = null;

    scrollLeft() {
      this.scrollHandler = setInterval( function() {
        let content = document.getElementById("configurator-items-drawer");

        if (content) {
          let parent = content.parentElement
          if (parent) {
            let scrollLeft = parent.scrollLeft;

            parent.scrollLeft = scrollLeft - 6;

            let scrollLeftImage = document.getElementById("configurator-items-drawer-scroll-left-image");
            if (scrollLeftImage) {
              scrollLeftImage.style.display = parent.scrollLeft == 0 ? "none" : "inline-block";
            }
            let scrollRightImage = document.getElementById("configurator-items-drawer-scroll-right-image");
            if (scrollRightImage) {
              scrollRightImage.style.display = "inline-block";
            }
          }
        }
      }, 10);
    }

    scrollRight() {
      this.scrollHandler = setInterval( function() {
        let content = document.getElementById("configurator-items-drawer")?.parentElement;

        if (content) {
          let scrollLeft = content.scrollLeft;

          content.scrollLeft = scrollLeft + 6;

          let scrollLeftImage = document.getElementById("configurator-items-drawer-scroll-left-image");
          if (scrollLeftImage) {
            scrollLeftImage.style.display = content.scrollLeft == 0 ? "none" : "inline-block";
          }
          let scrollRightImage = document.getElementById("configurator-items-drawer-scroll-right-image");
          if (scrollRightImage) {
            scrollRightImage.style.display = content.scrollLeft == scrollLeft ? "none" : "inline-block";
          }
        }
      }, 10);
    }

    endScroll()  {
      clearInterval(this.scrollHandler);
    }

    get nextViewTitle(): String {
        if (this.currentView == "top") {
            return "Top view (opened)";
        } else if (this.currentView == "opened") {
            return "Front view";
        } else {
            return "Top view";
        }
    }

    nextView() {
        if (this.currentView == "top") {
            this.currentView = "opened";
        } else if (this.currentView == "opened") {
            this.currentView = "front";
        } else {
            this.currentView = "top";
        }
    }

    showRuler: boolean = false;

    get toggleRulerTitle(): String {
        if (this.showRuler) {
            return "Hide foorprint";
        } else {
            return "Show footprint";
        }
    }

    toggleRuler() {
        this.showRuler = !this.showRuler;

        this.saveConfig();
    }

    get width(): number {
        let width = 0;

        let that = this;
        this.selectedModules.forEach(function (item, index) {
            let module = that.getModule(item);
            if (module) {
                width += module.width ?? 0;
                if (index > 0) {
                    width -= module.insetLeft ?? 0;
                }
                if (index < that.selectedModules.length - 1) {
                    width -= module.insetRight ?? 0;
                }
            }
        });

        return width;
    }


    get rulerHeight(): string {
        let height = this.heightOrDepth;
        let configurator = this.configuratorInstance;
        let maxHeight = this.currentView == "front" ? (configurator?.productHeight ?? 0) : (configurator?.productDepth ?? 0);

        let rulerHeight: number;
        if (maxHeight != 0) {
            rulerHeight = height / maxHeight * 100;
        } else {
            rulerHeight = 100;
        }

        let result = "height: " + (rulerHeight * 0.4) + "vh;";

        if (this.currentView !== "front") {
            let insetTop = configurator?.productWallOffset ?? 0;
            let rulerInset: number;
            if (maxHeight != 0) {
                rulerInset = insetTop / maxHeight * 100;
            } else {
                rulerInset = 100;
            }
            result += " margin-top: calc(2px + " + (rulerInset * 0.4) + "vh);";
        } else {
            let insetBottom = configurator?.productFloorOffset ?? 0;
            let rulerInset: number;
            if (maxHeight != 0) {
                rulerInset = insetBottom / maxHeight * 100;
            } else {
                rulerInset = 100;
            }
            result += " margin-top: calc(2px + " + (40 - (rulerHeight + rulerInset) * 0.4) + "vh);";
        }

        return result;
    }

    get horizontalRulerLabel(): string {
        let centimeters = (this.width / 10);
        let inches = Math.round(centimeters * 3.93700787) / 10;

        return centimeters + " CM / " + inches + "″";
    }

    get configuratorHeight():number {
        let configurator = this.configuratorInstance;
        if (this.currentView == "front") {
            return configurator?.productHeight ?? this.heightOrDepth;
        } else {
            return configurator?.productDepth ?? this.heightOrDepth;
        }
    }

    get heightOrDepth(): number {
        let height = 0;

        let that = this;
        this.selectedModules.forEach(function(item, index) {
            let module = that.getModule(item);
            if (module) {
                if (that.currentView == "front") {
                    height = Math.max(height, module.height ?? 0);
                } else {
                    height = Math.max(height, module.depth ?? 0);
                }
            }
        });

        return height;
    }

    get verticalRulerLabel(): string {
        let height = this.heightOrDepth;

        let centimeters = (height / 10);
        let inches = Math.round(centimeters * 3.93700787) / 10;

        return centimeters + " CM / " + inches + "″";
    }

    get loadingModules(): Boolean {
        let loading = this.loadingApp;

        return loading || this.currentModules == null;
    }

    get selectedModules(): ConfigurationModule[] {
        let configurator = this.configuratorInstance;
        let configuration = configurator?.defaultConfiguration;

        if (configuration !== null && this.currentModules === null) {
            let modules: ConfigurationModule[] = configurator?.defaultConfiguration?.sortedModules ?? [];

            if (modules.length > 0) {
                this.currentModules = modules;
                this.defaultModules = modules.map(item => {
                    return item.moduleId;
                });
            }
        }
        if (this.currentModules !== null) {
            return this.currentModules;
        }

        return [];
    }

    optionEnabled(option: Option): boolean {
        return !this.currentOptions.some(o => { return o.id == option.id});
    }

    moduleEnabled(module: Module): boolean {
        if (module.maximum) {
            let maximum = module.maximum;
            let moduleId = module.id;
            let total = this.selectedModules.filter(m => { return m.moduleId == moduleId }).length;

            if (total >= maximum) {
                return false;
            }
        }

        if (module.groupMaximum && module.moduleGroup?.id) {
            let moduleGroupId = module.moduleGroup.id;
            let total = this.selectedModules.filter(m => { return this.getModule(m)?.moduleGroup?.id == moduleGroupId }).length;

            if (total >= module.groupMaximum) {
                return false;
            }
        }

        return true;
    }

    isDragging(module: ConfigurationModule): boolean {
        return this.draggingModule?.id == module.id;
    }

    isDraggingOption(option: Option): boolean {
        return this.draggingOption?.id == option.id;
    }

    startDraggingOption(draggingOption: Option, dragX: number, dragY: number, offsetX: string, offsetY: string) {
        this.movedOption = false;

        this.currentOptions.push(draggingOption);

        this.startDraggingConfigurationOption(draggingOption, dragX, dragY, offsetX, offsetY);
    }

    startDraggingModule(draggingModule: Module, dragX: number, dragY: number, offsetX: string, offsetY: string) {
        this.movedModule = false;

        let modulesContainer = document.getElementById("configurator-configuration-content");

        if (modulesContainer) {
            let closestPossiblePosition = this.selectedModules.length;
            let closestPossibleDistance = null;

            for (let i = 0; i < this.selectedModules.length + 1; i++) {
                if (draggingModule.placement != null) {
                    let valid = true;

                    for (let j = 0; j < i; j++) {
                        let module = this.getModule(this.selectedModules[j]);

                        if (module && module.placement != null && module.placement > draggingModule.placement) {
                            valid = false;
                            break;
                        }
                    }

                    if (!valid) {
                        continue;
                    }

                    for (let j = i; j < this.selectedModules.length; j++) {
                        let module = this.getModule(this.selectedModules[j]);

                        if (module && module.placement != null && module.placement < draggingModule.placement) {
                            valid = false;
                            break;
                        }

                    }

                    if (!valid) {
                        continue;
                    }
                }

                if (i == 0) {
                    let dom = modulesContainer.children[i] as HTMLElement;
                    if (dom) {
                        let distance = Math.abs(dragX - dom.offsetLeft);
                        if (closestPossibleDistance == null || distance < closestPossibleDistance) {
                            closestPossiblePosition = -1;
                            closestPossibleDistance = distance;
                        }
                    }
                } else {
                    let dom = modulesContainer.children[i-1] as HTMLElement;
                    if (dom) {
                        let distance = Math.abs(dragX - (dom.offsetLeft + dom.offsetWidth));
                        if (closestPossibleDistance == null || distance < closestPossibleDistance) {
                            closestPossiblePosition = i-0.5;
                            closestPossibleDistance = distance;
                        }
                    }
                }
            }

            let configurationModule = new ConfigurationModule({
                id: uuid(),
                moduleId: draggingModule.id,
                position: closestPossiblePosition,
            });

            let modules = this.selectedModules;
            modules.push(configurationModule);
            modules = modules.sort((a,b) => {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }

                return 0;
            });

            for (let i = 0; i < modules.length; i++) {
                modules[i].position = i;
            }

            this.currentModules = modules;
            this.startDraggingConfigurationModule(configurationModule, dragX, dragY, offsetX, offsetY);
        }
    }

    startDraggingConfigurationModule(module: ConfigurationModule, dragX: number, dragY: number, offsetX: string, offsetY: string) {
        this.draggingModule = module;

        this.dragModule(dragX, dragY, offsetX, offsetY);
    }

    startDraggingConfigurationOption(option: Option, dragX: number, dragY: number, offsetX: string, offsetY: string) {
        this.draggingOption = option;

        this.dragOption(dragX, dragY, offsetX, offsetY);
    }

    dragOption(dragX: number, dragY: number, offsetX: string | null, offsetY: string | null) {
        let optionDrag = document.getElementById("option-drag");
        if (optionDrag !== null) {
            optionDrag.style.left = dragX + "px";
            optionDrag.style.top = dragY + "px";

            if (offsetX != null && offsetY != null) {
                optionDrag.style.transform = "translate(-" + offsetX + ", -" + offsetY + ")";
            }
        }

        if (this.draggingOption) {
            let drawerContainer = document.getElementById("configurator-items-drawer");

            if (drawerContainer) {
                this.removeOption = drawerContainer.offsetTop < dragY;

            }
        }
    }

    dragModule(dragX: number, dragY: number, offsetX: string | null, offsetY: string | null) {
        let moduleDrag = document.getElementById("module-drag");
        if (moduleDrag !== null) {
            moduleDrag.style.left = dragX + "px";
            moduleDrag.style.top = dragY + "px";

            if (offsetX != null && offsetY != null) {
                moduleDrag.style.transform = "translate(-" + offsetX + ", -" + offsetY + ")";
            }
        }

        if (this.draggingModule) {
            let modulesContainer = document.getElementById("configurator-configuration-content");
            let drawerContainer = document.getElementById("configurator-items-drawer");

            if (modulesContainer && drawerContainer) {
                this.removeModule = drawerContainer.offsetTop < dragY;

                let draggingModule = this.getModule(this.draggingModule);

                if (draggingModule) {
                    let currentPosition = this.draggingModule.position;

                    let moduleDom = modulesContainer.children[currentPosition] as HTMLElement;

                    if (!moduleDom) {
                        return;
                    }

                    let closestDropDistance: number = Math.min(Math.abs(dragX - (moduleDom.offsetLeft + moduleDom.offsetWidth)), Math.abs(dragX - moduleDom.offsetLeft));
                    let closestDropPosition: number = currentPosition;

                    if (currentPosition > 0) {
                        let module = this.getModule(this.selectedModules[currentPosition - 1]);

                        if (module != null) {
                            let canPlaceLeft = draggingModule.placement == null || module.placement == null || draggingModule.placement <= module.placement;

                            if (canPlaceLeft) {
                                let moduleDom = modulesContainer.children[currentPosition - 1] as HTMLElement;
                                let distance = Math.abs(dragX - moduleDom.offsetLeft);

                                if (closestDropDistance > distance) {
                                    closestDropDistance = distance;
                                    closestDropPosition = currentPosition - 1;
                                }
                            }
                        }
                    }
                    if (currentPosition < this.selectedModules.length - 1) {
                        let module = this.getModule(this.selectedModules[currentPosition + 1]);

                        if (module != null) {
                            let canPlaceRight = draggingModule.placement == null || module.placement == null || draggingModule.placement >= module.placement;

                            if (canPlaceRight) {
                                let moduleDom = modulesContainer.children[currentPosition + 1] as HTMLElement;
                                let distance = Math.abs(dragX - (moduleDom.offsetLeft + moduleDom.offsetWidth));

                                if (closestDropDistance > distance) {
                                    closestDropDistance = distance;
                                    closestDropPosition = currentPosition + 1;
                                }
                            }
                        }
                    }

                    if (currentPosition != closestDropPosition) {
                        let modules = this.selectedModules;
                        closestDropPosition = Math.max(0, Math.min(closestDropPosition, modules.length - 1));
                        modules[closestDropPosition].position = currentPosition;
                        modules[currentPosition].position = closestDropPosition;
                        this.currentModules = modules.sort((a,b) => {
                            if (a.position > b.position) {
                                return 1;
                            }

                            if (a.position < b.position) {
                                return -1;
                            }

                            return 0;
                        });
                    }
                }
            }
        }
    }

    defaultModules: string[] = [];
    resetConfig() {
        this.currentModules = null;
        this.currentOptions = [];
        this.saveConfig();
        this.currentModules = this.selectedModules;
        this.saveConfig();

        let configurator = this.configuratorInstance;

        if (configurator) {
            document.cookie = "Configuration= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
        } else {
            console.log("Unknown configuration");
        }
    }

    saveConfig() {
        let configurator = this.configuratorInstance;

        if (configurator) {
            let moduleIds: String[] = [];
            this.selectedModules.forEach((item: ConfigurationModule) => {
                if (item) {
                    moduleIds.push(item.moduleId);
                }
            });

            let optionIds: String[] = [];
            this.currentOptions.forEach((item: Option) => {
                if (item) {
                    optionIds.push(item.id);
                }
            });

            let config = moduleIds.join(",") + "|" + optionIds.join(",") + "|" + (this.showRuler ? "1" : "0") + "|" + this.currentView;

            document.cookie = "Configuration" + configurator.id + "=" + config;
        }
    }

    stopDragging(module: ConfigurationModule) {
        if (!this.movedModule && this.draggingModule) {
            let module = this.getModule(this.draggingModule);
            
            if (module) {
                this.openModule(module);
            }
        }

        if (this.draggingModule && this.removeModule) {
            this.currentModules = this.selectedModules.filter(module => { return !module.id || module.id != this.draggingModule?.id });
        }

        this.draggingModule = null;

        this.saveConfig();
    }
    stopDraggingOption(option: Option) {
        if (!this.movedOption && this.draggingOption) {
            this.openOption(this.draggingOption);
        }

        if (this.draggingOption && this.removeOption) {
            this.currentOptions = this.currentOptions.filter(option => { return !option.id || option.id != this.draggingOption?.id });
        }

        this.draggingOption = null;

        this.saveConfig();
    }

    getModule(module: ConfigurationModule): Module | null {
        let result: Module | null = null;
        let moduleId = module?.moduleId;

        this.modules.forEach((item: Module) => {
            if (item.id == moduleId && result == null) {
                result = item;
            }
        });

        return result;
    }

    get modules() {
        let configurator = this.configuratorInstance;

        return configurator?.sortedModules ?? [];
    }

    get options() {
        let configurator = this.configuratorInstance;

        return configurator?.sortedOptions ?? [];
    }

    beforeMount() {
        this.refreshApp();
    }

    @Watch("configuratorInstance", {immediate: true, deep: true})
    onConfiguratorInstance(newValue: Configurator | null, oldValue: Configurator | null) {
        if (!oldValue && newValue) {

            let cname = "Configuration" + newValue.id
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            let config: string | null = null;
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    config = c.substring(name.length, c.length);
                    break;
                }
            }

            if (config) {
                let components = config.split("|");
                if (components.length == 4) {
                    let moduleIds: string[];
                    if (components[0] != "") {
                        moduleIds = components[0].split(",");
                    } else {
                        moduleIds = [];
                    }
                    let optionIds: string[];
                    if (components[1] != "") {
                        optionIds = components[1].split(",");
                    } else {
                        optionIds = [];
                    }
                    this.showRuler = components[2] == "1";
                    this.currentView = components[3];

                    if (moduleIds.length + optionIds.length > 0) {
                        let modules: ConfigurationModule[] = [];

                        moduleIds.forEach(function (item, index) {
                            let module = new ConfigurationModule({
                                id: uuid(),
                                moduleId: item,
                                position: index,
                            });
                            modules.push(module);
                        });
                        let options = newValue.options.filter(o => { return optionIds.includes(o.id); });

                        this.currentModules = modules;
                        this.currentOptions = options;
                    }
                }
            }
        }
    }

    mounted() {
        window.addEventListener("touchmove", this.drag, false);
        window.addEventListener("mousemove", this.drag, false);

        document.addEventListener('wheel', function (evt) {

            let drawerContainer = document.getElementById("configurator-items-drawer");
            let configurationContainer = document.getElementById("configurator-configuration");

            if (drawerContainer && configurationContainer) {
                if (drawerContainer.offsetTop < evt.clientY) {
                    let parent = drawerContainer.parentElement;

                    if (parent) {
                        let scrollLeft = parent.scrollLeft;

                        parent.scrollLeft = Math.max(0, scrollLeft + evt.deltaY);

                      let scrollLeftImage = document.getElementById("configurator-items-drawer-scroll-left-image");
                      if (scrollLeftImage) {
                        scrollLeftImage.style.display = parent.scrollLeft == 0 ? "none" : "inline-block";
                      }
                      let scrollRightImage = document.getElementById("configurator-items-drawer-scroll-right-image");
                      if (scrollRightImage) {
                        scrollRightImage.style.display = scrollLeft !== 0 && parent.scrollLeft == scrollLeft ? "none" : "inline-block";
                      }
                    }
                } else {
                    let parent = configurationContainer.parentElement;

                    if (parent) {
                        let scrollLeft = parent.scrollLeft;

                        parent.scrollLeft = Math.max(0, scrollLeft + evt.deltaY);
                    }
                }

            }
        });
    }

    movedModule = false;
    movedOption = false;

    drag(e: any) {
        if (this.draggingModule || this.draggingOption) {
            let dragX: null|number = null;
            let dragY: null|number = null;

            if (e.type === "touchmove") {
                dragX = e.touches[0].clientX;
                dragY = e.touches[0].clientY;
            } else {
                dragX = e.clientX;
                dragY = e.clientY;
            }

            if (dragX != null && dragY != null) {
                if (this.draggingModule) {
                    this.movedModule = true;
                    this.dragModule(dragX, dragY, null, null);
                } else {
                    this.movedOption = true;
                    this.dragOption(dragX, dragY, null, null);
                }
            }
        }
    }

}
