













import {Component, Vue} from 'vue-property-decorator';
import globalStore from "@/globalstore";

@Component({
    name: "download-debug-overview",
    components: {}
})
export default class DownloadDebugOverview extends Vue {
    click() {
        globalStore.downloadManager.toggle();
    }

    get totalDownloaded(): number {
        return globalStore.downloadManager.totalDownloaded
    }

    get totalDownloads(): number {
        return globalStore.downloadManager.totalDownloads
    }

    get downloading(): boolean {
        return globalStore.downloadManager.downloading
    }

    get currentlyDownloading(): number [] {
        return globalStore.downloadManager.currentlyDownloading
    }

    get totalProgress(): string {
        return globalStore.downloadManager.totalProgress.toFixed(2)
    }

    get totalProgressDisplay(): string | number {
        return globalStore.downloadManager.totalDownloads > 0 ? (globalStore.downloadManager.totalProgress * 100 / globalStore.downloadManager.totalDownloads).toFixed(2) : 0.0;
    }

    get toggleDownloadingText(): string {
        if (globalStore.downloadManager.enabled)
            return "Disable";
        else
            return "Enable";
    }

    get downloadsEnabled(): boolean {
        return globalStore.downloadManager.enabled
    }

    get downloadsSupported(): boolean {
        return globalStore.downloadManager.downloadsSupported
    }
}
