























/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";

import {Machine} from "@/data/model/machine";
import {Page} from "@/data/model/page";

import {MachineImage} from "@/data/model/machineimage";
import {MachineImageBenefit} from "@/data/model/machineimagebenefit";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import globalStore from "@/globalstore";
import {isMobile} from "mobile-device-detect";
import Button from "@/components/Button.vue";

class DownloadStatus {
    public downloadedImage: boolean = false;
    public downloadedIndex: boolean = false;
    public downloadingImage: boolean = false;
    public image: string|null = null;
    public button: string|null = null;
    public position: number = 0;
}
@Component({
    components: {LoadingIndicator, ImageResource, Button}
})
export default class Machine360 extends Vue {
    @Prop() private machineImages!: MachineImage[];
    @Prop() public machine!: Machine;
    @Prop() public pages!: Page[];

    private operations: any[]|null = null;

    private currentImageIndex: number|null = null;
    private precision: number = 180;

    protected showButtons: boolean = true;

    private active: boolean = false;

    hideButtons(){
      this.showButtons = true;
      let that = this;
      setTimeout(function(){
        that.showButtons = false;
      }, 4000);
    }

    show360Buttons() {
      return this.showButtons;
    }

    getMachineBenefitTitle(benefitPage: MachineImageBenefit) {
      let title: string | undefined = "";

      if(benefitPage.benefitId != null){
        this.machine?.benefits.forEach(function (benefit){
          if(benefitPage.benefitId == benefit.id){
            title = benefit.title;
          }
        })
      }
      else if(benefitPage.pageId != null){
        this.pages.forEach(function (page){
          if(benefitPage.pageId == page.id){
            title = page.title;
          }
        })
      }
      return title;
    }

    get getMachinePages(): MachineImageBenefit[] {
      let seenBenefitIds: string[] = [];
      let seenPageIds: string[] = [];
      let results: MachineImageBenefit[] = [];
      this.machineImages.forEach(function (machineImage){
        machineImage.benefits.forEach(function (benefitPage){
          if(benefitPage.benefitId != null){
            if (!seenBenefitIds.includes(benefitPage.benefitId)){
              seenBenefitIds.push(benefitPage.benefitId);
              results.push(benefitPage);
            }
          } else if(benefitPage.pageId != null){
            if (!seenPageIds.includes(benefitPage.pageId)){
              seenPageIds.push(benefitPage.pageId);
              results.push(benefitPage);
            }
          }
        })
      })    
      return results;
    }

    show360ButtonsAndNotMobile(){
      return this.showButtons && !isMobile;
    }

    isMobile(){
      return isMobile;
    }

    openImageBenefit(imageBenefit: MachineImageBenefit) {
      let benefitId = imageBenefit.benefitId;

      if (benefitId) {
        this.$router.push({
          name: 'machine-benefit-item', params: {
            benefitId: benefitId
          }
        })
      } else {
        let pageId = imageBenefit.pageId;

        if (pageId) {
          this.$router.push({
            name: 'machine-page-item', params: {
              pageId: pageId
            }
          })
        }
      }
    }

    buttonsWidth() {
      let buttons = document.getElementById("machine-360-buttons");

      if (buttons) {
        let defaultWidth = 0;
        let defaultHeight = 0;
        let cname = "View360Width"
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var j = 0; j <ca.length; j++) {
          var c = ca[j];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            defaultWidth = Number(c.substring(name.length, c.length));
            break;
          }
        }
        cname = "View360Height"
        name = cname + "=";
        decodedCookie = decodeURIComponent(document.cookie);
        ca = decodedCookie.split(';');
        for(j = 0; j <ca.length; j++) {
          c = ca[j];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            defaultHeight = Number(c.substring(name.length, c.length));
            break;
          }
        }

        let machineImage = this.currentMachine;

        if (machineImage) {
          let dom = document.querySelectorAll(".machine-360-buttons");

          for (var i = 0, max = dom.length; i < max; i++) {
            var style = window.getComputedStyle(dom[i]);
            if (!((style.display === 'none') || (style.visibility === 'hidden'))) {
              document.cookie = "View360Width=" + dom[i].clientWidth;
              document.cookie = "View360Height=" + dom[i].clientHeight;
              buttons.style.width = dom[i].clientWidth + "px";
              buttons.style.height = dom[i].clientHeight + "px";
              return;
            }
          }

          if (defaultWidth != 0 && defaultHeight != 0) {
            buttons.style.width = defaultWidth + "px";
            buttons.style.height = defaultWidth + "px";
            return;
          }
        }


        if (defaultWidth != 0 && defaultHeight != 0) {
          buttons.style.width = defaultWidth + "px";
          buttons.style.height = defaultWidth + "px";
          return;
        }
      }

      let that = this;
      setTimeout(function () {
        that?.buttonsWidth();
      }, 500);
    }

    get currentMachineBenefits(): MachineImageBenefit[] {
      return this.currentMachine?.benefits ?? [];           
    }

    closestIndex(index: number): number {

        if (this.operations) {
            for (let i = 0; i < this.machineImages.length; i++) {
                let direction = (i % 2) == 0 ? 1 : -1;

                let checkIndex = index + (direction * (Math.floor(i / 2)));

                while (checkIndex < 0) {
                    checkIndex += this.machineImages.length;
                }

                checkIndex = checkIndex % this.machineImages.length;

                let status = this.operations[checkIndex];

                if (status && status.downloadedImage) {
                    return checkIndex;
                }
            }
        }

        return index % this.machineImages.length;
    }

    get currentMachine(): null|MachineImage {
      return this.machineAtIndex(this.closestIndex(this.currentImageIndex ?? 0));    
    }

    machineAtIndex(index: number): null|MachineImage {
        let machineImages = this.machineImages;

        if (machineImages.length > 0) {
            return machineImages[index % machineImages.length];
        }

        return null;
    }

    downloadNextImage() {
        if (this.machineImages.length == 0) {
            //console.log("wait");

            let that = this;
            setTimeout(function () {
                that.downloadNextImage()
            }, 100);

            return;
        }

        if (this.operations == null) {
            this.operations = [];

            for (let i = 0; i < this.machineImages.length; i++) {
                var status = new DownloadStatus();
                let image = this.machineImages[i];

                let imageUrl = image.image?.bestImageUrlForSize(1000, 1000) ?? null;
                let buttonUrl = image.buttonsImage?.bestImageUrlForSize(1000, 1000) ?? null;

                status.image = imageUrl ? globalStore.downloadManager.handleUrl(imageUrl) : null;
                status.button = buttonUrl ? globalStore.downloadManager.handleUrl(buttonUrl) : null;
                status.position = i;

                let originalExists = false // Todo check downloaded image

                status.downloadedImage = originalExists;

                originalExists = false; // Todo check downloaded buttons

                status.downloadedIndex = originalExists;

                this.operations!.push(status);
            }
        }

        let index = this.currentImageIndex ?? 0;
        var downloadStatus: DownloadStatus|null = null;

        for (let i = 0; i < this.machineImages.length / this.precision; i++) {
            let direction: number = (i % 2) == 0 ? 1 : -1;

            var checkIndex: number = index + (direction * (Math.floor(i / 2)) * this.precision);

            while (checkIndex < 0) {
                checkIndex += this.machineImages.length;
            }

            checkIndex = checkIndex % this.machineImages.length;

            let status = this.operations![checkIndex];

            if (status && (!status.downloadedImage || !status.downloadedIndex)) {
                downloadStatus = status;
                break;
            }
        }

        if (downloadStatus) {
            if (downloadStatus.downloadedImage) {
              let url = downloadStatus.button;

              if (url) {
                let that = this;
                let img = new Image;
                img.addEventListener("load", function () {
                  that.finished(downloadStatus, this, true);
                });
                img.src = url;
              } else {
                this.finished(downloadStatus, null, true);
              }
            } else {
              let url = downloadStatus.image;

              if (url) {
                  let that = this;
                  let img = new Image;
                  img.addEventListener("load", function () {
                      that.finished(downloadStatus, this, false);
                  });
                  img.src = url;
              } else {
                  this.finished(downloadStatus, null, false);
              }
            }
        } else if (this.precision > 1) {
            if (this.precision == 180) {
                this.precision = 90;
            } else if (this.precision == 90) {
                this.precision = 45;
            } else if (this.precision == 45) {
                this.precision = 20;
            } else if (this.precision == 20) {
                this.precision = 15;
            } else if (this.precision == 15) {
                this.precision = 10;
            } else if (this.precision == 10) {
                this.precision = 5;
            } else if (this.precision == 5) {
                this.precision = 3;
            } else if (this.precision == 3) {
                this.precision = 2;
            } else if (this.precision == 2) {
                this.precision = 1;
            }

            this.downloadNextImage();
        } else {
            //console.log("done");
        }
    }

    finished(status: DownloadStatus | null, img: HTMLImageElement|null, index: boolean) {
        if (status && img && this.operations) {
            if (index) {
              status.downloadedIndex = true;
            } else {
              status.downloadedImage = true;
            }

            if (this.closestIndex(this.currentImageIndex ?? 0) == status.position && status.downloadedImage) {
                if (this.active) {
                    if (status.downloadingImage) {
                        // Todo set image
                    } else {
                        // Todo set button
                    }
                } else {
                  if (!index) {
                    let imageDom = document.getElementById("image-360") as HTMLImageElement;
                    if (imageDom) {
                      imageDom.src = img.src;
                      let loaderDom = document.getElementById("machine-loader");
                      if (loaderDom) {
                        loaderDom.style.visibility = "hidden";
                      }
                    }
                    imageDom = document.getElementById("button-360") as HTMLImageElement;
                    if (imageDom && status.button) {
                      imageDom.src = status.button;
                    }
                  }
                }
            }
        }

        let position:number|null = status?.position ?? null

        if (position !== null && this.operations) {
            this.operations[position] = status;

            let that = this;
            setTimeout(function () {
                that.downloadNextImage();
            }, 0.001)
        } else {
            //console.log("halted");
        }
    }

    mounted() {
        let indexCName = "View360Index";
        let buttonsCName = "View360ButtonsVisble";
        var indexName = indexCName + "=";
        var buttonsName = buttonsCName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(indexName) == 0) {
                this.currentImageIndex = Number(c.substring(indexName.length, c.length));
            } else if (c.indexOf(buttonsName) == 0) {
                this.showButtons = Number(c.substring(buttonsName.length, c.length)) == 1;
                this.buttonsWidth();
            }
        }

        window.addEventListener("touchstart", this.dragStart, false);
        window.addEventListener("touchend", this.dragEnd, false);
        window.addEventListener("touchmove", this.drag, false);

        window.addEventListener("mousedown", this.dragStart, false);
        window.addEventListener("mouseup", this.dragEnd, false);
        window.addEventListener("mousemove", this.drag, false);

        this.downloadNextImage();
        this.hideButtons();

      let that = this;
      that.buttonsWidth();

      window.addEventListener('resize', function () {
        that?.buttonsWidth();
      });
    }

    private dragStartX: number|null = null;
    private dragX: number|null = null;
    private dragTimer: any = null;

    dragStart(e: any) {
        this.buttonsWidth();

        let target = e.target;

        if (target.className != "machine-360-image") {
            return true;
        }

        e.preventDefault();

        let dragX: number|null = null;

        if (e.type === "touchstart") {
            dragX = e.touches[0].clientX;
        } else if (e.which == 1) {
            dragX = e.clientX;
        }

        if (dragX != null) {
            this.active = true;
            this.dragX = null;
            this.dragStartX = dragX;
            this.dragTimer = setInterval(this.checkDrag, 100);
        }
    }

    dragEnd(e: any) {
        if (this.active) {
            e.preventDefault();

            if (this.dragX == null) {
                this.showButtons = !this.showButtons;
                this.buttonsWidth();

                let d = new Date();
                d.setTime(d.getTime() + (5 * 60 * 1000));
                let expires = "expires=" + d.toUTCString();
                document.cookie = "View360ButtonsVisble=" + (this.showButtons ? 1 : 0) + ";" + expires;
            }

            this.active = false;
            this.dragX = null;
            this.dragStartX = null;
            if (this.dragTimer) {
                window.clearInterval(this.dragTimer);
            }
            this.dragTimer = null;

            let d = new Date();
            d.setTime(d.getTime() + (5 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = "View360Index=" + this.currentImageIndex + ";" + expires;
        }

        this.dragX = null;
        this.dragStartX = null;
    }

    checkDrag() {
        if (this.dragX && this.dragStartX) {
            if (this.active) {
                let velocity = (this.dragStartX - this.dragX) / 3;// / 2.0;
                //console.log(velocity);

                let n = this.machineImages.length;
                let currentIndex = (this.currentImageIndex ?? 0) % n;
                let newIndex = Math.round((currentIndex + velocity + n * (Math.ceil(velocity / n) + 1)));

                while (newIndex < 0) {
                    newIndex += n;
                }

                if (this.currentImageIndex == null || this.currentImageIndex != newIndex % n) {
                    this.currentImageIndex = newIndex % n;

                    if (this.operations) {
                        let index = this.closestIndex(this.currentImageIndex ?? 0);

                        let status = this.operations[index];
                      let imageDom = document.getElementById("image-360") as HTMLImageElement;
                      if (imageDom) {
                        imageDom.src = status.image;
                      }
                      let buttonDom = document.getElementById("button-360") as HTMLImageElement;
                      if (buttonDom) {
                        buttonDom.src = status.button;
                      }
                    }
                }
            }

            this.dragStartX = this.dragX;
        }
    }

    drag(e: any) {
        if (this.active) {
            let dragX: null|number = null;

            if (e.type === "touchmove") {
                dragX = e.touches[0].clientX;
            } else {
                dragX = e.clientX;
            }

            if (dragX != null) {
                this.dragX = dragX;
            }
        }
    }
}
