















/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import Button from "@/components/Button.vue";
import LinkButton from "@/components/LinkButton.vue";
import ModuleItem from "@/components/ModuleItem.vue";
import {Option} from "@/data/model/option";
@Component({
    components: {ModuleItem, LinkButton, Button, ImageResource}
})
export default class ConfigurationOptionItem extends Vue {
    @Prop() private option!: Option;
    @Prop() private dragging!: Boolean;

    get image(): Resource | null {
        let option = this.option;
        return option?.icon?.imageExists(300, 300) ? option?.icon : null;
    }

    mouseDownHandler(e: any) {
        e.preventDefault();
        let dragX: null|number = null;
        let dragY: null|number = null;

        if (e.type === "touchstart") {
            dragX = e.touches[0].clientX;
            dragY = e.touches[0].clientY;
        } else {
            dragX = e.clientX;
            dragY = e.clientY;
        }
        if (dragX !== null && dragY !== null) {
            let offsetX = dragX - e.target.offsetLeft;
            let offsetY = dragY - e.target.offsetTop;

            this.$emit("startdragging", this.option, dragX, dragY, offsetX + "px", offsetY + "px");
        }
    }
    mouseUpHandler(e: any) {
        e.preventDefault();
        this.$emit("stopdragging", this.option);
    }
}
