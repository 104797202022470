import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";
import {Tooltip} from "@/data/model/tooltip";

export class Overview extends DatabaseModel {
    id: string;
    position?: number;
    prominent?: boolean;
    home?: boolean;
    title?: string;
    body?: string;
    @Collectable()
    tooltips: Tooltip[];
    @Collectable()
    image?: Resource;

    constructor(data: {
        id: string,
        position?: number,
        prominent?: boolean,
        home?: boolean,
        title?: string,
        body?: string,
        tooltips: Tooltip[],
        image?: Resource,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.prominent = data.prominent;
        this.home = data.home;
        this.title = data.title;
        this.body = data.body;
        this.tooltips = data.tooltips;
        this.image = data.image;
    }

    get buttonTitle(): string | null {
        return this.title ?? null;
    }
}