




























import {Component, Prop, Vue} from 'vue-property-decorator';
import {ContentPageElement} from "@/data/model/contentpageelement";
import ImageResource from "@/components/ImageResource.vue";

@Component({
    components: {
        ImageResource
    }
})
export default class ContentPageElements extends Vue {
    @Prop() public elements!: ContentPageElement[];
}
