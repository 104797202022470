import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";
import {OptionalVisualVideo} from "@/data/model/optionalvisualvideo";

export class OptionalVisual extends DatabaseModel {
    id: string;
    isDefault?: boolean;
    optionals: String[];
    @Collectable()
    footprintImage?: Resource;
    @Collectable()
    image?: Resource;
    @Collectable()
    videos: OptionalVisualVideo[];

    constructor(data: {
        id: string,
        isDefault?: boolean,
        optionals: String[],
        footprintImage?: Resource,
        image?: Resource,
        videos: OptionalVisualVideo[],
    }) {
        super();
        this.id = data.id;
        this.isDefault = data.isDefault;
        this.optionals = data.optionals;
        this.footprintImage = data.footprintImage;
        this.image = data.image;
        this.videos = data.videos;
    }
}