








import {Component, Prop, Vue} from 'vue-property-decorator';
import PageBase from "@/components/PageBase.vue";

@Component({
    components: {
        PageBase
    }
})
export default class Interstitial extends Vue {
    @Prop() public title!: String;
    @Prop() public subtitle!: String;
    @Prop() public preview!: boolean;
    @Prop({default: false}) private csaLogo!: boolean;
}
