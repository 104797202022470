






























import AppApi from "@/api/app-api";
import Button from "@/components/Button.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import QuestionnaireContentMultipleChoice from "@/components/QuestionnaireContentMultipleChoice.vue";
import QuestionnaireContentText from "@/components/QuestionnaireContentText.vue";
import QuestionnaireContentBoolean from "@/components/QuestionnaireContentBoolean.vue";
import QuestionnaireContentOpen from "@/components/QuestionnaireContentOpen.vue";
import QuestionnaireContentRating from "@/components/QuestionnaireContentRating.vue";
import QuestionnaireContentTextArea from "@/components/QuestionnaireContentTextArea.vue";


@Component({
  components: {
    QuestionnaireContentTextArea,
    QuestionnaireContentRating,
    QuestionnaireContentOpen,
    QuestionnaireContentBoolean,
    QuestionnaireContentText,
    Button,
    QuestionnaireContentMultipleChoice
  }
})
export default class QuestionnaireModal extends Vue {
  @Prop() public show!: boolean;
  private currentQuestion: string = "intro";
  private currentQuestionNumber: number = 1;
  private maxQuestionNumber: number = 11;
  private inputValue: any = null;
  private exception: boolean = false;

  private answerEmployee: boolean = false;
  private answerRegion: string|null = null;
  private answerDepartment: string|null = null;
  private answerWebsite: string|null = null;
  private answerFirstTime: boolean = false;
  private answerPurpose: string|null = null;
  private answerOftenUse: string|null = null;
  private answerRateContents: number = 0;
  private answerRateDesign: number = 0;
  private answerRateEaseUse: number = 0;
  private answerRateOverall: number = 0;
  private answerComment: string|null = null;

  startQuestionnaire() {
    this.currentQuestion = "employee";
    this.currentQuestionNumber = 1;
    this.maxQuestionNumber = 11;
    this.exception = false;
  }

  selectMultipleChoiceAndFormat(key: string) {
    this.saveAnswerAndContinue(key);
  }

  selectBooleanAndFormat(state: boolean) {
    this.saveAnswerAndContinue(state);
  }

  changeOpenTextAndFormat(event: any) {
    this.inputValue = event.target.value;
    this.exception = false;
  }

  selectRatingAndFormat(event: any) {
    this.inputValue = event.target.value;
    this.exception = false;
  }

  saveAnswerAndContinue(answer: any) {
    this.inputValue = null;
    this.exception = false;
    this.currentQuestionNumber += 1;

    switch (this.currentQuestion) {
      case "employee":
        this.answerEmployee = answer;
        if (answer) {
          this.currentQuestion = "region";
          this.maxQuestionNumber = 11;
        } else {
          this.currentQuestion = "website";
          this.maxQuestionNumber = 9;
          this.inputValue = this.answerWebsite;
          this.answerRegion = null;
          this.answerDepartment = null;
        }
        break;
      case "region":
        this.answerRegion = answer;
        this.currentQuestion = "department";
        this.inputValue = this.answerDepartment;
        break;
      case "department":
        this.answerDepartment = answer;
        this.currentQuestion = "website";
        this.inputValue = this.answerWebsite;
        break;
      case "website":
        this.answerWebsite = answer;
        this.currentQuestion = "first_time";
        break;
      case "first_time":
        this.answerFirstTime = answer;
        if (answer) {
          this.currentQuestion = "purpose";
          this.inputValue = this.answerPurpose;
          this.answerOftenUse = null;
        } else {
          this.currentQuestion = "use";
          this.answerPurpose = null;
        }

        if (this.answerEmployee) {
          this.maxQuestionNumber = 11;
        } else {
          this.maxQuestionNumber = 9;
        }
        break;
      case "purpose":
        this.answerPurpose = answer;
        this.currentQuestion = "rate_contents";
        this.inputValue = this.answerRateContents;
        break;
      case "use":
        this.answerOftenUse = answer;
        this.currentQuestion = "rate_contents";
        this.inputValue = this.answerRateContents;
        break;
      case "rate_contents":
        this.answerRateContents = answer;
        this.currentQuestion = "rate_design";
        this.inputValue = this.answerRateDesign;
        break;
      case "rate_design":
        this.answerRateDesign = answer;
        this.currentQuestion = "rate_ease_use";
        this.inputValue = this.answerRateEaseUse;
        break;
      case "rate_ease_use":
        this.answerRateEaseUse = answer;
        this.currentQuestion = "rate_overall";
        this.inputValue = this.answerRateOverall;
        break;
      case "rate_overall":
        this.answerRateOverall = answer;
        this.currentQuestion = "comment";
        this.inputValue = this.answerComment;
        break;
      case "comment":
        this.answerComment = answer;
        this.currentQuestion = "final";
        break;
    }
  }

  next() {
    if (this.isEmptyOrSpaces(this.inputValue)
        && ((this.currentQuestionData().type === "open" && this.currentQuestionData().required === true)
            || this.currentQuestionData().openChoice === true)
    ) {
      this.exception = true;
    } else {
      this.saveAnswerAndContinue(this.inputValue);
    }
  }

  back() {
    this.currentQuestionNumber -= 1;

    switch (this.currentQuestion) {
      case "final":
        this.currentQuestion = "comment";
        this.inputValue = this.answerComment;
        break;
      case "comment":
        this.currentQuestion = "rate_overall";
        this.inputValue = this.answerRateOverall;
        break;
      case "rate_overall":
        this.currentQuestion = "rate_ease_use";
        this.inputValue = this.answerRateEaseUse;
        break;
      case "rate_ease_use":
        this.currentQuestion = "rate_design";
        this.inputValue = this.answerRateDesign;
        break;
      case "rate_design":
        this.currentQuestion = "rate_contents";
        this.inputValue = this.answerRateContents;
        break;
      case "rate_contents":
        if (this.answerFirstTime) {
          this.currentQuestion = "purpose";
        } else {
          this.currentQuestion = "use";
        }
        if (this.answerEmployee) {
          this.maxQuestionNumber = 11;
        } else {
          this.maxQuestionNumber = 9;
        }
        break;
      case "use":
        this.currentQuestion = "first_time";
        break;
      case "purpose":
        this.currentQuestion = "first_time";
        break;
      case "first_time":
        this.currentQuestion = "website";
        this.inputValue = this.answerWebsite;
        break;
      case "website":
        if (this.answerEmployee) {
          this.currentQuestion = "department";
          this.inputValue = this.answerDepartment;
        } else {
          this.currentQuestion = "employee";
        }
        this.maxQuestionNumber = 11;
        break;
      case "department":
        if (this.answerEmployee) {
          this.currentQuestion = "region";
        } else {
          this.currentQuestion = "employee";
        }
        break;
      case "region":
        this.currentQuestion = "employee";
        break;
      case "employee":
        this.currentQuestion = "intro";
        break;
    }
  }

  finishQuestionnaire() {
    fetch(AppApi.API_BASE + "/questionnaire", {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({
        employee: this.answerEmployee,
        region: this.answerRegion,
        department: this.answerDepartment,
        website: this.answerWebsite,
        firstTime: this.answerFirstTime,
        purpose: this.answerPurpose,
        oftenUse: this.answerOftenUse,
        rateContents: this.answerRateContents,
        rateDesign: this.answerRateDesign,
        rateEaseUse: this.answerRateEaseUse,
        rateOverall: this.answerRateOverall,
        comment: this.answerComment,
      }),
    })
    .then((response) => {
      if (response.ok) {
        return document.cookie = "questionnaire_done=1;";
      }
        throw new Error("Unable to send questionnaire data");
    })
    .catch((error) => {
      console.log(error);
    });

    this.$emit('close');
  }

  showNextButton(): boolean {
    let currentQuestion = this.currentQuestionData();
    if (this.currentQuestion === "website") {
      return true;
    }
    return !(currentQuestion.type === 'multiple' || currentQuestion.type === 'boolean' || currentQuestion.type === 'text');
  }

  showBackButton(): boolean {
    let currentQuestion = this.currentQuestionData();
    return !(currentQuestion.type === 'text');
  }

  currentQuestionData(): any {
    return this.getQuestionForIdentifier(this.currentQuestion);
  }

  getQuestionForIdentifier(identifier: string): Object {
    let question = {};
    switch (identifier) {
      case "intro":
        question = {
          "type": "text",
          "text": "You are currently on the Canon Interactive Demo Tools website. " +
              "We would like to ask you some short questions to improve your user experience. " +
              "\n\n" +
              "We value your feedback, and we'll keep all of your answers anonymous.",
          "nextButtonLabel": "Start survey",
        }
        break;
      case "employee":
        question = {
          "type": "boolean",
          "title": "Are you a Canon Employee?",
        }
        break;
      case "region":
        question = {
          "type": "multiple",
          "title": "In which region are you active?",
          "choices": [
            {"key": "africa", "value": "Africa"},
            {"key": "asia", "value": "Asia"},
            {"key": "europe", "value": "Europe"},
            {"key": "middle_east", "value": "Middle East"},
            {"key": "north_america", "value": "North America"},
            {"key": "south_america", "value": "South America"},
            {"key": "australia_oceania", "value": "Australia/ Oceania"},
          ],
        }
        break;
      case "department":
        question = {
          "type": "open",
          "title": "In which department do you work and what role do you have?",
          "value": this.answerDepartment,
          "required": true,
        }
        break;
      case "website":
        question = {
          "type": "multiple",
          "title": "How did you reach this website?",
          "choices": [
            {"key": "employee", "value": "Via a link received from a Canon employee"},
            {"key": "partner", "value": "Via a link received from a Canon partner"},
            {"key": "google", "value": "Found it via Google"},
          ],
          "openChoice": true,
          "value": (["employee", "partner", "google"].includes(this.answerWebsite ?? "") ? null : this.answerWebsite),
        }
        break;
      case "first_time":
        question = {
          "type": "boolean",
          "title": "Is this the first time that you use the Canon Interactive Demo Tool?"
        }
        break;
      case "purpose":
        question = {
          "type": "open",
          "title": "For what purpose do you use this tool?",
          "value": this.answerPurpose,
          "required": false,
        }
        break;
      case "use":
        question = {
          "type": "multiple",
          "title": "How often do you use the IDTs?",
          "choices": [
            {"key": "week", "value": "Once a week"},
            {"key": "more_week", "value": "More than once a week"},
            {"key": "month", "value": "Once a month"},
            {"key": "less_month", "value": "Less than once a month"},
          ],
        }
        break;
      case "rate_contents":
        question = {
          "type": "rating",
          "title": "How would you rate the contents?",
          "value": this.answerRateContents,
        }
        break;
      case "rate_design":
        question = {
          "type": "rating",
          "title": "How would you rate the look and feel?",
          "value": this.answerRateDesign,
        }
        break;
      case "rate_ease_use":
        question = {
          "type": "rating",
          "title": "How would you rate the ease of use?",
          "value": this.answerRateEaseUse,
        }
        break;
      case "rate_overall":
        question = {
          "type": "rating",
          "title": "How would you rate this tool overall?",
          "value": this.answerRateOverall,
        }
        break;
      case "comment":
        question = {
          "type": "comment",
          "title": "Are there any things you would like to change or any other general comment?",
          "value": this.answerComment,
        }
        break;
      case "final":
        question = {
          "type": "text",
          "text": "Thanks for completing our survey",
          "nextButtonLabel": "Close survey",
          "showBackButton": true,
        }
        break;
    }

    return question
  }

  isEmptyOrSpaces(str: string|null){
    return str === null || (/^\s*$/).test(str);
  }
}
