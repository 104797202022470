













































import Button from "@/components/Button.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faStar as faStarSolid} from "@fortawesome/pro-solid-svg-icons";
import {faStar as faStarRegular} from "@fortawesome/pro-regular-svg-icons";

@Component({
  components: {
    Button,
    FontAwesomeIcon,
    FontAwesomeLayers,
  }
})
export default class QuestionnaireContentRating extends Vue {
  @Prop() public question!: string;
  @Prop() public clickHandler!: Function;
  @Prop() public exception!: boolean;
  @Prop() public value!: number;
  private rating: number = this.value ?? 0;

  get icon(): IconDefinition {
    return faStarSolid
  }

  get exceptionIcon(): IconDefinition {
    return faStarRegular
  }


}
