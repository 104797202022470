import {Collectable, DatabaseModel} from "@/data/model/database-model";

export class Optional extends DatabaseModel {
    id: string;
    position?: number;
    isDefault?: boolean;
    title?: string;
    body?: string;
    @Collectable()
    requires: String[];
    @Collectable()
    compatibleWith: String[];

    constructor(data: {
        id: string,
        position?: number,
        isDefault?: boolean,
        title?: string,
        body?: string,
        requires: String[],
        compatibleWith: String[],
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.isDefault = data.isDefault;
        this.title = data.title;
        this.body = data.body;
        this.requires = data.requires;
        this.compatibleWith = data.compatibleWith;
    }
}