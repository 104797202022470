








import EmptyChildHost from '@/views/stub/EmptyChildHost.vue';
import { Hash } from 'crypto';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CanonLogo extends Vue {
    @Prop({default: 110}) private width!: number;
    @Prop({default: false}) private csaLogo!: boolean;

    clickHandler() {
        if(this.$router.currentRoute.name == 'app-intro')
            this.$router.push({ name: 'app-selection'});
        else if(this.$router.currentRoute.name != 'app-selection')
            this.$router.push({name: 'app-intro'});  
    }
}


