
import {Component, Vue, Watch} from 'vue-property-decorator';
import {DatabaseObserver} from "@/data/database-observer";
import {Dictionary, Route} from "vue-router/types/router";

@Component({})
export default class RouterViewVue extends Vue {
    protected liveDatas: Array<DatabaseObserver> = [];

    get routerParams(): Dictionary<string> {
        return this.$route.params
    }

    @Watch("$route", {immediate: true, deep: true})
    routeWatcher(to: Route | undefined, from: Route | undefined) {
        // console.log("Route watcher", to, from);
        this.onRouteChanged(to, from)
    }

    onRouteChanged(to: Route | undefined, from: Route | undefined): void {
        // console.log("onRouteChanged")
    }

    beforeDestroy() {
        // console.log("Cleaning up livedata");
        this.liveDatas.forEach(e => e.release());
        this.liveDatas = []
    }
}
