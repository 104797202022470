
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Technology} from "@/data/model/technology";
import {Benefit} from "@/data/model/benefit";

@Component({})
export default class BenefitVue extends MachineVue {
    get benefitInstance(): Benefit | null {
        return this.machineBenefits.find((item: Benefit) => item.id == this.routerParams.benefitId) ?? null
    }

    get benefitTechnologies(): Technology[] {
        return this.benefitInstance?.technologies ?? [];
    }
}
