







import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MachineVue from "@/views/base/MachineVue.vue";
import PageBase from "@/components/PageBase.vue";
import Gallery from "@/components/Gallery.vue";

@Component({
    components: {
        Gallery,
        PageBase,
        LoadingIndicator
    }
})
export default class ApplicationsOverview extends MachineVue {
    get subtitle() {
        let machine = this.machineInstance;
        return machine?.applicationsButtonTitle ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
