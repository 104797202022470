/* eslint-disable no-console */
import Dexie from 'dexie';
import {DatabaseObserver} from "@/data/database-observer";
import {App} from "@/data/model/app";
import {DbConverter} from "@/data/db-converter";
import {wrapLiveCall} from "@/data/repository-utils";
import {Machine} from "@/data/model/machine";

export class AppRepository extends Dexie {
    private repoTable: Dexie.Table<Object, string>;

    constructor() {
        super("apps");
        this.version(1).stores({
            apps: "id,urlPath"
        });
        this.repoTable = this.table("apps");
    }

    public async putAllApps(apps: Array<App>, doCleanup: boolean = false) {
        let ids = apps.map(app => app.id);
        // console.log("Putting all apps", apps);
        await this.transaction('rw', this.repoTable, async () => {
            for (let app of apps) {
                app.cleanUndefineds();

                let exists = await this.repoTable
                    .where('id').equals(app.id)
                    .first() != null;

                // console.log("Current database value", await this.table.where('id').equals(app.id).first())
                if (exists) {
                    // console.log("Updating", app);
                    const updates = await this.repoTable.update(app.id, app);
                    // console.log("Update result", updates)
                } else {
                    // console.log("Putting", app);
                    const put = await this.repoTable.put(app);
                    // console.log("Put result", put)
                }
                // console.log("New database value", await this.table.where('id').equals(app.id).first())
            }
        });
        // console.log("Put all completed");
        if (doCleanup)
            await this.repoTable
                .where('id').noneOf(ids)
                .delete();
        // console.log("clean");
    }

    public listenForAppChanges(callback: () => void): DatabaseObserver {
        // console.log("listenForAppChanges");
        let queryCall = async () => {
            callback();
        };
        return wrapLiveCall(this.repoTable, queryCall);
    }

    public getAllAppsLive(callback: (data: Array<App>) => void, forceFetch: boolean = false): DatabaseObserver {
        // console.log("getAllAppsLive");
        let queryCall = async () => {
            const items = await this.repoTable.toArray();
            callback(items.map(item => DbConverter.toApp(item)!!));
        };
        return wrapLiveCall(this.repoTable, queryCall);
    }

    public async getApp(slugOrId: string): Promise<App | undefined> {
        // console.log("getApp");
        return DbConverter.toApp(await this.repoTable
            .where('id').equals(slugOrId)
            .or('urlPath').equals(slugOrId)
            .first());
    }

    public getAppLive(slugOrId: string, callback: (data: App | undefined) => void, forceFetch: boolean = false): DatabaseObserver {
        // console.log(`getAppLive ${slugOrId}`);
        let queryCall = async () => {
            let data = await this.repoTable
                .where('id').equals(slugOrId)
                .or('urlPath').equals(slugOrId)
                .first();
            // console.log("Reexecuting query", slugOrId, await this.repoTable.toArray(), data);

            if (data != null) {
                // console.log(`app loaded ${slugOrId}`)
                callback(DbConverter.toApp(data)!!);
            } else {
                // console.log(`No app with slugOrId ${slugOrId} in db (yet)`)
            }
        };

        return wrapLiveCall(this.repoTable, queryCall);
    }

    public getMachineLive(slugOrId: string, machineId: string, callback: (data: Machine | undefined) => void, forceFetch: boolean = false): DatabaseObserver {
        let queryCall = async () => {
            // console.log("Reexecuting query", await this.table.toArray());
            let data = await this.repoTable
                .where('id').equals(slugOrId)
                .or('urlPath').equals(slugOrId)
                .first();

            if (data != null) {
                const app = DbConverter.toApp(data)!!;
                callback(app.machines.find(machine => machine.id == machineId));
            } else {
                console.log("No machine yet")
            }
        };

        return wrapLiveCall(this.repoTable, queryCall);
    }
}