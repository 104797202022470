
















/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import Button from "@/components/Button.vue";
import LinkButton from "@/components/LinkButton.vue";
import {Module} from "@/data/model/module";
import {ConfigurationModule} from "@/data/model/configurationmodule";
import ModuleItem from "@/components/ModuleItem.vue";
@Component({
    components: {ModuleItem, LinkButton, Button, ImageResource}
})
export default class ConfigurationModuleItem extends Vue {
    @Prop() private configurationModule!: ConfigurationModule;
    @Prop() private module!: Module;
    @Prop() private dragging!: Boolean;
    @Prop() private view!: String;
    @Prop() private useStyle!: Boolean;
    @Prop() private totalHeight!: number;

    key: String = "first_load";

    get image(): Resource | null {
        let module = this.module;
        if (this.view == "top") {
            return module?.topView?.imageExists(768, 768) ? module?.topView : null;
        } else if (this.view == "opened") {
            return module?.topOpenedView?.imageExists(768, 768) ? module?.topOpenedView : null;
        } else {
            return module?.frontView?.imageExists(768, 768) ? module?.frontView : null;
        }
    }

    get position(): number | null {
        return this.configurationModule?.position;
    }

    get zIndex(): number {
        if (!this.module) {
            return 0;
        }

        return (this.module.placement ?? 0) * 1000;
    }

    leftInset(): string {
        if (!this.module) {
            return "0";
        }
        
        let totalWidth = (this.module.width ?? 0);

        if (totalWidth == 0) {
            return "0";
        }

        let img = this.$refs.moduleItem as any;

        if (img) {
            let image = img.$el as HTMLElement;

            let domWidth = image.offsetWidth;

            let margin = domWidth / totalWidth * (this.module.insetLeft ?? 0);

            return (-margin) + "px";
        }

        return "0";
    }

    forceReload() {
      this.key = Math.random()+"";
    }

    rightInset(): string {
        if (!this.module) {
            return "0";
        }

        let totalWidth = (this.module.width ?? 0);

        if (totalWidth == 0) {
            return "0";
        }

        let img = this.$refs.moduleItem as any;

        if (img) {
            let image = img.$el as HTMLElement;

            let domWidth = image.offsetWidth;

            let margin = domWidth / totalWidth * (this.module.insetRight ?? 0);

            return (-margin) + "px";
        } else {
          setTimeout(this.forceReload, 300);
        }

        return "0";
    }

    get moduleStyle(): String {
        let key = this.key;
        return this.useStyle ? "z-index: " + this.zIndex + "; margin-left: " + this.leftInset() + "; margin-right: " + this.rightInset() + ";" : "";
    }

    mouseDownHandler(e: any) {
        e.preventDefault();
        let dragX: null|number = null;
        let dragY: null|number = null;

        if (e.type === "touchstart") {
            dragX = e.touches[0].clientX;
            dragY = e.touches[0].clientY;
        } else {
            dragX = e.clientX;
            dragY = e.clientY;
        }
        if (dragX !== null && dragY !== null) {
            let offsetX = dragX - e.target.offsetLeft;
            let offsetY = dragY - e.target.offsetTop;

            this.$emit("startdragging", this.configurationModule, dragX, dragY, offsetX + "px", offsetY + "px");
        }
    }
    mouseUpHandler(e: any) {
        e.preventDefault();
        this.$emit("stopdragging", this.configurationModule);
    }
}
