













import Button from "@/components/Button.vue";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
    Button,
  }
})
export default class QuestionnaireContentMultipleChoice extends Vue {
  @Prop() public question!: string;
  @Prop() public choices!: Array<any>;
  @Prop() public openChoice!: boolean;
  @Prop() public value!: string;
  @Prop() public clickHandler!: Function;
  @Prop() public inputClickHandler!: Function;
  @Prop() public exception!: boolean;
  private inputValue: string|null = this.value ?? null;
}
