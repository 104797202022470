import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {BenefitElement} from "@/data/model/benefitelement";
import {Resource} from "@/data/model/resource";
import {Technology} from "@/data/model/technology";
import {BenefitCategory} from "@/data/model/benefitCategory";

export class Benefit extends DatabaseModel {
    id: string;
    position?: number;
    title?: string;
    @Collectable()
    technologies: Technology[];
    @Collectable()
    benefitElements: BenefitElement[];
    @Collectable()
    mainVideo?: Resource;
    @Collectable()
    mainImage?: Resource;
    @Collectable()
    category?: BenefitCategory;

    constructor(data: {
        id: string,
        position?: number,
        title?: string,
        technologies: Technology[],
        benefitElements: BenefitElement[],
        mainVideo?: Resource,
        mainImage?: Resource,
        category?: BenefitCategory
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.title = data.title;
        this.technologies = data.technologies;
        this.benefitElements = data.benefitElements;
        this.mainVideo = data.mainVideo;
        this.mainImage = data.mainImage;
        this.category = data.category;
    }

    get sortedBenefitElements(): BenefitElement[] {
        return this.benefitElements.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }
}