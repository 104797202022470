import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {CaseStudy} from "@/data/model/casestudy";
import {Contact} from "@/data/model/contact";
import {Language} from "@/data/model/language";
import {Machine} from "@/data/model/machine";
import {Overview} from "@/data/model/overview";
import {Page} from "@/data/model/page";
import {Resource} from "@/data/model/resource";
import moment, {Moment} from "moment";
import {BenefitCategory} from "@/data/model/benefitCategory";
import {AppCategory} from "@/data/model/appcategory";
import {BenefitElement} from "@/data/model/benefitelement";
import {ConfigurationModule} from "@/data/model/configurationmodule";
import {AppCategoryDto} from "@/api/dto/appcategory-dto";

export class App extends DatabaseModel {
    id: string;
    published?: Date;
    callToActionTitle?: string;
    callToActionButtonTitle?: string;
    skipIntroductionButtonTitle?: string;
    introductionButtonTitle?: string;
    introductionBody?: string;
    subtitle?: string;
    title?: string;
    callToActionBody?: string;
    urlPath?: string;
    skipMachineOverview?: boolean;
    preview?: boolean;
    type?: string;
    region?: string;
    windowsInstaller?: string;
    iosUrl?: string;
    androidUrl?: string;
    @Collectable()
    pages: Page[];
    @Collectable()
    icon?: Resource;
    @Collectable()
    introductionImage?: Resource;
    @Collectable()
    introductionVideo?: Resource;
    @Collectable()
    mainImage?: Resource;
    @Collectable()
    languages: Language[];
    @Collectable()
    contacts: Contact[];
    @Collectable()
    overviews: Overview[];
    @Collectable()
    caseStudies: CaseStudy[];
    @Collectable()
    machines: Machine[];
    @Collectable()
    callToActionImage?: Resource;
    @Collectable()
    benefitCategories?: BenefitCategory[];
    @Collectable()
    categories?: AppCategory[];
    @Collectable()
    landing?: AppCategory[];
    @Collectable()
    category?: AppCategory;
    currentCountry?: string;
    countries?: string[];
    defaultForCountry?: boolean;
    @Collectable()
    siblings: App[];
    @Collectable()
    children: App[];

    constructor(data: {
        id: string,
        published?: Date,
        callToActionTitle?: string,
        callToActionButtonTitle?: string,
        skipIntroductionButtonTitle?: string,
        introductionButtonTitle?: string,
        introductionBody?: string,
        subtitle?: string,
        title?: string,
        callToActionBody?: string,
        urlPath?: string,
        skipMachineOverview?: boolean,
        preview?: boolean,
        type?: string,
        region?: string,
        windowsInstaller?: string,
        iosUrl?: string,
        androidUrl?: string,
        pages: Page[],
        icon?: Resource,
        introductionImage?: Resource,
        introductionVideo?: Resource,
        mainImage?: Resource,
        languages: Language[],
        contacts: Contact[],
        overviews: Overview[],
        caseStudies: CaseStudy[],
        machines: Machine[],
        callToActionImage?: Resource,
        benefitCategories: BenefitCategory[],
        categories: AppCategory[],
        landing: AppCategory[],
        category?: AppCategory,
        currentCountry?: string,
        countries?: string[],
        defaultForCountry?: boolean,
        siblings: App[],
        children: App[],
    }) {
        super();
        this.id = data.id;
        this.published = data.published;
        this.callToActionTitle = data.callToActionTitle;
        this.callToActionButtonTitle = data.callToActionButtonTitle;
        this.skipIntroductionButtonTitle = data.skipIntroductionButtonTitle;
        this.introductionButtonTitle = data.introductionButtonTitle;
        this.introductionBody = data.introductionBody;
        this.subtitle = data.subtitle;
        this.title = data.title;
        this.callToActionBody = data.callToActionBody;
        this.urlPath = data.urlPath;
        this.skipMachineOverview = data.skipMachineOverview;
        this.preview = data.preview;
        this.type = data.type;
        this.region = data.region;
        this.windowsInstaller = data.windowsInstaller;
        this.iosUrl = data.iosUrl;
        this.androidUrl = data.androidUrl;
        this.pages = data.pages;
        this.icon = data.icon;
        this.introductionImage = data.introductionImage;
        this.introductionVideo = data.introductionVideo;
        this.mainImage = data.mainImage;
        this.languages = data.languages;
        this.contacts = data.contacts;
        this.overviews = data.overviews;
        this.caseStudies = data.caseStudies;
        this.machines = data.machines;
        this.callToActionImage = data.callToActionImage;
        this.benefitCategories = data.benefitCategories;
        this.categories = data.categories;
        this.landing = data.landing;
        this.category = data.category;
        this.currentCountry = data.currentCountry;
        this.countries = data.countries;
        this.defaultForCountry = data.defaultForCountry;
        this.siblings = data.siblings;
        this.children = data.children;

    }

    supportsLanguage(language: string): boolean {
        return this.languages.find(item => item.identifier == language) != null;
    }

    get defaultLanguage(): Language | undefined {
        const languages = this.languages;
        return languages.find(language => language.default && language.identifier != null) ??
            languages.find(language => language.identifier != null);
    }

    get machinesSafe(): Machine[] {
        return this.machines ?? [];
    }

    get sortedMachines(): Machine[] {
        return this.machinesSafe.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }

    get languagesSafe(): Language[] {
        // console.log("Getting languages", this._languages, this._languages?.length, this._languages ?? []);
        return this.languages ?? [];
    }

    get publishedParsed(): Moment | undefined {
        return this.published != null ? moment(this.published) : undefined;
    }

    get sortedBenefitCategories(): BenefitCategory[] {
        if (!this.benefitCategories) {
            return [];
        }

        return this.benefitCategories.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }

    get sortedLanding(): AppCategory[] {
        if (!this.landing) {
            return [];
        }

        return this.landing.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }
}