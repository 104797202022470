
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Page} from "@/data/model/page";

@Component({})
export default class PageVue extends MachineVue {
    get pageInstance(): Page | null {
        return this.appPages.find((item: Page) => item.id == this.routerParams.pageId) ?? null
    }

    get pageSubPages(): Page[] {
        return this.pageInstance?.contentPage?.subpages ?? [];
    }
}
