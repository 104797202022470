import {BrowserWindow, IpcRenderer} from 'electron';
import ElectronApi from "@/electron/ElectronApi";
import App = Electron.App;

export default class ElectronApiImplementation implements ElectronApi {
    private readonly _app: App;
    private _ipcRenderer: IpcRenderer | undefined;
    private _window!: BrowserWindow;

    constructor(app: Electron.App) {
        this._app = app;
    }

    set ipcRenderer(value: Electron.IpcRenderer | undefined) {
        this._ipcRenderer = value;
    }

    get ipcRenderer(): Electron.IpcRenderer | undefined {
        return this._ipcRenderer;
    }

    set window(value: Electron.BrowserWindow) {
        this._window = value;
    }

    isElectron(): boolean {
        return true;
    }

    setProgress(progress: number, mode: string) {
        // @ts-ignore
        this._window.setProgressBar(progress, mode);
    }

    setBadge(value: string) {
        this._app.dock.setBadge(value)
    }

    get platform(): string {
        return process.platform
    }

    on(channel: string, listener: (event: any, ...args: any[]) => void): this {
        this._ipcRenderer?.on(channel, listener ?? []);

        return this;
    }

    removeListener(channel: string, listener: (...args: any[]) => void): this {
        // console.log(channel, listener)
        this._ipcRenderer?.removeListener(channel, listener);
        return this;
    }

    send(channel: string, ...args: any[]): void {
        this._ipcRenderer?.send(channel, args ?? [])
    }
}