






















/* eslint-disable no-console */
import {Component, Vue} from 'vue-property-decorator';
import AppApi from "@/api/app-api";
import AppItem from "@/components/AppItem.vue";
import {DatabaseObserver} from "@/data/database-observer";
import router from "@/router";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import globalStore from "@/globalstore";
import {App} from "@/data/model/app";
import PageBase from "@/components/PageBase.vue";
import Rows from "@/components/Rows.vue";
import Row from "@/components/Row.vue";
import {AppCategory} from "@/data/model/appcategory";

@Component({
    components: {
        Row,
        Rows,
        PageBase,
        LoadingIndicator,
        AppItem
    }
})
export default class AppOverview extends Vue {
    private loading: boolean = false;
    private liveDatas: Array<DatabaseObserver> = [];
    apps: App[] = [];
    categories: AppCategory[] = [];

    created() {
        // this.updateApps();
        this.refreshApps();
    }

    selectApp(app: App) {
        // appState.commit('changeApp', app.id);
        if (app.type == "countries") {
          let children = app.children ?? [];
          let toOpen: App = app;
          for(let child of children) {
            let countries = child.countries ?? [];
            if (countries.includes(app.currentCountry ?? "NL")) {
              toOpen = child;
              console.log(`open country`);
              break;
            } else if (child.defaultForCountry) {
              toOpen = child;
              console.log(`open default`);
            }

          }
          router.push({
            name: 'app-intro',
            params: {appSlug: toOpen.urlPath!!, locale: this.$route.params.locale ?? globalStore.localeManager.locale}
          })
        } else {
          router.push({
            name: 'app-intro',
            params: {appSlug: app.urlPath!!, locale: this.$route.params.locale ?? globalStore.localeManager.locale}
          })
        }
    }

    beforeMount() {
        this.liveDatas.push(globalStore.appRepository.getAllAppsLive((data) => {
            // console.log("update data", data);
            this.apps = data
        }));
        this.liveDatas.push(globalStore.categoryRepository.getAllCategoriesLive((data) => {
            // console.log("update data", data);
            this.categories = data.sort((a: AppCategory, b: AppCategory) => a.title?.localeCompare(b.title ?? "") ?? 0)
        }));
    }

    beforeDestroy() {
        this.liveDatas.forEach(e => e.release());
        this.liveDatas = []
    }

    getAppsForCategory(category: AppCategory | null): App[] {
      if (category) {

        return this.apps
            .filter(app => {
              let categoryIds = app.categories?.map(category => {
                return category.id
              }) ?? []

              return categoryIds.includes(category.id)
            })
            .sort((a: App, b: App) => a.title?.localeCompare(b.title ?? "") ?? 0)
      } else {
        return [];
      }
    }

    refreshApps() {
        this.loading = true;

        let response = AppApi.getAllApps();
        response
            // Handle success
            .then(() => {
                this.loading = false;
                // globalStore.appRepository.putAllApps(e);
            })
            // Handle error
            .catch((e) => {
                globalStore.tracker.exception(e)
                // console.error(e);
                this.loading = false;
            })
    }
}
