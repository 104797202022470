import {DatabaseModel} from "@/data/model/database-model";

export class ModuleGroup extends DatabaseModel {
    id: string;
    name?: string;

    constructor(data: {
        id: string,
        name?: string,
    }) {
        super();
        this.id = data.id;
        this.name = data.name;
    }
}