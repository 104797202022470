











































import {Component, Watch} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import router from "@/router";
import Button from "@/components/Button.vue";
import LinkButton from "@/components/LinkButton.vue";
import AppVue from "@/views/base/AppVue.vue";
import Interstitial from "@/components/Interstitial.vue";
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";
import DownloadDebugOverview from "@/views/debug/DownloadDebugOverview.vue";
import {Application} from "@/data/model/application";
import {App} from "@/data/model/app";
import {AppCategory} from "@/data/model/appcategory";
import globalStore from "@/globalstore";
import PageBase from "@/components/PageBase.vue";
import Rows from "@/components/Rows.vue";
import Row from "@/components/Row.vue";
import AppItem from "@/components/AppItem.vue";
import {isAndroid, isIOS, osName} from "mobile-device-detect";
import NotFound from "@/views/stub/NotFound.vue";

@Component({
    components: {
      AppItem,
      Row,
      Rows,
      PageBase,
        DownloadDebugOverview,
        ImageResource,
        Button,
        LinkButton,
        LoadingIndicator,
        Interstitial,
        NotFound
    }
})
export default class AppIntro extends AppVue {
    //todo skip this page if introductionButtonTitle and skipIntroductionButtonTitle are empty

    get categories(): AppCategory[] {
      let app = this.appInstance;

      return app?.landing ?? [];
    }

    selectApp(app: App) {
      // console.log(`App intro with`, app);
      // appState.commit('changeApp', app.id);
      router.push({
        name: 'app-intro',
        params: {appSlug: app.urlPath!!, locale: this.$route.params.locale ?? globalStore.localeManager.locale}
      })
    }

    get landing(): boolean {
      let app = this.appInstance;
      return app?.type == "landing" || app?.type == "countries";
    }

    get introductionBody(): string | null {
        let app = this.appInstance;
        return app?.introductionBody ?? null;
    }
    get introductionButtonTitle(): string | null {
        let app = this.appInstance;
        return app?.introductionButtonTitle ?? null;
    }
    get skipIntroductionButtonTitle(): string | null {
        let app = this.appInstance;
        return app?.skipIntroductionButtonTitle ?? null;
    }

    get introductionVideo(): Resource | null {
        let app = this.appInstance;
        return app?.introductionVideo?.videoExists() ? app?.introductionVideo : null;
    }

    get introductionImage(): Resource | null {
        let app = this.appInstance;
        return app?.introductionImage?.imageExists(2048, 2048) ? app?.introductionImage : null;
    }

    play() {
        if (this.introductionVideo != null) {
            router.push({
                name: 'app-intro-video',
                params: {appSlug: this.$route.params.appSlug, machineId: this.$route.params.machineId}
            })
        } else {
            this.skip()
        }
    }

    skip() {
        let app = this.appInstance;

        if (app?.machines?.length == 1 && (app?.skipMachineOverview ?? true)) {
            let machine = app?.machines[0];

            router.push({
                name: 'machine-home',
                params: {appSlug: this.$route.params.appSlug, machineId: machine.id}
            })
        } else {
            router.push({
                name: 'app-machine-selection',
                params: {appSlug: this.$route.params.appSlug, machineId: this.$route.params.machineId}
            })
        }
    }

    @Watch("appInstance", {immediate: true, deep: true})
    onAppInstance(newValue: App | null, oldValue: App | null) {
      // console.debug("app instance loaded", this.loadingApp)
        if (newValue) {
            if (newValue.introductionButtonTitle == null && newValue.skipIntroductionButtonTitle == null) {
                if (newValue.type == "landing") {
                  return;
                } else if (newValue.type == "countries") {
                  let children = this.appInstance?.children ?? [];
                  let toOpen: App|null = null;
                  for(let child of children) {
                    let countries = child.countries ?? []
                    if (countries.includes(this.appInstance?.currentCountry ?? "NL")) {
                      toOpen = child;
                      break;
                    } else if (child.defaultForCountry) {
                      toOpen = child;
                    }

                  }

                  if (toOpen != null) {
                    router.replace({
                      name: 'app-intro',
                      params: {
                        appSlug: toOpen.urlPath!!,
                        locale: this.$route.params.locale ?? globalStore.localeManager.locale
                      }
                    })
                  }
                  return;
                } else if (newValue.machines?.length == 1 && (newValue.skipMachineOverview ?? true)) {
                    let machine = newValue.machines[0];

                    router.replace({
                        name: 'machine-home',
                        params: {appSlug: this.$route.params.appSlug, machineId: machine.id}
                    })
                } else {
                    router.replace({
                        name: 'app-machine-selection',
                        params: {appSlug: this.$route.params.appSlug, machineId: this.$route.params.machineId}
                    })
                }
            }
        }
    }

    beforeMount() {
        this.refreshApp();
    }
}
