























































import {Component} from 'vue-property-decorator';
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import BenefitVue from "@/views/base/BenefitVue.vue";
import {Technology} from "@/data/model/technology";
import PageBase from "@/components/PageBase.vue";
import ImageResource from "@/components/ImageResource.vue";
import Column from "@/components/Column.vue";
import Columns from "@/components/Columns.vue";
import {Resource} from "@/data/model/resource";
import VideoResource from "@/components/VideoResource.vue";
import {BenefitElement} from "@/data/model/benefitelement";
import {BenefitCategory} from "@/data/model/benefitCategory";
import {Benefit} from "@/data/model/benefit";
import BenefitsList from "@/components/BenefitsList.vue";

@Component({
    components: {
        BenefitsList,
        VideoResource,
        Columns,
        Column,
        ImageResource,
        PageBase,
        Button,
        LoadingIndicator
    }
})
export default class BenefitDetail extends BenefitVue {
    get title(): string | null {
        let machine = this.machineInstance;
        return machine?.benefitsButtonTitle ?? null;
    }

    get subtitle(): string | null {
        let benefit = this.benefitInstance;
        return benefit?.title ?? null;
    }

    get benefitId(): string | null {
        let benefit = this.benefitInstance;
        return benefit?.id ?? null;
    }

    selectBenefit(benefit: Benefit | null)  {
        this.replaceBenefit(benefit);
    }

    get benefitMainImage(): Resource | null {
        let benefit = this.benefitInstance;
        let size = benefit?.mainImage?.style == 'full' ? 2048 : 1024;
        return benefit?.mainImage?.imageExists(size, size) ? benefit?.mainImage : null;
    }

    get benefitMainVideo(): Resource | null {
        let benefit = this.benefitInstance;
        return benefit?.mainVideo?.videoExists() ? benefit?.mainVideo : null;
    }

    get benefitElements(): BenefitElement[] {
        let benefit = this.benefitInstance;

        return benefit?.sortedBenefitElements ?? [];
    }

    openTechnology(technology: Technology) {
        let benefit = this.benefitInstance;

        this.$router.push({
            name: 'machine-technology-item', params: {
                technologyId: technology.id,
                overrideTitle: benefit?.title ?? ""
            }
        })
    }

    beforeMount() {
        this.refreshApp();
    }
}
