













































import {Component, Watch} from 'vue-property-decorator';
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MachineVue from "@/views/base/MachineVue.vue";
import PageBase from "@/components/PageBase.vue";
import Rows from "@/components/Rows.vue";
import Row from "@/components/Row.vue";
import ImageResource from "@/components/ImageResource.vue";
import {Language} from "@/data/model/language";
import {Application} from "@/data/model/application";
import {Machine} from "@/data/model/machine";
import {CaseStudy} from "@/data/model/casestudy";
import CaseStudyItem from "@/components/CaseStudyItem.vue";

@Component({
    components: {
        CaseStudyItem,
        ImageResource,
        Row,
        Rows,
        PageBase,
        Button,
        LoadingIndicator
    }
})
export default class CaseStudyOverview extends MachineVue {
    protected query: string | null = "";
    protected selectedLanguage: String | null = null;
    protected selectedApplication: Application | null = null;
    protected selectedMachine: Machine | String | null = null;
    
    
    @Watch('query')
    onQueryChanged(newQuery: string) {
        if (newQuery.length > 0) {
            this.$tracker.trackSearch(newQuery);
        }
    }

    get subtitle() {
        let machine = this.machineInstance;
        return machine?.caseStudiesButtonTitle ?? null;
    }

    get filterCaseStudies(): CaseStudy[] {
        let caseStudies: CaseStudy[] = this.appCaseStudies;

        let query = this.query;
        let selectedApplication = this.selectedApplication;
        let selectedMachine = this.selectedMachine;
        let selectedLanguage = this.selectedLanguage;

        let terms: string[] = ((query?.length ?? 0) > 0 ? query?.toLowerCase().split(/(?:,| \n)+/) : []) ?? [];

        return caseStudies.filter(caseStudy => {
            let title = caseStudy.title?.toLowerCase();
            let metadata = caseStudy.metadata?.toLowerCase();

            for (let term of terms) {
                if ((!title?.includes(term) ?? false) && (!metadata?.includes(term) ?? false)) {
                    return false;
                }
            }
            if (selectedMachine instanceof Machine) {
                let found = false;
                let machines: Machine[] = caseStudy.machines;
                for (let machine of machines) {
                    if (machine.id == selectedMachine.id) {
                      found = true;
                      break;
                    }
                }
                if (!found) return false;
            } else if (selectedMachine) {
                let found = false;
                let machines: String[] = caseStudy.filter_machines;
                for (let machine of machines) {
                  if (machine == selectedMachine) {
                    found = true;
                    break;
                  }
                }
                if (!found) return false;
            }
            if (selectedApplication) {
                let found = false;
                let applications: Application[] = caseStudy.applications;
                for (let application of applications) {
                    if (application.id == selectedApplication.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) return false;
            }
            if (selectedLanguage) {
                let found = false;
                let languages: String[] = caseStudy.contentPage?.mainVideo?.languages ?? [];
                for (let language of languages) {
                    if (language == selectedLanguage) {
                        found = true;
                        break;
                    }
                }
                if (!found) return false;
            }

            return true;
        }).sort(function (a, b) {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position > b.position) {
                return 1;
            }

            if (a.position < b.position) {
                return -1;
            }

            return 0;
        });
    }

    get casestudyLanguages(){
        let templang: string[] = [];
        let casestudylanguages = this.appCaseStudies.filter(casestudy =>{
            let casestudylang = casestudy.contentPage?.mainVideo?.languages ?? [];
            for (let lang of casestudylang){
                templang.push(lang);
            }
        });
        return templang.filter((v, i, a) => a.indexOf(v) === i);
    }

    get languages(): Language[] {
        let app = this.appInstance;
        let languages: Language[] = (app?.languages ?? []);

        if (this.selectedLanguage == null) {
          for (let language of languages) {
            if (language.identifier == "en") {
              this.selectedLanguage = String(language.identifier);
            }
          }
        }

        return languages.sort(function (a, b) {
            if (a.identifier && b.identifier) {
                if (a.identifier > b.identifier) {
                    return 1;
                }

                if (a.identifier < b.identifier) {
                    return -1;
                }
            } else if (a.identifier) {
                return 1;
            } else if (b.identifier) {
                return -1;
            }

            return 0;
        });
    }

    get hasLanguages(): boolean {
        return this.languages.length > 0;
    }

    get applications(): Application[] {
        let applications: Application[] = [];
        let applicationIds: string[] = [];

        for (let caseStudy of this.appCaseStudies) {
            for (let application of caseStudy.applications) {
                if (applicationIds.indexOf(application.id) == -1) {
                    applicationIds.push(application.id);
                    applications.push(application);
                }
            }
        }

        return applications.sort(function (a, b) {
            if (a.title && b.title) {
                if (a.title > b.title) {
                    return 1;
                }

                if (a.title < b.title) {
                    return -1;
                }
            } else if (a.title) {
                return 1;
            } else if (b.title) {
                return -1;
            }

            return 0;
        });
    }

    get hasApplications(): boolean {
        return this.applications.length > 0;
    }

    get filterMachines(): String[] {
      const machines = [];
      const map = new Map();

      for (const caseStudy of this.appCaseStudies) {
          if(Array.isArray(caseStudy.filter_machines)){
            for (const machine of caseStudy.filter_machines) {
                if (!map.has(machine)) {
                    map.set(machine, true);    // set any value to Map
                    machines.push(machine);
                }
            }
        }  
      }

      return machines.sort();
    }

    get machines(): Machine[] {
        const machines = [];
        const map = new Map();

        for (const caseStudy of this.appCaseStudies) {
            if (Array.isArray(caseStudy.filter_machines) && caseStudy.filter_machines.length == 0) {
                for (const machine of caseStudy.machines) {
                    if (!map.has(machine.id)) {
                        map.set(machine.id, true);    // set any value to Map
                        machines.push(machine);
                    }
                }
            }
        }

        return machines.sort(function (a, b) {
            if (a.title && b.title) {
                if (a.title > b.title) {
                    return 1;
                }

                if (a.title < b.title) {
                    return -1;
                }
            } else if (a.title) {
                return 1;
            } else if (b.title) {
                return -1;
            }

            return 0;
        });
    }

    get hasMachines(): boolean {
        return this.machines.length + this.filterMachines.length > 0;
    }

    get callToActionButtonTitle(): String | null {
        let app = this.appInstance
        return app?.callToActionButtonTitle && app?.contacts?.length > 0 ? app?.callToActionButtonTitle : null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
