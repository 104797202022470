/* eslint-disable no-console */
import 'core-js/stable'
import "isomorphic-fetch"
import Vue from 'vue'
import './service/registerServiceWorker'
import router, {onApplyLocaleToRouter} from './router'
// import store from './store/app-state'
import App from "@/App.vue";
import globalStore from "@/globalstore";
import {LocalChangedListener} from "@/manager/LocaleManager";
import AppApi from "@/api/app-api";
import {Route} from "vue-router";

import VueI18n from 'vue-i18n';
import { translationlang, fallbackLocale } from './translations/translation';

import VueAnalytics from 'vue-analytics';
Vue.use(VueI18n);

Vue.use(VueAnalytics, {
    id: '229655031',
    router
  });

// @ts-ignore
if (window.isElectron == true) {
    const electron = require('electron');
    const api = new (require("@/electron/ElectronApiImplementation").default)(electron.app);
    // console.log("Setup ipc rendered");

    api.window = electron.remote.getCurrentWindow();
    api.ipcRenderer = electron.ipcRenderer;
    // @ts-ignore
    window.electronApi = api;
}

Vue.config.productionTip = false;

globalStore.downloadManager.prepare();
globalStore.localeManager.addListener(new class implements LocalChangedListener {
    onLocaleChanged(newLocale: string): void {
        AppApi.resetCaches();
        // onApplyLocaleToRouter();
    }
});

const messages = Object.assign(translationlang);
  
const i18n = new VueI18n({
    messages,
    fallbackLocale: fallbackLocale,
    silentTranslationWarn: true
});

new Vue({
    router,
    i18n,
    // store,
    render: h => h(App),
    created() {
        window.document.title = process.env.VUE_APP_PRODUCT_NAME!!;
    }
}).$mount('#app');

i18n.locale = router.app.$route.params['locale'];


globalStore.appRepository.listenForAppChanges(() => {
    onApplyLocaleToRouter();
});

router.afterEach((to: Route, from: Route) => {
    onApplyLocaleToRouter();
});

// router.afterEach((to, from) => {
//     console.log(to);
// });