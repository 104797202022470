
import AppVue from "@/views/base/AppVue.vue";
import {Component, Watch} from "vue-property-decorator";
import {Machine} from "@/data/model/machine";
import globalStore from "@/globalstore";
import {Technology} from "@/data/model/technology";
import {Benefit} from "@/data/model/benefit";
import {Application} from "@/data/model/application";
import {OptionalVisual} from "@/data/model/optionalvisual";
import {Optional} from "@/data/model/optional";
import {Configurator} from "@/data/model/configurator";

@Component({})
export default class MachineVue extends AppVue {
    protected machineInstance: Machine | null = null;
    protected loadedMachineInstance = false

    get machineTitle(): string | null {
        return this.machineInstance?.title ? this.machineInstance?.title : null;
    }

    get machineSubtitle(): string | null {
        return this.machineInstance?.subtitle ? this.machineInstance?.subtitle : null;
    }

    get machineApplications(): Application[] {
        return this.machineInstance?.applications ?? [];
    }

    get machineBenefits(): Benefit[] {
        return this.machineInstance?.benefits ?? [];
    }

    get machineConfigurators(): Configurator[] {
        return this.machineInstance?.configurators ?? [];
    }

    get machineTechnologies(): Technology[] {
        return this.machineInstance?.technologies ?? [];
    }

    get machineOptionals(): Optional[] {
        return this.machineInstance?.optionals ?? [];
    }

    get machineOptionalVisuals(): OptionalVisual[] {
        return this.machineInstance?.optionalVisuals ?? [];
    }

    get machineShouldBeLoadedNow(): boolean {
        return !this.loadingApp && this.hasRefreshed && this.loadedMachineInstance
    }

    @Watch('machineShouldBeLoadedNow')
    onMachineShouldBeLoadedNow(loaded: boolean) {
        if (!loaded) return
        if (this.machineInstance == null) {
            this.$router.push({name: "app-machine-selection"})
        }
    }

    beforeMount() {
        const machineId = this.routerParams.machineId;

        this.liveDatas.push(globalStore.appRepository.getMachineLive(this.routerParams.appSlug, machineId, (data) => {
            this.machineInstance = data ?? null;
            this.loadedMachineInstance = true
        }));
    }
}
