








import Button from "@/components/Button.vue";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
    Button,
  }
})
export default class QuestionnaireContentOpen extends Vue {
  @Prop() public text!: string;
  @Prop() public nextButtonLabel!: string;
  @Prop() public showBackButton!: boolean;
  @Prop() public clickHandler!: Function;
  @Prop() public backClickHandler!: Function;
}
