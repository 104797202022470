








import {Component} from 'vue-property-decorator';
import CanonLogo from "@/components/CanonLogo.vue";
import BackButton from "@/components/BackButton.vue";
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import OverviewVue from "@/views/base/OverviewVue.vue";
import PageBase from "@/components/PageBase.vue";
import ImageResource from "@/components/ImageResource.vue";
import OverviewBase from "@/components/OverviewBase.vue";

@Component({
    components: {
        OverviewBase,
        ImageResource,
        PageBase,
        Button,
        CanonLogo,
        BackButton,
        LoadingIndicator
    }
})
export default class OverviewDetail extends OverviewVue {
    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title ?? this.appInstance?.title;
    }

    get subtitle() {
        return this.overviewInstance?.title
    }

    beforeMount() {
        this.refreshApp();
    }
}
