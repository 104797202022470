






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class LoadingSpinner extends Vue {
    @Prop() private loading!: boolean;
}
