/* eslint-disable no-console */
import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import NotFound from '../views/stub/NotFound.vue'
import AppOverview from '../views/AppOverview.vue'
import AppMachineSelection from '../views/AppMachineSelection.vue'
import globalStore from "@/globalstore";
import EmptyChildHost from "@/views/stub/EmptyChildHost.vue";
import MachineHome from "@/views/MachineHome.vue";
import AppIntro from "@/views/AppIntro.vue";
import AppIntroVideo from "@/views/AppIntroVideo.vue";
import TechnologyDetail from "@/views/TechnologyDetail.vue";
import BenefitDetail from "@/views/BenefitDetail.vue";
import BenefitOverview from "@/views/BenefitsOverview.vue";
import ApplicationsOverview from "@/views/ApplicationsOverview.vue";
import ApplicationDetail from "@/views/ApplicationDetail.vue";
import CaseStudyOverview from "@/views/CaseStudyOverview.vue";
import CaseStudyDetail from "@/views/CaseStudyDetail.vue";
import AppContact from "@/views/AppContact.vue";
import OptionalsOverview from "@/views/OptionalsOverview.vue";
import PageDetail from "@/views/PageDetail.vue";
import OverviewDetail from "@/views/OverviewDetail.vue";
import ConfiguratorDetail from "@/views/ConfiguratorDetail.vue";
import ModuleDetail from "@/views/ModuleDetail.vue";
import OptionDetail from "@/views/OptionDetail.vue";
import AppLanding from "@/views/AppLanding.vue";

Vue.use(VueRouter);

let forcedApp = process.env.VUE_APP_FORCED_APP;

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'app-selection',
        component: AppOverview,
        beforeEnter(to, from, next) {
            // console.log("Routing", forcedApp, to, from);

            if (forcedApp != null) {
                next({
                    name: 'app-intro',
                    params: {appSlug: forcedApp, locale: globalStore.localeManager.locale}
                })
            } else {
                next();
            }
        }
    },
    {
        path: '/:appSlug',
        component: EmptyChildHost,
        name: 'app-root',
        beforeEnter(to, from, next) {
            next({
                name: 'app-intro',
                params: {appSlug: to.params.appSlug, locale: globalStore.localeManager.locale}
            })
        }
    },
    {
        path: '/:appSlug/:locale',
        component: EmptyChildHost,
        children: [
            {
                path: '',
                name: 'app-intro',
                component: AppIntro,
            },
            {
                path: 'introduction',
                name: 'app-intro-video',
                component: AppIntroVideo
            },
            {
                path: 'machines',
                name: 'app-machine-selection',
                component: AppMachineSelection,
            },
            {
                path: 'contact',
                name: 'app-contact',
                component: AppContact,
            }
        ]
    },
    {
        path: '/:appSlug/:locale/machine/:machineId',
        component: EmptyChildHost,
        children: [
            {
                path: '',
                name: 'machine-home',
                component: MachineHome
            },
            {
                path: 'configurator/:configuratorId',
                component: EmptyChildHost,
                children: [
                    {
                        path: '',
                        name: 'machine-configurator-item',
                        component: ConfiguratorDetail,
                        props: {overrideTitle: null}
                    },
                    {
                        path: 'modules/:moduleId',
                        name: 'machine-configurator-module-item',
                        component: ModuleDetail,
                        props: {overrideTitle: null}
                    },
                    {
                        path: 'options/:optionId',
                        name: 'machine-configurator-option-item',
                        component: OptionDetail,
                        props: {overrideTitle: null}
                    }
                ]
            },
            {
                path: 'overview',
                component: EmptyChildHost,
                children: [
                    {
                        path: ':overviewId',
                        name: 'machine-overview-item',
                        component: OverviewDetail,
                        props: {overrideTitle: null}
                    }
                ]
            },
            {
                path: 'benefits',
                component: EmptyChildHost,
                children: [
                    {
                        path: '',
                        name: 'machine-benefits',
                        component: BenefitOverview
                    },
                    {
                        path: ':benefitId',
                        name: 'machine-benefit-item',
                        component: BenefitDetail
                    }
                ]
            },
            {
                path: 'technology',
                component: EmptyChildHost,
                children: [
                    {
                        path: ':technologyId',
                        name: 'machine-technology-item',
                        component: TechnologyDetail,
                        props: {overrideTitle: null}
                    }
                ]
            },
            {
                path: 'applications',
                component: EmptyChildHost,
                children: [
                    {
                        path: '',
                        name: 'machine-applications',
                        component: ApplicationsOverview
                    },
                    {
                        path: ':applicationId',
                        name: 'machine-application-item',
                        component: ApplicationDetail
                    }
                ]
            },
            {
                path: 'casestudies',
                component: EmptyChildHost,
                children: [
                    {
                        path: '',
                        name: 'machine-casestudies',
                        component: CaseStudyOverview
                    },
                    {
                        path: ':casestudyId',
                        name: 'machine-casestudy-item',
                        component: CaseStudyDetail
                    }
                ]
            },
            {
                path: 'page',
                component: EmptyChildHost,
                children: [
                    {
                        path: ':pageId',
                        name: 'machine-page-item',
                        component: PageDetail,
                        props: {overrideTitle: null}
                    }
                ]
            },
            {
                path: 'optionals',
                component: EmptyChildHost,
                children: [
                    {
                        path: '',
                        name: 'machine-optionals',
                        component: OptionalsOverview
                    }
                ]
            },
        ]
    },
    {
        path: '*',
        name: "NotFound",
        component: NotFound
    }/*
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    },*/
];

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
});

let routeLocaleParsed = false;
router.beforeEach((to: Route, from: Route, next) => {
    if (routeLocaleParsed) {
        // console.debug("Locale already taken from url")
        return next()
    }
    routeLocaleParsed = true;
    const toRouteHasLocale = "locale" in to.params;
    const isLocaleSet = to.params.locale != null;

    if (!(toRouteHasLocale && isLocaleSet)) {
        // console.debug("Route has no locale support");
        return next();
    }

    console.debug("Using locale from URL");

    if (globalStore.localeManager.locale != to.params.locale) {
        // console.debug("Applying locale from URL to LocaleManager");
        globalStore.localeManager.locale = to.params.locale;
        if (globalStore.localeManager.locale != to.params.locale) {
            to.params.locale = globalStore.localeManager.locale;
            // @ts-ignore
            return next(to)
        }
    }
    // console.debug("URL is not changing the current locale, nice");
    return next();
});

router.beforeEach((to: Route, from: Route, next) => {
    // Only handle setting the locale when the route supports it (currently unsupported)
    const toRouteHasLocale = "locale" in to.params;
    const isLocaleSet = to.params.locale != null;

    if (!(toRouteHasLocale && isLocaleSet)) {
        // console.debug("Route has no locale support");
        return next();
    }

    if (to.params.locale == null) {
        const newRoute = globalStore.localeManager.applyTo(to);
        if (newRoute != null) {
            // console.debug("Applying locale from LocaleManager to route");
            // @ts-ignore
            return next(newRoute);
        }
    }

    // console.debug("Keeping given locale")
    return next()
});
// router.afterEach((to: Route, from: Route) => {
//     console.log(`Routing to ${to.name}: ${to.path}`)
// });

router.beforeEach((to: Route, from: Route, next) => {
    // Force the current locale to be applied to the new route
    const newRoute = globalStore.localeManager.applyTo(to);
    if (newRoute != null) {
        // console.log("Navigating to locale changed route");
        // @ts-ignore
        return next(to);
    }
    return next();
});

router.afterEach((to: Route, from: Route) => {
    console.log(`Page changed ${to.fullPath} (${from.fullPath})`)
    globalStore.tracker.trackRoute(to)
});


export const onApplyLocaleToRouter = () => {
    // fixLanguage(router, )
    const currentApp = router.currentRoute.params.appSlug;
    if (currentApp == null) return;

    // console.log("Applying locale", globalStore.localeManager.locale);
    globalStore.appRepository.getApp(currentApp)
        .then(app => {
            if (app != null) {
                if (globalStore.localeManager.limitTo(app.languages)) {
                    // console.log("Language changed");
                    const newRoute = globalStore.localeManager.applyTo(router.currentRoute);
                    // @ts-ignore
                    if (newRoute != null) router.replace(newRoute);
                }
            }
        })
        .catch(error => {
            globalStore.tracker.exception(error)
            console.error(error);
        });
};

export const isConsideredRoot = (route: Route): boolean => {
    return route.name == 'app-selection' || (forcedApp != null && route.name == 'app-intro');
};

export default router
