










import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import TechnologyVue from "@/views/base/TechnologyVue.vue";
import ContentPageBase from "@/components/ContentPageBase.vue";
import {ContentPage} from "@/data/model/contentpage";
import ImageResource from "@/components/ImageResource.vue";
import {Resource} from "@/data/model/resource";

@Component({
    components: {
        ImageResource,
        ContentPageBase,
        LoadingIndicator
    }
})
export default class TechnologyDetail extends TechnologyVue {

    get title() {
        return this.$route.params.overrideTitle ?? this.machineInstance?.title
    }

    get subtitle() {
        return this.technologyInstance?.title
    }

    get technologyLogo(): Resource | null {
        let technology = this.technologyInstance;
        return technology?.logo?.imageExists(110, 80) ? technology?.logo : null;
    }

    get contentPageId(): string | null {
        return this.contentPage?.id ?? null;
    }

    get contentPage(): ContentPage | null {
        let technology = this.technologyInstance;
        return technology?.contentPage ?? null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
