import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Resource} from "@/data/model/resource";
import {Technology} from "@/data/model/technology";

export class BenefitElement extends DatabaseModel {
    id: string;
    position?: number;
    title?: string;
    body?: string;
    moreButtonTitle?: string;
    @Collectable()
    technologies: Technology[];
    @Collectable()
    image?: Resource;

    constructor(data: {
        id: string,
        position?: number,
        title?: string,
        body?: string,
        moreButtonTitle?: string,
        technologies: Technology[],
        image?: Resource,
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.title = data.title;
        this.body = data.body;
        this.moreButtonTitle = data.moreButtonTitle;
        this.technologies = data.technologies;
        this.image = data.image;
    }
}