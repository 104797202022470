
import {Component} from "vue-property-decorator";
import MachineVue from "@/views/base/MachineVue.vue";
import {Technology} from "@/data/model/technology";
import {ContentPage} from "@/data/model/contentpage";

@Component({})
export default class TechnologyVue extends MachineVue {
    get technologyInstance(): Technology | null {
        return this.machineTechnologies.find((item: Technology) => {
            return item.id == this.routerParams.technologyId
        }) ?? null
    }

    get contentPage(): ContentPage | null {
        return this.technologyInstance?.contentPage ?? null;
    }
}
