




























































import {Component, Watch} from 'vue-property-decorator';
import DownloadDebugOverview from "@/views/debug/DownloadDebugOverview.vue";
import Button from "@/components/Button.vue";
import MachineVue from "@/views/base/MachineVue.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageBase from "@/components/PageBase.vue";
import Rows from "@/components/Rows.vue";
import Row from "@/components/Row.vue";
import ImageResource from "@/components/ImageResource.vue";
import VideoResource from "@/components/VideoResource.vue";
import {MachineImage} from "@/data/model/machineimage";
import {MachineImageBenefit} from "@/data/model/machineimagebenefit";
import Machine360 from "@/components/Machine360.vue";
import OverviewBase from "@/components/OverviewBase.vue";
import {Overview} from "@/data/model/overview";

@Component({
    components: {
        OverviewBase,
        Machine360,
        VideoResource,
        ImageResource,
        Row,
        Rows,
        PageBase,
        Button,
        DownloadDebugOverview,
        LoadingIndicator
    }
})
export default class MachineHome extends MachineVue {
    protected playing: boolean = false;
    protected play: boolean = false;

    showVideo(show: boolean) {
        this.playing = show;
        this.play = show;
    }

    playVideo() {
        this.playing = true;
        this.play = true;
    }

    get routerParams() {
        return this.$route.params
    }

    get title(): string | null {
        let machine = this.machineInstance;
        return machine?.title ?? null
    }

    get hasMachineImages(): boolean {
        return this.machineImages.length > 0;
    }

    get machineImages(): MachineImage[] {
        let machine = this.machineInstance;

        return machine?.machineImages ?? []
    }

    get overviewInstance(): Overview | null {
        let overviews = this.appOverviews;

        let home = overviews.find((item: Overview) => {
            return item.home;
        });

        return home ?? null;
    }

    get machineMainImage() {
        if (this.hasMachineImages) {
            return null;
        }
        let machine = this.machineInstance;
        return machine?.mainImage?.imageExists(2048, 2048) ? machine?.mainImage : null;
    }

    get machineIntroductionVideo() {
        let machine = this.machineInstance;
        return machine?.introductionVideo?.videoExists() ? machine?.introductionVideo : null;
    }

    get showCallToAction() {
        let app = this.appInstance
        return this.callToActionButtonTitle && (app?.machines?.length ?? 0) <= 1;
    }

    get callToActionButtonTitle(): String | null {
        let app = this.appInstance
        return app?.callToActionButtonTitle && app?.contacts?.length > 0 ? (app?.callToActionButtonTitle ?? null) : null;
    }

    beforeMount() {
        this.refreshApp();
    }
}
