























import {Component} from 'vue-property-decorator';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Button from "@/components/Button.vue";
import AppVue from "@/views/base/AppVue.vue";
import {Contact} from "@/data/model/contact";
import Columns from "@/components/Columns.vue";
import Column from "@/components/Column.vue";
import PageBase from "@/components/PageBase.vue";
import {Resource} from "@/data/model/resource";
import ImageResource from "@/components/ImageResource.vue";

@Component({
    components: {
        ImageResource,
        PageBase,
        Column,
        Columns,
        Button,
        LoadingIndicator
    }
})
export default class AppContact extends AppVue {
    beforeMount() {
        this.refreshApp();
    }

    get callToActionTitle(): string | null {
        let app = this.appInstance;
        return app?.callToActionTitle ?? null;
    }

    get callToActionBody(): string | null {
        let app = this.appInstance;
        return app?.callToActionBody ?? null;
    }

    get callToActionImage(): Resource | null {
        let app = this.appInstance;
        return app?.callToActionImage?.imageExists(2048, 2048) ? app?.callToActionImage : null;
    }

    get contacts(): Contact[] {
        let contacts: Contact[] = (this.appInstance?.contacts ?? []);
        return contacts.filter(contact => contact.enabled);
    }
}
