
import RouterViewVue from "@/views/base/RouterViewVue.vue";
import {Component, Watch} from "vue-property-decorator";
import globalStore from "@/globalstore";
import {App} from "@/data/model/app";
import AppApi from "@/api/app-api";
import {CaseStudy} from "@/data/model/casestudy";
import {Page} from "@/data/model/page";
import {Overview} from "@/data/model/overview";
import {Application} from "@/data/model/application";
import {Benefit} from "@/data/model/benefit";
import {Contact} from "@/data/model/contact";
import {Technology} from "@/data/model/technology";
import {Tooltip} from "@/data/model/tooltip";
import {Route} from "vue-router";
import {Configurator} from "@/data/model/configurator";
import {Module} from "@/data/model/module";
import {Option} from "@/data/model/option";

@Component({})
export default class AppVue extends RouterViewVue {
    protected appInstance: App | null = null;
    protected loadingApp: boolean = false;
    protected hasRefreshed: boolean = false;
    protected loadingAppError: any | null = null;
    protected loadedAppInstance = false;

    get isAppNotFound() : boolean {
        return this.loadingAppError != null && this.appInstance == null;
    }

    get csaLogo(): boolean {
        let app = this.appInstance;

        return app?.region == "csa" ?? false;
    }

    get locale(): string | null {
        //TODO
        return "en";
    }

    get appPreview(): boolean {
        let app = this.appInstance;
        return app?.preview ?? false;
    }

    get appTitle(): string | null {
        let app = this.appInstance;
        return app?.title ? app?.title : null;
    }

    get appSubtitle(): string | null {
        let app = this.appInstance;
        return app?.subtitle ? app?.subtitle : null;
    }

    get appCaseStudies(): CaseStudy[] {
        return this.appInstance?.caseStudies ?? [];
    }

    get appPages(): Page[] {
        return this.appInstance?.pages ?? [];
    }

    get appOverviews(): Overview[] {
        return this.appInstance?.overviews ?? [];
    }

    beforeMount() {
        // console.debug("Loading app live",this.$route.params.appSlug)
        this.liveDatas.push(globalStore.appRepository.getAppLive(this.$route.params.appSlug, (data) => {
            if (data && data.urlPath == this.$route.params.appSlug) {
                this.appInstance = data;
                this.loadedAppInstance = true
            } else {
                this.appInstance = null;
                // eslint-disable-next-line no-console
                console.log("no app instance or wrong slug", data, this.$route)
            }
        }));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRouteChanged(to: Route | undefined, from: Route | undefined): void {
      // console.log("newroute", to);
        // super.onRouteChanged(to, from);
        this.refreshApp();
    }

    refreshApp() {
        this.loadingAppError = null;
        if (this.$route.params.appSlug != null){
            // console.log("slug", this.$route.params.appSlug)
            AppApi.getApp(this.$route.params.appSlug)
                .then((result) => {
                    // console.debug("app loaded")
                    this.hasRefreshed = true;
                    this.loadingApp = false;
                    if (result){
                        this.onRefreshSuccess()
                    }else{
                        this.onRefreshFromCache()
                    }         
                })
                .catch((e) => {
                  // console.debug("failed to load app")
                    this.hasRefreshed = true;
                    globalStore.tracker.exception(e)
                    // eslint-disable-next-line no-console
                    console.error(e);
                    this.loadingAppError = e;
                    this.loadingApp = false;
                    this.onRefreshFailed()
                })
        }
    }

    get appShouldBeLoadedNow(): boolean {
        return !this.loadingApp && this.hasRefreshed && this.loadedAppInstance
    }

    @Watch('appShouldBeLoadedNow')
    onAppShouldBeLoadedNow(loaded: boolean) {
        if (!loaded) return
        if (this.appInstance == null) {
            this.$router.push({name: "app-selection"})
        }
    }

    onRefreshFromCache() {
    }

    onRefreshSuccess() {
    }

    onRefreshFailed() {
    }

    openContact(contact: Contact) {
        this.$tracker.trackUrlOpen(contact.url!!, "contact", contact.title)
        window.open(contact.url, '_blank');
    }

    openBenefit(benefit: Benefit) {
        this.$router.push({
            name: 'machine-benefit-item', params: {
                benefitId: benefit.id
            }
        })
    }

    replaceBenefit(benefit: Benefit | null) {
        if (benefit != null) {
            this.$router.replace({
                name: 'machine-benefit-item', params: {
                    benefitId: benefit.id,
                    transitionName: 'fade'
                },
            })
        } else {
            this.$router.replace({name: 'machine-benefits'})
        }
    }

    openApplication(application: Application) {
        this.$router.push({
            name: 'machine-application-item', params: {
                applicationId: application.id,
                transitionName: 'fade'
            }
        })
    }

    openCaseStudy(caseStudy: CaseStudy) {
        this.$router.push({
            name: 'machine-casestudy-item', params: {
                casestudyId: caseStudy.id
            }
        })
    }

    openTechnology(technology: Technology) {
        this.$router.push({
            name: 'machine-technology-item', params: {
                technologyId: technology.id
            }
        })
    }

    openConfigurator(configurator: Configurator) {
        this.$router.push({
            name: 'machine-configurator-item', params: {
                configuratorId: configurator.id
            }
        })
    }

    openModule(module: Module) {
        this.$router.push({
            name: 'machine-configurator-module-item', params: {
                moduleId: module.id
            }
        })
    }

    openOption(option: Option) {
        this.$router.push({
            name: 'machine-configurator-option-item', params: {
                optionId: option.id
            }
        })
    }

    openOverview(overview: Overview) {
        this.$router.push({
            name: 'machine-overview-item', params: {
                overviewId: overview.id
            }
        })
    }

    openPage(page: Page) {
        this.$router.push({
            name: 'machine-page-item', params: {
                pageId: page.id
            }
        })
    }

    openTooltip(tooltip: Tooltip) {
        if (tooltip.page) {
            this.openPage(tooltip.page);
        }
    }

    openApplicationsOverview() {
        this.$router.push({name: 'machine-applications'})
    }

    openCaseStudyOverview() {
        this.$router.push({name: 'machine-casestudies'})
    }

    openOptionalsOverview() {
        this.$router.push({name: 'machine-optionals'})
    }
}
