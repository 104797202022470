




import {Component, Prop, Vue} from 'vue-property-decorator';
import {Resource} from "../data/model/resource";
import globalStore from "@/globalstore";

@Component
export default class ImageResource extends Vue {
    @Prop() protected image!: Resource;
    @Prop({default: 512}) protected width!: number;
    @Prop({default: 512}) protected height!: number;

    get url(): string | null {
        const target = this.image?.bestImageUrlForSize(this.width, this.height);
        if (target != null) {
            return globalStore.downloadManager.handleUrl(target)
        }
        return null;
    }

    handleLoad() {
        this.$emit("load");
    }

    handleClick() {
        this.$emit("click");
    }
}
