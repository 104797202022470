import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Application} from "@/data/model/application";
import {ContentPage} from "@/data/model/contentpage";
import {Machine} from "@/data/model/machine";
import {Resource} from "@/data/model/resource";

export class CaseStudy extends DatabaseModel {
    id: string;
    position?: number;
    metadata?: string;
    title?: string;
    @Collectable()
    applications: Application[];
    @Collectable()
    thumbnail?: Resource;
    @Collectable()
    contentPage?: ContentPage;
    @Collectable()
    machines: Machine[];
    @Collectable()
    filter_machines: String[];

    constructor(data: {
        id: string,
        position?: number,
        metadata?: string,
        title?: string,
        applications: Application[],
        thumbnail?: Resource,
        contentPage?: ContentPage,
        machines: Machine[],
        filter_machines: String[],
    }) {
        super();
        this.id = data.id;
        this.position = data.position;
        this.metadata = data.metadata;
        this.title = data.title;
        this.applications = data.applications;
        this.thumbnail = data.thumbnail;
        this.contentPage = data.contentPage;
        this.machines = data.machines;
        this.filter_machines = data.filter_machines;
    }
}