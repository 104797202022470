









import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class QuestionnaireContentOpen extends Vue {
  @Prop() public question!: string;
  @Prop() public value!: string;
  @Prop() public clickHandler!: Function;
  @Prop() public exception!: boolean;
  private inputValue: string|null = this.value ?? null;
}
