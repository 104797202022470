import {Collectable, DatabaseModel} from "@/data/model/database-model";
import {Application} from "@/data/model/application";
import {Benefit} from "@/data/model/benefit";
import {Optional} from "@/data/model/optional";
import {OptionalVisual} from "@/data/model/optionalvisual";
import {Resource} from "@/data/model/resource";
import {Technology} from "@/data/model/technology";
import {BenefitCategory} from "@/data/model/benefitCategory";
import {MachineImage} from "@/data/model/machineimage";
import {Configurator} from "@/data/model/configurator";

export class Machine extends DatabaseModel {

    id: string;
    subtitle?: string;
    optionalsButtonTitle?: string;
    caseStudiesButtonTitle?: string;
    applicationsButtonTitle?: string;
    benefitsButtonTitle?: string;
    skipIntroductionButtonTitle?: string;
    introductionButtonTitle?: string;
    introductionBody?: string;
    body?: string;
    title?: string;
    footprintTitle?: string;
    position?: number;
    @Collectable()
    benefits: Benefit[];
    @Collectable()
    applications: Application[];
    @Collectable()
    introductionImage?: Resource;
    @Collectable()
    introductionVideo?: Resource;
    @Collectable()
    mainImage?: Resource;
    @Collectable()
    benefitsImage?: Resource;
    @Collectable()
    optionals: Optional[];
    @Collectable()
    optionalVisuals: OptionalVisual[];
    @Collectable()
    technologies: Technology[];
    @Collectable()
    machineImages: MachineImage[];
    @Collectable()
    configurators: Configurator[];


    constructor(data: {
        id: string,
        subtitle?: string,
        optionalsButtonTitle?: string,
        caseStudiesButtonTitle?: string,
        applicationsButtonTitle?: string,
        benefitsButtonTitle?: string,
        skipIntroductionButtonTitle?: string,
        introductionButtonTitle?: string,
        introductionBody?: string,
        body?: string,
        title?: string,
        footprintTitle?: string,
        position?: number,
        benefits: Benefit[],
        applications: Application[],
        introductionImage?: Resource,
        introductionVideo?: Resource,
        mainImage?: Resource,
        benefitsImage?: Resource,
        optionals: Optional[],
        optionalVisuals: OptionalVisual[],
        technologies: Technology[],
        machineImages: MachineImage[],
        configurators: Configurator[],
    }) {
        super();
        this.id = data.id;
        this.subtitle = data.subtitle;
        this.optionalsButtonTitle = data.optionalsButtonTitle;
        this.caseStudiesButtonTitle = data.caseStudiesButtonTitle;
        this.applicationsButtonTitle = data.applicationsButtonTitle;
        this.benefitsButtonTitle = data.benefitsButtonTitle;
        this.skipIntroductionButtonTitle = data.skipIntroductionButtonTitle;
        this.introductionButtonTitle = data.introductionButtonTitle;
        this.introductionBody = data.introductionBody;
        this.body = data.body;
        this.title = data.title;
        this.footprintTitle = data.footprintTitle;
        this.position = data.position;
        this.benefits = data.benefits;
        this.applications = data.applications;
        this.introductionImage = data.introductionImage;
        this.introductionVideo = data.introductionVideo;
        this.mainImage = data.mainImage;
        this.benefitsImage = data.benefitsImage;
        this.optionals = data.optionals;
        this.optionalVisuals = data.optionalVisuals;
        this.technologies = data.technologies;
        this.machineImages = data.machineImages;
        this.configurators = data.configurators;
    }

    getBenefitsForCategory(category: BenefitCategory | null): Benefit[] {
        let result: Benefit[] = []

        this.benefits.forEach(function(benefit: Benefit){
            if (benefit.category == null && category == null) {
                result.push(benefit);
            } else if (benefit.category && category && benefit.category.id == category.id) {
                result.push(benefit);
            }
        });

        return result.sort((a,b) => {
            if (a.position === undefined && b.position === undefined) {
                return 0;
            } else if (a.position === undefined) {
                return -1;
            } else if (b.position === undefined) {
                return 1;
            }

            if (a.position !== null && b.position !== null) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
            } else if (a.position) {
                return 1;
            } else if (b.position) {
                return -1;
            }

            return 0;
        });
    }
}