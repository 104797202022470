import {DatabaseModel} from "@/data/model/database-model";

export class User extends DatabaseModel {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;

    constructor(data: {
        id: string,
        email?: string,
        firstName?: string,
        lastName?: string,
    }) {
        super();
        this.id = data.id;
        this.email = data.email;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }
}