






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class LinkButton extends Vue {
    @Prop({default: false}) protected secondary!: Boolean;


    handleClick() {
        this.$emit("click");
        // console.log(this.clickHandler);
        // if (this.clickHandler != null) {
        //     this.clickHandler();
        // }
    }
}
