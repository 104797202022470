import {DatabaseModel} from "@/data/model/database-model";

export class MachineImageBenefit extends DatabaseModel {

    id: string;
    x?: number;
    y?: number;
    benefitId?: string;
    pageId?: string;

    constructor(data: {
        id: string,
        x?: number,
        y?: number,
        benefitId?: string,
        pageId?: string,
    }) {
        super();
        this.id = data.id;
        this.x = data.x;
        this.y = data.y;
        this.benefitId = data.benefitId;
        this.pageId = data.pageId;
    }
}