









/* eslint-disable no-console */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Resource} from "@/data/model/resource";
import {CaseStudy} from "@/data/model/casestudy";
import ImageResource from "@/components/ImageResource.vue";

@Component({
    components: {
        ImageResource
    }
})
export default class CaseStudyItem extends Vue {
    @Prop() private caseStudy!: CaseStudy;
    @Prop() private clickHandler!: (caseStudy: CaseStudy) => void;

    get id(): string | null {
        return this.caseStudy?.id ?? null;
    }

    get title(): string | null {
        return this.caseStudy?.title ?? null;
    }

    get thumbnail(): Resource | null {
        let thumbnail = this.caseStudy?.thumbnail;
        return thumbnail?.imageExists(512, 512) ? thumbnail : null;
    }
}
