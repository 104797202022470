
















import {Component} from 'vue-property-decorator';
import CanonLogo from "@/components/CanonLogo.vue";
import BackButton from "@/components/BackButton.vue";
import Button from "@/components/Button.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MachineVue from "@/views/base/MachineVue.vue";
import PageBase from "@/components/PageBase.vue";
import Columns from "@/components/Columns.vue";
import Column from "@/components/Column.vue";
import ImageResource from "@/components/ImageResource.vue";
import {BenefitCategory} from "@/data/model/benefitCategory";
import {Benefit} from "@/data/model/benefit";
import BenefitsList from "@/components/BenefitsList.vue";

@Component({
    components: {
        BenefitsList,
        ImageResource,
        Column,
        Columns,
        PageBase,
        Button,
        CanonLogo,
        BackButton,
        LoadingIndicator
    }
})
export default class BenefitOverview extends MachineVue {

    get subtitle(): string | null {
        let machine = this.machineInstance;
        return machine?.benefitsButtonTitle ?? null;
    }

    get benefitsImage() {
        let machine = this.machineInstance;
        return machine?.benefitsImage?.imageExists(2048, 2048) ? machine?.benefitsImage : null;
    }

    selectBenefit(benefit: Benefit | null)  {
        this.replaceBenefit(benefit);
    }

    beforeMount() {
        this.refreshApp();
    }
}
