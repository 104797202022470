






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class Column extends Vue {
    @Prop({default: true}) protected centerVertical!: boolean;
    @Prop({default: 1}) protected columns!: number;
    @Prop({default: false}) protected wide!: boolean;
}
