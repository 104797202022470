





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Resource} from "../data/model/resource";
import ImageResource from "./ImageResource.vue";
import VideoResource from "./VideoResource.vue";
import Button from "./Button.vue";
import {Page} from "@/data/model/page";

@Component({
    components: {
        Button,
        VideoResource,
        ImageResource
    }
})
export default class ContentPageResource extends Vue {
    @Prop() public video!: Resource;
    @Prop() public image!: Resource;
    @Prop() private pageHandler?: (page: Page) => void;

    openPage(page: Page) {
      if (this.pageHandler) {
        this.pageHandler(page);
      }

    }

    get imageSubpages(): Page[] {
        return this.image?.subpages ?? [];
    }

    get imageBody(): string | null {
        return this.image?.body ?? null;
    }
    get videoBody(): string | null {
        return this.video?.body ?? null;
    }
}
